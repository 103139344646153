import { useRef, useState, FC, MutableRefObject } from "react";
import { Field, Formik, Form, FormikProps } from "formik";
import * as Yup from "yup";

import clsx from "clsx";
import { TextField } from "shared/ui/ae-user-input";
import { CloseIcon } from "shared/ui/icons";
import { AccountI } from "@/interfaces/accounts";
import { Dropdown } from "shared/ui";
import { USA_STATES } from "@/constants/states";
import { glencocoClientAPI } from "@/api/glencoco";
import { toast } from "react-hot-toast";
import { CampaignI } from "@/interfaces/campaign";
import { checkIfClient } from "shared/lib/helpers";

const EnhancedTextField = TextField(Field);
const EnhancedDropdown = Dropdown(Field);

export interface EditAccountFormI {
  campaign_name: string;
  account_name: string;
  website?: string;
  industry?: string;
  revenue?: string | number;
  hq_state?: string;
  employee_count?: string;
}

const softUrlRegExp =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

const EditAccountFormValidation = Yup.object().shape({
  campaign_name: Yup.string()
    .min(2, "Minimum 2 characters")
    .max(1000, "Maximum 1000 characters")
    .required("Required field"),
  account_name: Yup.string()
    .min(2, "Minimum 2 characters")
    .max(64, "Maximum 100 characters")
    .required("Required field"),
  website: Yup.string()
    .matches(softUrlRegExp, "Website should be a valid URL")
    .max(256, "Maximum 256 characters"),
  industry: Yup.string().max(1000, "Maximum 100 characters"),
  revenue: Yup.number()
    .typeError("Please provide a valid number")
    .min(2, "Minimum 2 characters")
    .max(1000000000000, "Maximum value 1,000,000,000,000"),
  hq_state: Yup.string()
    .min(2, "Minimum 2 characters")
    .max(1000, "Maximum 1000 characters"),
  employee_count: Yup.string()
    .min(2, "Minimum 2 characters")
    .max(1000, "Maximum 1000 characters"),
});

interface EditAccountModalI {
  modalId?: string;
  account?: AccountI;
  campaign?: CampaignI;
  onSubmitSuccess?: (data: AccountI) => void;
}

export const EDIT_ACCOUNT_MODAL_ID = "edit-account-modal";

const EditAccountModal: FC<EditAccountModalI> = ({
  modalId = EDIT_ACCOUNT_MODAL_ID,
  account,
  campaign,
  onSubmitSuccess = () => {},
}) => {
  const [disabled, disable] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const checkboxref = useRef() as MutableRefObject<HTMLInputElement>;
  const formikRef = useRef<FormikProps<EditAccountFormI>>(null);

  const form: EditAccountFormI = {
    campaign_name: campaign?.name || "",
    account_name: account?.name || "",
    website: account?.website || "",
    industry: account?.industry || "",
    revenue: account?.revenue?.toString() || "",
    hq_state: account?.hq_state || "",
    employee_count: account?.employee_count || "",
  };

  const onFormSubmitHandler = async (data: EditAccountFormI) => {
    if (campaign?.id && account?.id) {
      disable(true);
      const API = glencocoClientAPI();

      const formattedData = {
        name: data.account_name,
        website: data.website,
        industry: data.industry,
        revenue: data.revenue as string,
        state: data.hq_state,
        headcount: data.employee_count,
      };

      const resp = await API.editAccountInfo(
        campaign.id,
        account.id,
        formattedData
      ).catch(() => ({ status: null, data: null }));

      if (resp.status === 200) {
        toast.success("Success");

        onSubmitSuccess({ ...account, ...data });
        close();
      }

      disable(false);
    }
  };

  const close = () => {
    if (checkIfClient()) {
      handleCloseButton();
      (window?.document?.querySelector(`#${modalId}`) as HTMLElement)?.click();
    }
  };

  const handleCloseButton = () => {
    formikRef.current?.resetForm();
  };

  const onToogleHandler = (visibility: boolean) => {
    setIsVisible(visibility);
  };

  return (
    <>
      <input
        type="checkbox"
        id={modalId}
        className="modal-toggle"
        ref={checkboxref}
        onClick={(event) =>
          onToogleHandler((event.target as HTMLInputElement)?.checked)
        }
      />

      <div className="modal">
        {isVisible && (
          <div className="add-note-modal modal-box relative min-w-[520px] p-6">
            <div className="mb-2 flex items-center justify-between">
              <h3 className="ae-typography-h3">Edit Account</h3>
              <button
                className="btn btn-circle btn-ghost btn-sm"
                onClick={handleCloseButton}
              >
                <label
                  htmlFor={modalId}
                  className="flex h-full w-full cursor-pointer items-center justify-center"
                >
                  <CloseIcon className="h-4 w-4" />
                </label>
              </button>
            </div>

            <p className="ae-typography-body2 mb-6 text-base-content/60">
              Update campaign account details
            </p>

            <Formik
              initialValues={form}
              validationSchema={EditAccountFormValidation}
              onSubmit={onFormSubmitHandler}
              innerRef={formikRef}
              enableReinitialize
            >
              {({ errors, touched }) => (
                <Form>
                  <EnhancedTextField
                    name="campaign_name"
                    label="Campaign"
                    placeholder=""
                    errors={errors.campaign_name}
                    touched={touched.campaign_name}
                    disabled
                  />

                  <EnhancedTextField
                    name="account_name"
                    label="Account"
                    placeholder=""
                    errors={errors.account_name}
                    touched={touched.account_name}
                  />

                  <EnhancedTextField
                    name="website"
                    label="Website"
                    placeholder=""
                    errors={errors.website}
                    touched={touched.website}
                  />

                  <EnhancedTextField
                    name="industry"
                    label="Industry"
                    placeholder=""
                    errors={errors.industry}
                    touched={touched.industry}
                  />

                  <EnhancedTextField
                    name="revenue"
                    label="Revenue"
                    placeholder=""
                    errors={errors.revenue}
                    touched={touched.revenue}
                  />

                  <EnhancedDropdown
                    name="hq_state"
                    label="HQ State"
                    options={USA_STATES}
                    placeholder="Select a state"
                    errors={errors.hq_state}
                    touched={touched.hq_state}
                    labelContentClassName="ae-typography-detail1 text-base-content/60 font-[600]"
                  />

                  <EnhancedTextField
                    name="employee_count"
                    label="Number of Employees"
                    placeholder=""
                    errors={errors.employee_count}
                    touched={touched.employee_count}
                  />

                  <div className="mt-8 flex justify-end">
                    <button type="button" className="btn-ae-text mr-2">
                      <label
                        htmlFor={modalId}
                        className="flex h-full cursor-pointer items-center px-4 text-black/40"
                      >
                        Cancel
                      </label>
                    </button>

                    <button
                      type="submit"
                      disabled={disabled}
                      className={clsx("btn-ae-default w-[140px]", {
                        loading: disabled,
                      })}
                    >
                      Save
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
    </>
  );
};

export default EditAccountModal;
