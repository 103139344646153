import { FC, useCallback, useMemo } from "react";
import { CalendarDaysIcon, XCircleIcon } from "@heroicons/react/24/outline";

import { AccountDetailsStatusI } from "@/api/routes/account/interfaces";
import {
  ACCOUNT_DISPOSITION_STATUSES,
  ACCOUNT_STATUSES,
} from "shared/lib/constants/account";
import { ActionMenu, ActionMenuActionI } from "@/components/shared/action-menu";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { useAccountsContext } from "@/hooks/use-accounts-context";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { ACCOUNT_BOOK_MEETING_MODAL_ID } from "@/modals/account-book-meeting-modal";
import { AccountStatusI } from "shared/lib/interfaces/account";
import {
  AccountCancelMeetingConfirmationModal,
  CONFIRM_CANCEL_MEETING_MODAL_ID,
} from "@/modals/account-cancel-meeting-confirmation-modal";

interface StatusSectionActionsPropsI {
  accountStatus?: AccountDetailsStatusI;
}

export const StatusSectionActions: FC<StatusSectionActionsPropsI> = ({
  accountStatus,
}) => {
  console.log("STATUS", accountStatus?.status);
  const { setModalAccount } = useAccountsContext();
  const { campaign, account, accountUserDisposition } =
    useAccountDetailsContext();
  const isMeetingScheduled =
    accountStatus?.status === ACCOUNT_DISPOSITION_STATUSES.MEETING_SCHEDULED;

  const handleModifyMeeting = useCallback(() => {
    setModalAccount({
      campaignId: campaign?.id || "",
      accountId: account?.id || "",
      accountName: account?.name || "",
      accountStatus: accountUserDisposition?.status as AccountStatusI,
      targetAccountStatus: ACCOUNT_STATUSES.MEETING_SCHEDULED,
      contact: {
        title: accountUserDisposition?.last_contact_title || "",
        email: accountUserDisposition?.last_contact_email || "",
        name: accountUserDisposition?.last_contact_name || "",
      },
      followUpTime: accountUserDisposition?.follow_up_time || "",
    });

    modalHelpers.lazyOpen(ACCOUNT_BOOK_MEETING_MODAL_ID);
  }, [campaign, account, accountUserDisposition]);

  const meetingScheduledActionItems: ActionMenuActionI[] = useMemo(
    () =>
      isMeetingScheduled
        ? [
            {
              title: "Modify meeting",
              icon: <CalendarDaysIcon className="w-4" />,
              handler: handleModifyMeeting,
            },
            {
              title: "Cancel meeting",
              icon: <XCircleIcon className="w-4" />,
              handler: () => modalHelpers.open(CONFIRM_CANCEL_MEETING_MODAL_ID),
            },
          ]
        : [],
    [handleModifyMeeting]
  );

  if (isMeetingScheduled) {
    return (
      <>
        <ActionMenu
          trigger="Edit meeting"
          triggerClassName="ae-link brand-typography-body6 cursor-pointer"
          actions={meetingScheduledActionItems}
          itemClassName="w-max"
        />

        <AccountCancelMeetingConfirmationModal
          campaignId={campaign?.id}
          accountId={account?.id}
        />
      </>
    );
  }

  return null;
};
