import { FC } from "react";

import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import { Tooltip, TooltipContent, TooltipTrigger } from "shared/ui";
import CallingFollowUpModal, {
  CALLING_FOLLOWUP_MODAL,
} from "@/modules/calling/modals/follow-up-modal";
import { HeartIcon } from "@heroicons/react/24/outline";
import { clsxMerge } from "shared/lib/helpers";
import { Portal } from "react-portal";
import { DIALER_MODES } from "@/constants/dialer";

interface CallbackButtonPropsI {
  className?: string;
  buttonClassName?: string;
}

const DIALER_CALLING_FOLLOWUP_MODAL = `dialer_${CALLING_FOLLOWUP_MODAL}`;

export const CallbackButton: FC<CallbackButtonPropsI> = ({
  className,
  buttonClassName,
}) => {
  const { callbackData } = useInCallContext();
  const { mode } = useCallingContext();

  return (
    <>
      <Tooltip>
        <TooltipTrigger className={className}>
          <label
            htmlFor={
              !callbackData?.isCallbackSet ? DIALER_CALLING_FOLLOWUP_MODAL : ""
            }
            className={clsxMerge(
              "btn-nofill save-lead-btn btn w-full",
              buttonClassName,
              {
                disabled: callbackData?.isCallbackSet,
              }
            )}
          >
            <HeartIcon className="mr-2 h-5" /> Set Callback
          </label>
        </TooltipTrigger>
        <TooltipContent>
          <div className="max-w-[300px] text-center">
            You can use this to "save a lead" to work on your own outside of the
            dialer. This will prevent other callers from reaching out to this
            lead until the callback date set passes, at which point it is
            released back for anyone to try to book a meeting.
          </div>
        </TooltipContent>
      </Tooltip>

      <Portal>
        <CallingFollowUpModal
          modalId={DIALER_CALLING_FOLLOWUP_MODAL}
          isNotes={
            ![
              DIALER_MODES.DEFAULT as string,
              DIALER_MODES.AUTODIAL,
              DIALER_MODES.TARGETED,
            ].includes(mode as string)
          }
        />
      </Portal>
    </>
  );
};
