export const customEventData = (data: any) => ({ detail: data });

export const dispatchCustomEvent = ({
  eventType,
  data,
}: {
  eventType: string;
  data?: any;
}) => {
  window.document.dispatchEvent(
    new CustomEvent(eventType, customEventData(data))
  );
};
