import { InternalAxiosRequestConfig } from "axios";

import { getAuthToken } from "../../../auth/token";

// Only apply token to requests that are going to the "authenticated" API (versus the public API)
const checkIfShouldApplyToken = (request: InternalAxiosRequestConfig<any>) =>
  request.baseURL === process.env.NEXT_PUBLIC_API_DOMAIN;

export const handlerAuthenticationInterceptor = (
  request: InternalAxiosRequestConfig<any>
) => {
  if (checkIfShouldApplyToken(request)) {
    request.headers.set("Authorization", `Bearer ${getAuthToken()}`);
  }

  return request;
};
