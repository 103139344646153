import { FC, useMemo, useState } from "react";
import _omit from "lodash/omit";
import { toast } from "react-hot-toast";

import {
  ACCOUNT_CONFIRM_EDIT_NEXT_TOUCH_TIME_MODAL_ID,
  AccountConfirmEditNextTouchTimeConfirmationModal,
} from "@/components/modals/account-edit-touch-time-confirmation-modal";
import {
  NEXT_TOUCH_TIME_OPTIONS,
  NEXT_TOUCH_TIME_OPTIONS_DAYJS_DURATION_MAP,
  NEXT_TOUCH_TIME_OPTIONS_MAP,
} from "shared/lib/constants/dispositions";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import {
  DATE_TIME_CALENDAR_MODAL_ID,
  DateTimeCalendarModal,
} from "@/modals/date-time-calendar-modal";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";
import { convertTime12to24, DayJs } from "shared/lib/helpers/date";
import { useEditNextTouchTime } from "@/api/routes/account/mutations";
import { CampaignI } from "@/interfaces/campaign";
import { AccountI } from "@/interfaces/accounts";
import { TIMEZONES } from "shared/lib/constants/timezones";
import { ActionMenu, ActionMenuActionI } from "@/components/shared/action-menu";

type NextTouchTimeI = ValueOfObjectFields<typeof NEXT_TOUCH_TIME_OPTIONS>;

interface SetNextTouchTimeDropdownPropsI {
  className?: string;
  campaign?: CampaignI;
  account?: AccountI;
}

const calculateNextTouchTime = (selectedOption?: NextTouchTimeI) => {
  if (
    !selectedOption ||
    [
      NEXT_TOUCH_TIME_OPTIONS.NONE as string,
      NEXT_TOUCH_TIME_OPTIONS.CUSTOM,
    ].includes(selectedOption)
  ) {
    return undefined;
  }

  return DayJs().add(
    DayJs.duration(NEXT_TOUCH_TIME_OPTIONS_DAYJS_DURATION_MAP[selectedOption])
  );
};

export const SetNextTouchTimeDropdown: FC<SetNextTouchTimeDropdownPropsI> = ({
  className,
  campaign,
  account,
}) => {
  const { mutateAsync: editNextTouchTime } = useEditNextTouchTime();

  const [selectedNextTouchTime, setSelectedNextTouchTime] =
    useState<NextTouchTimeI>();

  // Click handler for the dropdown options, works for all static options and custom one.
  const handleConfirmChangeNextTouchTime = (value: NextTouchTimeI) => {
    if (value === NEXT_TOUCH_TIME_OPTIONS.CUSTOM) {
      modalHelpers.open(DATE_TIME_CALENDAR_MODAL_ID);
    } else {
      setSelectedNextTouchTime(value);
      setTimeout(() => {
        modalHelpers.open(ACCOUNT_CONFIRM_EDIT_NEXT_TOUCH_TIME_MODAL_ID);
      }, 100);
    }
  };

  const actionItems: ActionMenuActionI[] = useMemo(
    () =>
      Object.values(_omit(NEXT_TOUCH_TIME_OPTIONS, ["NONE"])).map((value) => ({
        title: NEXT_TOUCH_TIME_OPTIONS_MAP[value],
        handler: () =>
          handleConfirmChangeNextTouchTime(value as NextTouchTimeI),
      })),
    [handleConfirmChangeNextTouchTime]
  );

  // Handler for custom date time modal
  const onSetCustomNextTouchTime = (
    date: string,
    time: string,
    timezone: string
  ) => {
    const { hours, minutes } = convertTime12to24(time);

    const customNextTouchTimeISO = DayJs(date)
      .hour(hours)
      .minute(minutes)
      .tz(TIMEZONES.find((tz) => tz.fullZoneName === timezone)?.tzCode, true)
      .toISOString();

    modalHelpers.close(DATE_TIME_CALENDAR_MODAL_ID);

    updateNextTouchTime(customNextTouchTimeISO);
  };

  // This function should be called as the final one after user confirms the change
  // of next touch time either from confirmation modal or custom date time modal.
  const updateNextTouchTime = (nextTouchTimeISO: string) => {
    if (!campaign?.id || !account?.id || !nextTouchTimeISO) {
      return toast.error(
        "Campaign or account IDs are not valid, or next touch time is not selected, please try again."
      );
    }

    return editNextTouchTime({
      campaignId: campaign.id,
      accountId: account.id,
      nextTouchTime: nextTouchTimeISO,
    });
  };

  return (
    <>
      <ActionMenu
        className={className}
        menuClassName="max-h-[200px] overflow-y-auto"
        trigger={
          <button className="ae-link brand-typography-body6">Edit</button>
        }
        itemClassName="w-max min-w-[120px]"
        actions={actionItems}
      />

      <AccountConfirmEditNextTouchTimeConfirmationModal
        formattedNextTouchTime={calculateNextTouchTime(
          selectedNextTouchTime
        )?.format("MMM D, YYYY, h:mm A")}
        onConfirm={() =>
          updateNextTouchTime(
            calculateNextTouchTime(
              selectedNextTouchTime
            )?.toISOString() as string
          )
        }
      />

      <DateTimeCalendarModal
        title="Set Next Touch Time"
        descritpion="Specify date and time when to remind you to call back"
        onSuccess={onSetCustomNextTouchTime}
      />
    </>
  );
};
