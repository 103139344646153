import { useEffect, useState } from "react";
import clsx from "clsx";
import { DATE_FORMAT_ONLY_TIME } from "shared/lib/constants/time";
import { getInitialsFromUsername, safeGetDate } from "shared/lib/helpers";
import Avatar from "shared/ui/avatar";

export const Message = ({
  name,
  message,
  avatarUrl,
  timestamp,
  isUnread,
  isAvatarOnline,
  lastOnlineTimeString,
}: {
  name?: string;
  message?: string;
  avatarUrl?: string;
  timestamp?: string;
  isUnread?: boolean;
  isAvatarOnline?: boolean;
  lastOnlineTimeString?: string | null;
}) => {
  const [isUnreadBadgeVisible, setUnreadBadgeVisible] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setUnreadBadgeVisible(false);
    }, 3000);
  }, []);

  return (
    <div className="relative flex gap-3">
      <div>
        <Avatar
          isLazy
          showActiveStatus
          isOnline={isAvatarOnline}
          src={avatarUrl}
          placeholderText={getInitialsFromUsername(name)}
          className="h-8 w-8"
        />
      </div>

      <div>
        <div className="flex items-center gap-3">
          <p className="ae-typography-h3">{name}</p>
          <p className="b-typography-body3 uppercase">
            {safeGetDate(timestamp, DATE_FORMAT_ONLY_TIME)}
          </p>

          {isUnread && (
            <p
              className={clsx(
                "b-typography-detail2 rounded-[4px] bg-[#F88167] text-[white]",
                "px-2 py-0.5",
                "transition-[opacity]",
                {
                  "opacity-0": !isUnreadBadgeVisible,
                  "opacity-100": isUnreadBadgeVisible,
                }
              )}
            >
              New
            </p>
          )}
        </div>

        <p className="b-typography-detail2 text-black/60">
          {lastOnlineTimeString}
        </p>

        <p className="b-typography-body3 mt-1">{message}</p>
      </div>
    </div>
  );
};
