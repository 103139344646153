import { MutableRefObject, useCallback, useRef } from "react";
import { Field, Form, Formik } from "formik";
import clsx from "clsx";
import { RadioButton } from "shared/ui";
import { modal } from "@/modals/index";
import { TARGET_STATUS_TO_MODAL_ID_MAP } from "@/modules/pipeline/open/constants";
import { useAccountsContext } from "@/hooks/use-accounts-context";
import { ModalAccountDataI } from "@/context/accounts";
import { ACCOUNT_STATUSES } from "shared/lib/constants/account";
import { AccountStatusI } from "shared/lib/interfaces/account";

interface AccountChangeStatusFormValuesI {
  status?: AccountStatusI;
}

export const ACCOUNT_CHANGE_STATUS_MODAL_ID = "account-change-status-modal";

const SUPPORTED_STATUS_TRANSITIONS = [
  ACCOUNT_STATUSES.BACKLOG,
  ACCOUNT_STATUSES.CALLBACK,
  ACCOUNT_STATUSES.MEETING_SCHEDULED,
];
const STATUS_REQUIRING_ACTIONS = [
  ACCOUNT_STATUSES.CALLBACK,
  ACCOUNT_STATUSES.MEETING_SCHEDULED,
];

// this ACCOUNT_STATUSES_MAP is local to this file because these strings make more sense for this specific context.
// Ex. Here MEETING_SCHEDULED maps to "Book a meeting", but everywhere else we have "Meeting booked".
const ACCOUNT_STATUSES_MAP = {
  [ACCOUNT_STATUSES.BACKLOG]: "No Status",
  [ACCOUNT_STATUSES.CALLBACK]: "Schedule a Callback",
  [ACCOUNT_STATUSES.MEETING_SCHEDULED]: "Book a Meeting",
  [ACCOUNT_STATUSES.FEEDBACK_RECEIVED]: "Feedback Received",
  [ACCOUNT_STATUSES.NO_SHOW]: "No Show",
} as const;

const EnhancedRadioButton = RadioButton(Field);

const checkIfStatusRequiresAction = (status: AccountStatusI) =>
  STATUS_REQUIRING_ACTIONS.includes(
    status as (typeof STATUS_REQUIRING_ACTIONS)[number]
  );

const getNextButtonText = (
  currentStatus: AccountStatusI | undefined,
  targetStatus: AccountStatusI | undefined
) => {
  if (
    currentStatus &&
    targetStatus &&
    checkIfStatusRequiresAction(targetStatus)
  ) {
    switch (targetStatus) {
      case ACCOUNT_STATUSES.MEETING_SCHEDULED:
        return currentStatus === targetStatus ? "Modify Meeting" : "Next";
      case ACCOUNT_STATUSES.CALLBACK:
        return currentStatus === targetStatus ? "Modify Callback" : "Next";
      default:
        return "Next";
    }
  }

  return "Update";
};

const AccountChangeStatusModal = ({
  blockedStatuses = [],
  modalId = ACCOUNT_CHANGE_STATUS_MODAL_ID,
  onClose = () => {},
  onSuccess = () => {},
}: {
  blockedStatuses?: Array<
    (typeof ACCOUNT_STATUSES)[keyof typeof ACCOUNT_STATUSES]
  >;
  modalId?: string;
  onClose?: () => void;
  onSuccess?: () => void;
}) => {
  const {
    updateAccountWithCampaign,
    isAccountUpdating,
    modalAccount,
    setModalAccount,
  } = useAccountsContext();
  const { accountId, campaignId, accountName, accountStatus } =
    modalAccount || {};
  const checkboxRef = useRef() as MutableRefObject<HTMLInputElement>;

  const handleClose = () => {
    checkboxRef.current.click();
    onClose();
  };

  const handleUpdateAccountStatus = useCallback(
    (values: AccountChangeStatusFormValuesI) => {
      if (!accountId || !campaignId || !values.status) {
        return;
      }

      if (checkIfStatusRequiresAction(values.status)) {
        setModalAccount(
          (currentModalAccount) =>
            ({
              ...currentModalAccount,
              targetAccountStatus: values.status,
            } as ModalAccountDataI)
        );

        setTimeout(
          () =>
            modal.trigger(
              TARGET_STATUS_TO_MODAL_ID_MAP[
                values.status as keyof typeof TARGET_STATUS_TO_MODAL_ID_MAP
              ]
            ),
          100
        );

        modal.trigger(ACCOUNT_CHANGE_STATUS_MODAL_ID);
      } else {
        if (accountStatus === values.status) {
          return handleClose();
        }

        updateAccountWithCampaign(accountId, campaignId, {
          status: values.status,
        }).then(() => {
          onSuccess();
          handleClose();
        });
      }
    },
    [updateAccountWithCampaign]
  );

  return (
    <>
      <input
        type="checkbox"
        id={modalId}
        className="modal-toggle"
        ref={checkboxRef}
      />

      <div className="qualification-criteria-modal modal">
        <div className="modal-box relative min-w-[520px] rounded-md p-6">
          <div>
            <p className="ae-typography-body1 mb-1 font-semibold">
              Change status
            </p>
            <h2 className="ae-typography-body2 text-black/60">
              Update account status for {accountName}
            </h2>

            <Formik
              initialValues={{ status: accountStatus }}
              onSubmit={handleUpdateAccountStatus}
            >
              {({ values }) => (
                <Form className="mt-3 flex flex-col gap-3">
                  {Object.values(SUPPORTED_STATUS_TRANSITIONS).map((key) => (
                    <EnhancedRadioButton
                      key={key}
                      className="w-full p-1"
                      name="status"
                      disabled={blockedStatuses.includes(key)}
                      label={
                        ACCOUNT_STATUSES_MAP[
                          key as keyof typeof ACCOUNT_STATUSES_MAP
                        ]
                      }
                      value={key}
                    />
                  ))}

                  <div className="mt-6 flex justify-end">
                    <button
                      className={clsx("btn-ae-text mr-2", {
                        disabled: isAccountUpdating,
                      })}
                      onClick={handleClose}
                    >
                      Cancel
                    </button>

                    <button
                      type="submit"
                      className={clsx("btn-ae-default", {
                        disabled: isAccountUpdating,
                      })}
                    >
                      {getNextButtonText(accountStatus, values.status)}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountChangeStatusModal;
