import { useMemo } from "react";

import { BaseControlButton } from "@/modules/pipeline/account-details/control-menu/buttons/base-control-button";
import { CalendarIcon } from "shared/ui/icons";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { ACCOUNT_BOOK_MEETING_MODAL_ID } from "@/modals/account-book-meeting-modal";
import { AccountStatusI, ContactDetailI } from "shared/lib/interfaces/account";
import { useAccountsContext } from "@/hooks/use-accounts-context";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import {
  ACCOUNT_DISPOSITION_STATUSES,
  ACCOUNT_STATUSES,
} from "shared/lib/constants/account";
import ButtonMenuItem from "@/components/shared/button-menu-item";
import ButtonMenu from "shared/ui/button-menu";
import { getFullEntityName } from "shared/lib/helpers";

export const MeetingButton = () => {
  const { setModalAccount } = useAccountsContext();

  const {
    accountUserDisposition,
    account,
    campaign,
    contacts,
    accountDetails,
  } = useAccountDetailsContext();

  // User can either book a meeting or reschedule it depending on the account status
  const actionLabel = useMemo(() => {
    if (
      accountDetails.data?.account_status?.status ===
      ACCOUNT_DISPOSITION_STATUSES.MEETING_SCHEDULED
    ) {
      return "Reschedule meeting";
    }

    return "Book meeting";
  }, [accountDetails.data?.account_status?.status]);

  const handleOpenBookMeetingModal = ({
    title = "",
    email = "",
    first_name,
    last_name,
  }: ContactDetailI) => {
    const name = getFullEntityName({ first_name, last_name });

    setModalAccount({
      campaignId: campaign?.id || "",
      accountId: account?.id || "",
      accountName: account?.name || "",
      accountStatus: accountUserDisposition?.status as AccountStatusI,
      targetAccountStatus: ACCOUNT_STATUSES.MEETING_SCHEDULED,
      contact: {
        title,
        email,
        name,
      },
      followUpTime: accountUserDisposition?.follow_up_time || "",
    });

    setTimeout(() => modalHelpers.open(ACCOUNT_BOOK_MEETING_MODAL_ID));
  };

  const items = useMemo(
    () =>
      contacts?.map((contact) => {
        let itemLabel = "Contact without email";
        const firstOrLastName = contact.first_name || contact.last_name;
        const { email } = contact;

        if (firstOrLastName) {
          itemLabel = email ? `${firstOrLastName} - ${email}` : firstOrLastName;
        }

        return (
          <ButtonMenuItem
            className="text-right"
            text={itemLabel}
            onClick={() => handleOpenBookMeetingModal(contact)}
          />
        );
      }) || [],
    [contacts]
  );

  if (contacts?.length === 1) {
    return (
      <BaseControlButton
        className="btn-ae-default"
        onClick={() => handleOpenBookMeetingModal(contacts[0])}
      >
        <CalendarIcon className="mb-1 mt-1 w-4" />
        <span>{actionLabel}</span>
      </BaseControlButton>
    );
  }

  return (
    <ButtonMenu
      items={items}
      className="p-0"
      position="end"
      containerClassName="dropdown-top"
      contentClassName="w-max max-w-[300px] mt-0 mb-2"
      renderTrigger={() => (
        <BaseControlButton className="btn-ae-default">
          <CalendarIcon className="mb-1 mt-1 w-4" />
          <span>{actionLabel}</span>
        </BaseControlButton>
      )}
    />
  );
};
