import { AccountActivityI } from "../interfaces/account";

export const ACCOUNT_ACTIVITY_TYPES = {
  CALLBACK_BOOKED: "callback_booked",
  BOOKED_MEETING: "booked_meeting",
  CALL_DISPOSITION: "call_disposition",
  CONTACT_ADDED: "contact_added",
  CALLED_BACK: "called_back",
  AE_NOTE: "ae_note",
  CANCEL_MEETING: "cancel_meeting",
  ACCOUNT_DNC: "account_dnc",
  ACCOUNT_UN_DNC: "account_undnc",
  USER_NOTE: "user_note",
  CALL: "call",
  SET_BACKLOG: "set_backlog",
  MARK_TENTATIVE_INTEREST_DONE: "mark_tentative_interest_done",
  SYSTEM_DQ: "system_dq",
  HIDE_CONTACT: "hide_contact",

  // Activity types from catchups
  EMAIL_REQUEST: "email_request",
  EMAIL_SENT: "email_sent",
  EMAIL_RECEIVED: "email_received",
  EMAIL_REQUEST_REJECTED: "email_request_rejected",
  MARK_DONE: "mark_todo_done",
  ARCHIVE: "archive",
  UNARCHIVE: "unarchive",
  DISQUALIFY_LEAD: "disqualify_lead",
  SKIP_LEAD: "skip_lead",
  TODO_FOLLOWUP_EMAIL: "todo_follow_up_email",
} as const;

export const ACCOUNT_ACTIVITY_TYPES_WITHOUT_CONTACT: AccountActivityI[] = [
  ACCOUNT_ACTIVITY_TYPES.CONTACT_ADDED,
  ACCOUNT_ACTIVITY_TYPES.AE_NOTE,
  ACCOUNT_ACTIVITY_TYPES.ACCOUNT_DNC,
  ACCOUNT_ACTIVITY_TYPES.ACCOUNT_UN_DNC,
  ACCOUNT_ACTIVITY_TYPES.USER_NOTE,
  ACCOUNT_ACTIVITY_TYPES.ARCHIVE,
  ACCOUNT_ACTIVITY_TYPES.UNARCHIVE,
];

export const ACCOUNT_STATUSES = {
  BACKLOG: "backlog", //"Backlog" is showcased as "No status" on the UI
  CALLBACK: "callback",
  MEETING_SCHEDULED: "meeting_scheduled",
  FEEDBACK_RECEIVED: "feedback",
  NO_SHOW: "no_show",
  ARCHIVE: "archive",
} as const;

// The new account statuses which were introduced for the maxed dialer and nurturing lists
export const ACCOUNT_DISPOSITION_STATUSES = {
  COLD_LEAD: "cold_lead",
  NURTURE: "nurture",
  MEETING_SCHEDULED: "meeting_scheduled",
  FEEDBACK_RECEIVED: "feedback",
  DISQUALIFIED: "disqualified",
} as const;

export const ACCOUNT_DISPOSITION_STATUS_LABELS = {
  [ACCOUNT_DISPOSITION_STATUSES.COLD_LEAD]: "Cold",
  [ACCOUNT_DISPOSITION_STATUSES.NURTURE]: "Nurture",

  // Feedback is not actually used and added here to avoid TS errors
  [ACCOUNT_DISPOSITION_STATUSES.FEEDBACK_RECEIVED]: "Feedback",

  [ACCOUNT_DISPOSITION_STATUSES.MEETING_SCHEDULED]: "Meeting Scheduled",
  [ACCOUNT_DISPOSITION_STATUSES.DISQUALIFIED]: "Disqualified",
} as const;

export const ACCOUNT_STATUSES_MAP = {
  [ACCOUNT_STATUSES.BACKLOG]: "No Status", //"Backlog" is showcased as "No status" on the UI
  [ACCOUNT_STATUSES.CALLBACK]: "Callback",
  [ACCOUNT_STATUSES.MEETING_SCHEDULED]: "Meeting Scheduled",
  [ACCOUNT_STATUSES.FEEDBACK_RECEIVED]: "Feedback Received",
  [ACCOUNT_STATUSES.NO_SHOW]: "No Show",
  [ACCOUNT_STATUSES.ARCHIVE]: "Archive",
} as const;
