import { AxiosInstance } from "axios";

import { interceptorResponse } from "./interceptor-response";
import { checkIfClient } from "shared/lib/helpers";
import { interceptorRequest } from "@/api/interceptors/interceptor-request";

export const interceptors = (instance: AxiosInstance) => {
  if (checkIfClient()) {
    interceptorRequest(instance);
    interceptorResponse(instance);
  }
};
