import { FC, useCallback } from "react";
import { PlusIcon } from "@heroicons/react/24/solid";

import { CREATE_NEW_LEAD_MODAL_ID } from "@/modals/create-new-lead-modal";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { AccountDetailsV2LeadItem } from "@/modules/pipeline/account-details/sidebar/sections/leads-section/lead-item";
import {
  ACCOUNT_SIDEBAR_ACTION_BUTTON_CLASSNAME,
  AccountDetailsV2SidebarSection,
} from "@/modules/pipeline/account-details/sidebar/sections/section";
import { ACCOUNT_STATUSES } from "shared/lib/constants/account";
import { ContactDetailI } from "shared/lib/interfaces/account";
import { DataStatesWrapper } from "shared/ui/data-states-wrapper";
import CenteredSpinner from "shared/ui/spinners/centered-spinner";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";

const ACCOUNT_STATUSES_PROTECTED_FROM_DELETE = [
  ACCOUNT_STATUSES.MEETING_SCHEDULED,
  ACCOUNT_STATUSES.CALLBACK,
];

interface LeadsSectionPropsI {
  className?: string;
}

export const LeadsSection: FC<LeadsSectionPropsI> = ({ className }) => {
  const { accountUserDisposition, accountDetails } = useAccountDetailsContext();

  const contacts = accountDetails?.data?.contacts;

  // make sure contact doesn't have any significant upcoming events like a booked meeting
  const checkIfContactDeletionDisabled = useCallback(
    (contact: ContactDetailI) =>
      accountUserDisposition?.status &&
      accountUserDisposition.last_contact_id === contact.contact_id &&
      ACCOUNT_STATUSES_PROTECTED_FROM_DELETE.find(
        (status) => status === accountUserDisposition?.status
      ),
    [accountUserDisposition]
  );

  return (
    <AccountDetailsV2SidebarSection
      title="Leads"
      className={className}
      actionsSection={
        <button
          className={ACCOUNT_SIDEBAR_ACTION_BUTTON_CLASSNAME}
          onClick={() => modalHelpers.open(CREATE_NEW_LEAD_MODAL_ID)}
        >
          <PlusIcon className="h-4 w-4" />
          Add Lead
        </button>
      }
    >
      <DataStatesWrapper
        api={accountDetails}
        loading={<CenteredSpinner className="h-[160px]" />}
      >
        {contacts && (
          <div className="flex animate-fadein flex-col divide-y">
            {contacts.map((contact) => (
              <AccountDetailsV2LeadItem
                key={contact.id}
                contact={contact}
                className="lg:min-w-none py-6"
                isDeleteContactAllowed={
                  contacts.length > 1 &&
                  !checkIfContactDeletionDisabled(contact)
                }
              />
            ))}
          </div>
        )}
      </DataStatesWrapper>
    </AccountDetailsV2SidebarSection>
  );
};
