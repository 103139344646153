import { FC } from "react";
import clsx from "clsx";
import { twMerge } from "tailwind-merge";
import { Spinner } from "shared/ui/spinners/spinner";

interface CenteredSpinnerPropsI {
  className?: string;
}

const CenteredSpinner: FC<CenteredSpinnerPropsI> = ({ className }) => (
  <div className={clsx(twMerge("relative w-full", className))}>
    <Spinner className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
  </div>
);

export default CenteredSpinner;
