import { MessagingChatMessageI } from "@/interfaces/messaging";
import { Message } from "shared/ui/messages/message";
import { DateSeperator } from "shared/ui/messages/date-seperator";
import { isDifferentDay } from "shared/lib/helpers/date";
import { getFullEntityName } from "shared/lib/helpers";
import { UserIsOnlineMapI } from "shared/lib/interfaces/chat";

export const MessagesList = ({
  messages,
  userOnlineMap,
}: {
  messages?: MessagingChatMessageI[];
  userOnlineMap?: UserIsOnlineMapI;
  isLoading?: boolean;
}) => {
  return (
    <>
      {messages?.length
        ? messages?.map((message, i) => (
            <div key={i} className="duration-500 animate-in fade-in">
              {(i == 0 ||
                (i > 0 &&
                  isDifferentDay(
                    message.timestamp,
                    messages[i - 1].timestamp
                  ))) && <DateSeperator timestamp={message.timestamp} />}

              <div className="py-3">
                <Message
                  name={getFullEntityName(message)}
                  message={message.message}
                  avatarUrl={message.profile_pic_url}
                  isAvatarOnline={userOnlineMap?.[message.sender_id]?.isOnline}
                  lastOnlineTimeString={
                    userOnlineMap?.[message.sender_id]?.lastOnlineTimeString
                  }
                  isUnread={!message.is_seen}
                  timestamp={message.timestamp}
                />
              </div>
            </div>
          ))
        : null}
    </>
  );
};
