import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";

import { useApiClient } from "@/context/api-client";
import { GetAccountDetailsResponse } from "@/api/routes/account/interfaces";

export const getAccountDetailsQueryKey = (
  campaignId: string | undefined,
  accountId: string | undefined
) => ["account-details", campaignId, accountId];

export const useFetchAccountDetails = (
  campaignId: string | undefined,
  accountId: string | undefined
) => {
  const apiClient = useApiClient();

  const accountDetailsApi = useQuery({
    enabled: !!campaignId && !!accountId,
    queryKey: getAccountDetailsQueryKey(campaignId, accountId),
    queryFn: () =>
      apiClient.getAccountDetails(campaignId as string, accountId as string),

    // We want to work with data directly without need to always data.data
    select: (response) =>
      response.data as GetAccountDetailsResponse | undefined,
  });

  useEffect(() => {
    if (accountDetailsApi.isError) {
      toast.error(
        "Failed to fetch account details. Please try to reload the page."
      );
    }
  }, [accountDetailsApi.isError]);

  return accountDetailsApi;
};

export type UseFetchAccountDetailsApiI = ReturnType<
  typeof useFetchAccountDetails
>;
