import { FC, useMemo, useState } from "react";
import DomPurifier from "dompurify";

import { useEffectOnce } from "shared/lib/hooks/use-effect-once";
import { clsxMerge } from "shared/lib/helpers/styles";

interface RenderHTMLPropsI {
  className?: string;
  html: string;
}

const RenderHTML: FC<RenderHTMLPropsI> = ({ className, html }) => {
  const [isRendererReady, setIsRendererReady] = useState(false);

  useEffectOnce(() => {
    DomPurifier.addHook("afterSanitizeAttributes", (node) => {
      if (node.tagName === "A") {
        node.setAttribute("target", "_blank");
        node.setAttribute("rel", "noopener noreferrer");
      }
    });

    setIsRendererReady(true);
  });

  const purifiedHTML = useMemo(
    () =>
      isRendererReady ? DomPurifier.sanitize(html) : "Loading email content...",
    [isRendererReady]
  );

  return (
    <div
      className={clsxMerge("rendered-html", className)}
      dangerouslySetInnerHTML={{ __html: purifiedHTML }}
    />
  );
};

export default RenderHTML;
