// TODO remove NO_SHOW from statuses and all side effects connected to it
// THERE IS NO   NO_SHOW    status there is meeting_outcome: "no_show"

export const MEETING_OUTCOMES = {
  CANCELED: "canceled",
  QUALIFIED: "qualified",
  UNQUALIFIED: "unqualified",
  RESCHEDULED: "rescheduled",
  NO_SHOW: "no_show",
  PENDING: "pending",
} as const;

export const MEETING_OUTCOMES_MAP = {
  [MEETING_OUTCOMES.CANCELED]: "Meeting Canceled",
  [MEETING_OUTCOMES.QUALIFIED]: "Qualified",
  [MEETING_OUTCOMES.UNQUALIFIED]: "Unqualified",
  [MEETING_OUTCOMES.RESCHEDULED]: "Meeting Rescheduled",
  [MEETING_OUTCOMES.NO_SHOW]: "No Show",
  [MEETING_OUTCOMES.PENDING]: "Pending Feedback",
} as const;
