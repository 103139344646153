import { ReactElement, ReactNode, useMemo } from "react";
import type { NextPage } from "next";
import Head from "next/head";
import type { AppProps } from "next/app";

import MetaViewport from "shared/ui/meta-viewport";

import { useWebsocketConnection } from "@/hooks/use-websocket-connection";
import { useDatadogRum } from "@/helpers/datadog";
import { GoogleTagManager } from "@/helpers/tracking";

// FONTs import
// https://fontsource.org/docs/guides/nextjs

// Changed In favour of google fonts
// import "@fontsource/inter/100.css";
// import "@fontsource/inter/200.css";
// import "@fontsource/inter/300.css";
// import "@fontsource/inter/400.css";
// import "@fontsource/inter/500.css";
// import "@fontsource/inter/600.css";
// import "@fontsource/inter/700.css";
// import "@fontsource/inter/800.css";
// import "@fontsource/inter/900.css";

// import HomepageLayout from "@/components/layouts/homepage-layout";
import AppLayout from "@/components/layouts/app-layout";
import { AppLayoutPagePropsI } from "@/interfaces/layout";

import { useRouter } from "next/router";
import {
  APP_LAYOUT_SUPPORTED_ROUTES,
  RESPONSIVE_PAGE_ROUTES,
} from "@/constants/layout";

import "./globals.css";

export type NextPageWithLayout<P = any, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps<AppLayoutPagePropsI> & {
  Component: NextPageWithLayout;
};

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const { route } = useRouter();

  useDatadogRum();
  useWebsocketConnection();

  // Use the layout defined at the page level, if available
  // const getLayout = Component.getLayout ?? ((page) => page);

  const pageInternals = useMemo(
    () => (
      <>
        <MetaViewport
          head={Head}
          responsivePageRoutes={RESPONSIVE_PAGE_ROUTES}
        />

        <GoogleTagManager />

        <Component {...pageProps} />
        {/**
         * NOTE: with a SPA approach the loader regression has been introduced many times
         *       is not able to indicate that loading has been completed
         */}
        {/* <NavigationProgressbar /> */}
      </>
    ),
    [Component, pageProps]
  );

  if (!APP_LAYOUT_SUPPORTED_ROUTES.includes(route)) {
    return pageInternals;
  }

  return <AppLayout layoutProps={pageProps}>{pageInternals}</AppLayout>;
}
