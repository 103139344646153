import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

import { clsxMerge, formatUSPhoneNumber } from "shared/lib/helpers";

import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { EndCallCountdown } from "@/components/modules/calling/control-menu/v2/buttons/call-button/end-call-button/end-call-countdown";
import { useState } from "react";

dayjs.extend(duration);

export const DialingLabel = ({ className }: { className?: string }) => {
  const { contact } = useInCallContext();

  const [isCountdown, setIsCountdown] = useState(true);

  const handleOnIsCountdownChange = (isCountdown?: boolean) => {
    setIsCountdown(!!isCountdown);
  };

  return (
    <div
      className={clsxMerge(
        "min-w-[170px]",
        "w-max rounded-xl bg-error-content px-2 py-1",
        "brand-typography-h7 animate-fadein text-white",
        className
      )}
    >
      <div className="flex items-center justify-center gap-1.5">
        <span>
          Dialing{" "}
          {formatUSPhoneNumber(
            contact?.mobile ? contact?.mobile : contact?.phone
          )}
        </span>
      </div>
      {isCountdown && (
        <div
          className={clsxMerge(
            "brand-typography-detail2 flex items-center justify-center text-white/60",
            "animate-fadein"
          )}
        >
          <span className="mr-1">You can hangup in</span>{" "}
          <EndCallCountdown
            className={`[&::before]:[content:":"]`}
            onChange={handleOnIsCountdownChange}
          />
        </div>
      )}
    </div>
  );
};
