import { FC, Fragment, ReactNode } from "react";

import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";

import { ChevronDownIcon } from "../icons";
import { clsxMerge } from "shared/lib/helpers";

interface ButtonMenuPropsI {
  id?: string;
  btnIcon?: ReactNode;
  text?: string;
  items: ReactNode[];
  className?: string;
  containerClassName?: string;
  contentClassName?: string;
  position?: "start" | "end";
  isArrowVisible?: boolean;
  renderTrigger?: () => ReactNode;
}

const ButtonMenu: FC<ButtonMenuPropsI> = ({
  id,
  btnIcon,
  text,
  items,
  className,
  containerClassName,
  contentClassName,
  isArrowVisible = true,
  position = "end",
  renderTrigger,
}) => (
  <div
    className={clsxMerge(
      "dropdown",
      {
        "dropdown-end": !position || position === "end",
        "dropdown-start": position === "start",
      },
      containerClassName
    )}
  >
    <label
      id={id}
      tabIndex={0}
      className={clsxMerge("dropdown min-w-[40px] p-3", className)}
    >
      {renderTrigger ? (
        renderTrigger()
      ) : (
        <Fragment>
          {text ? (
            <div className="flex items-center gap-2">
              {btnIcon}
              {text} {isArrowVisible && <ChevronDownIcon className="h-2" />}
            </div>
          ) : (
            btnIcon || <EllipsisVerticalIcon className="relative -top-1 h-6" />
          )}
        </Fragment>
      )}
    </label>
    <ul
      tabIndex={0}
      className={clsxMerge(
        "menu-sm dropdown-content menu mt-2 flex-nowrap rounded-lg shadow-xl",
        "z-[30] w-52",
        "bg-base-100 shadow",
        "ae-typography-body2",
        "max-h-[40vh] overflow-hidden overflow-y-auto",
        contentClassName
      )}
    >
      {items.filter(Boolean).map((item, i) => (
        <li
          className="[&:active>*]:active:bg-black/20 [&:active>*]:active:text-black"
          key={i}
        >
          {item}
        </li>
      ))}
    </ul>
  </div>
);

export default ButtonMenu;
