import { createContext } from "react";
import {
  DefaultCallingContextI,
  FrequentlyChangingCallingContextI,
  InCallContextI,
} from "@/interfaces/contexts/calling";

import {
  DIALER_MODES,
  DIALER_STATES,
  DIALER_VERSIONS,
} from "@/constants/dialer";
import { asyncGet } from "@/helpers/context";

export const defaultLeadInfo = {
  id: "",
  title: "",
  name: "",

  account_id: "",
  company_name: "",
  company_url: "",
  company_size: "",
  company_industry: "",
  company_revenue: "",
  company_state: "",

  company_phone: "",
  company_email: "",
  company_linkedin_url: "",

  timezone_info: "",
};

export const defaultContextObject = {
  version: DIALER_VERSIONS.V1,
  mode: DIALER_MODES.DEFAULT,

  callingState: DIALER_STATES.LOADING,

  leadInfo: defaultLeadInfo,

  isMuted: false,
  isMusic: true,
  isTourComplete: false,
  leadQueue: [],
  callerProfile: {
    autoDial: false,
    setAutoDial: () => {},
  },
  callOnDemandConfig: {
    setCallId: () => {},
  },

  setDevice: () => {},
  setCall: () => {},
  setIdentity: () => {},
  setSocket: () => {},
  setCampaignID: () => {},
  setCampaign: () => {},
  setInputDevices: () => {},
  setOutputDevices: () => {},
  setMuted: () => {},
  setCallingState: () => {},
  setLeadQueue: () => {},
  setLeadInfo: () => {},
  setIsCallOnDemand: () => {},
  setIsMusic: () => {},
  setIsTourComplete: () => {},
  setMode: () => {},
  setVersion: () => {},

  asyncGet: (setStateFunc) =>
    new Promise((resolve) => {
      setStateFunc((v) => {
        resolve(v as typeof v);
        return v;
      });
    }),

  clear: () => {},
} as DefaultCallingContextI;

export const frequentlyChangingObject = {
  inputVol: 0,
  outputVol: 0,
  callQuality: 5,

  setCallQuality: () => {},
  setInputVol: () => {},
  setOutputVol: () => {},
};

export const inCallContextObject = {
  caller_phone: "",
  //TODO remove to empty
  account: {
    // id: "12r3tesfasdf",
    // name: "OG Greens",
    // employee_count: 1000,
    // industry: "Cybersecurity",
    // revenue: 100000,
    // hq_state: "New York",
    // hq_city: "Brooklyn",
    // website: "www.og_greens.com",
  },
  //TODO remove to empty
  contact: {
    // id: "124refwasfsfgfasd",
    // first_name: "Andeula",
    // last_name: "Gross",
    // phone: "5512483447",
    // mobile: "5512483447",
    // email: "herman@glencoco.io",
    // linkedin: "https://linkedin.com",
    // title: "Director of Finance",
  },
  metadata: {},
  activity_log: [],
  calendly_uri: "",
  calendlyEvent: undefined,
  callbackData: {},
  qualificationCriteria: [],
  startAt: "",
  endAt: "",
  isMeetingCanceled: false,

  setCallerPhone: () => {},
  setAccount: () => {},
  setContact: () => {},
  setMetadata: () => {},
  setActivityLog: () => {},
  setCalendlyUri: () => {},
  setCalendlyEvent: () => {},
  setCallbackData: () => {},
  setQualificationCriteria: () => {},
  setLiveTransferStatus: () => {},
  setStartAt: () => {},
  setEndAt: () => {},
  setIsMeetingCanceled: () => {},

  clear: () => {},

  asyncGet: asyncGet,
};

export const CallingContext =
  createContext<DefaultCallingContextI>(defaultContextObject);

export const FrequentlyChangingCallingContext =
  createContext<FrequentlyChangingCallingContextI>(frequentlyChangingObject);

export const InCallContext = createContext<InCallContextI>(inCallContextObject);
