import { DISPOSITIONS as ALL_DISPOSITIONS } from "shared/lib/constants/dispositions";

export const DISPOSITIONS = {
  TENTATIVE_INTEREST: ALL_DISPOSITIONS.TENTATIVE_INTEREST,
  NO_PICK_UP: ALL_DISPOSITIONS.NO_PICK_UP,
  NOT_INTERESTED: ALL_DISPOSITIONS.NOT_INTERESTED,
  NOT_QUALIFIED: ALL_DISPOSITIONS.NOT_QUALIFIED,
  BAD_DATA: ALL_DISPOSITIONS.BAD_DATA,
  OTHER: ALL_DISPOSITIONS.OTHER,
  BOOKED_MEETING: ALL_DISPOSITIONS.BOOKED_MEETING,
  CALLBACK_BOOKED: ALL_DISPOSITIONS.CALLBACK_BOOKED,
} as const;

export const DISPOSITIONS_STRING_MAP = {
  [DISPOSITIONS.BOOKED_MEETING]: "Booked meeting",
  [DISPOSITIONS.TENTATIVE_INTEREST]: "Tentative interest",
  [DISPOSITIONS.NO_PICK_UP]: "No pick up",
  [DISPOSITIONS.CALLBACK_BOOKED]: "Callback booked",
  [DISPOSITIONS.NOT_INTERESTED]: "Not interested",
  [DISPOSITIONS.NOT_QUALIFIED]: "Not qualified",
  [DISPOSITIONS.BAD_DATA]: "Bad data / do not call",
  [DISPOSITIONS.OTHER]: "Other",
} as const;

// DIALER LEGACY
export const DISPOSITION_DETAILS = {
  REQUESTED_MORE_MATERIALS: "Requested more materials",
  VOICEMAIL_LEFT: "Did not pick up, left Voicemail",
  VOICEMAIL_NOT_LEFT: "Did not pick up, did not leave Voicemail",
  PITCH_REJECTED_DM: "Pitch rejected by decision maker",
  INTRO_REJECTED_DM: "Intro rejected by decision maker",
  GATEKEEPER_REJECTED: "Rejected by Gatekeeper",
  NOT_QUALIFIED_BUDGET: "Not qualified - Budget",
  NOT_QUALIFIED_AUTHORITY: "Not qualified - Authority",
  NOT_QUALIFIED_NEED: "Not qualified - Need",
  NOT_QUALIFIED_TIMING: "Not qualified - Timing",
  BAD_PHONE_NUMBER: "Bad phone number",
  DO_NOT_CALL: "Request do not call list",
  DATA_WRONG: "Person data wrong",
  OTHER: "Other",
};

export const DISPOSITION_DETAILS_MAP = {
  [DISPOSITION_DETAILS.REQUESTED_MORE_MATERIALS]: "more_materials",
  [DISPOSITION_DETAILS.VOICEMAIL_LEFT]: "voicemail_left",
  [DISPOSITION_DETAILS.VOICEMAIL_NOT_LEFT]: "voicemail_not_left",
  [DISPOSITION_DETAILS.PITCH_REJECTED_DM]: "pitch_rejected_dm",
  [DISPOSITION_DETAILS.INTRO_REJECTED_DM]: "intro_rejected_dm",
  [DISPOSITION_DETAILS.GATEKEEPER_REJECTED]: "gatekeeper_rejected",
  [DISPOSITION_DETAILS.NOT_QUALIFIED_BUDGET]: "not_qualified_budget",
  [DISPOSITION_DETAILS.NOT_QUALIFIED_AUTHORITY]: "not_qualified_authority",
  [DISPOSITION_DETAILS.NOT_QUALIFIED_NEED]: "not_qualified_need",
  [DISPOSITION_DETAILS.NOT_QUALIFIED_TIMING]: "not_qualified_timing",
  [DISPOSITION_DETAILS.BAD_PHONE_NUMBER]: "bad_phone_number",
  [DISPOSITION_DETAILS.DO_NOT_CALL]: "do_not_call",
  [DISPOSITION_DETAILS.DATA_WRONG]: "data_wrong",
  [DISPOSITION_DETAILS.OTHER]: "other",
} as const;

// Used in Account Executive Survey
export const MEETING_DISPOSITIONS = {
  NO_SHOW_UP: "no_show",
  RESCHEDULED: "reschedule",
  SHOW_UP: "show",
  CANCELED: "canceled",
} as const;
