import { useEffect, useState } from "react";

import {
  AccountExecutiveI,
  AccountI,
  AccountUserDispositionI,
  AccountUserListI,
  ContactDetailI,
} from "@/interfaces/accounts";
import { GetAccountDetailsDataI } from "@/interfaces/account-details";
import { CampaignI } from "@/interfaces/campaign";
import { getAccountDetailsData } from "@/helpers/account-details";

import { checkIfClient } from "shared/lib/helpers";

import { useCampaigns } from "@/hooks/use-campaigns";

import { CUSTOM_EVENTS } from "@/constants/custom-events";

export const useAccountDetails = (
  campaignId: string,
  accountId: string,
  prefetchedData?: GetAccountDetailsDataI
) => {
  const { campaigns, refillCampaignsByIds } = useCampaigns();

  const [accountData, setAccountData] = useState<AccountI | undefined>(
    prefetchedData?.account
  );
  const [contacts, setContacts] = useState<ContactDetailI[] | undefined>(
    prefetchedData?.contacts
  );
  const [accountExecutiveData, setAccountExecutiveData] = useState<
    AccountExecutiveI | undefined
  >(prefetchedData?.accountExecutiveData);
  const [accountUserDisposition, setAccountUserDisposition] = useState<
    AccountUserDispositionI | undefined
  >(prefetchedData?.accountUserDisposition);
  const [accountUserLists, setAccountUserLists] = useState<
    AccountUserListI[] | undefined
  >(prefetchedData?.accountUserLists);
  const [campaign, setCampaign] = useState<CampaignI | undefined>(
    prefetchedData?.campaign
  );

  const [isErrorOccurred, setIsErrorOccurred] = useState<boolean>(false);
  const updateCampaign = (campaign_id?: string) => {
    const camp = campaigns?.find((c) => c.id === campaign_id);

    setCampaign(camp);
  };

  const updateAccountDetails = async () => {
    const data = await getAccountDetailsData(campaignId, accountId);

    setIsErrorOccurred(!!data.isNotApprovedForCampaign);

    updateCampaign(data.accountUserDisposition?.campaign_id);

    setAccountData(data.account);
    setAccountExecutiveData(data.accountExecutiveData);
    setAccountUserDisposition(data.accountUserDisposition);
    setAccountUserLists(data.accountUserLists);
    setContacts(data.contacts);
  };

  const onAccountUpdate = () => {
    if (checkIfClient()) {
      updateAccountDetails();
    }
  };

  useEffect(() => {
    updateAccountDetails();

    window.document.addEventListener(
      CUSTOM_EVENTS.ACCOUNT.RELOAD,
      onAccountUpdate
    );

    return () => {
      window.document.removeEventListener(
        CUSTOM_EVENTS.ACCOUNT.RELOAD,
        onAccountUpdate
      );
    };
  }, []);

  useEffect(() => {
    if (accountUserDisposition?.campaign_id) {
      refillCampaignsByIds([accountUserDisposition?.campaign_id]);
    }
  }, [accountUserDisposition?.campaign_id]);

  // NOTE add better messaging so would know why it may fail
  useEffect(() => {
    updateCampaign(accountUserDisposition?.campaign_id);
  }, [accountUserDisposition?.campaign_id, campaigns]);

  return {
    isErrorOccurred,

    campaign,
    account: accountData,
    contacts,
    accountUserDisposition,
    accountExecutiveData,
    accountUserLists,

    onAccountUpdate,
  };
};
