import { FC, ReactNode, useEffect, useState } from "react";

import { InCallContextI } from "@/interfaces/contexts/calling";
import { InCallContext } from "./default-context";
import { LiveTransferStatusI } from "@/interfaces/dialer/live-transfer";
import { asyncGet } from "@/helpers/context";

interface InCallContextProviderPropsI {
  children: ReactNode;
  context: InCallContextI;
}

export const InCallContextProvider: FC<InCallContextProviderPropsI> = ({
  children,
  context,
}) => {
  const [caller_phone, setCallerPhone] = useState(context.caller_phone);
  const [account, setAccount] = useState(context.account);
  const [contact, setContact] = useState(context.contact);
  const [activity_log, setActivityLog] = useState(context.activity_log);
  const [calendly_uri, setCalendlyUri] = useState(context.calendly_uri);
  const [calendlyEvent, setCalendlyEvent] = useState(context.calendlyEvent);
  const [qualificationCriteria, setQualificationCriteria] = useState(
    context.qualificationCriteria
  );
  const [startAt, setStartAt] = useState(context.startAt);
  const [endAt, setEndAt] = useState(context.endAt);
  const [liveTransferStatus, setLiveTransferStatus] = useState<
    LiveTransferStatusI | undefined | ""
  >();
  const [metadata, setMetadata] = useState(context.metadata);
  // Modals data is needed to display specific UI card states
  const [callbackData, setCallbackData] = useState({});
  //V2 Catchup mode when cancelling a meeting
  const [isMeetingCanceled, setIsMeetingCanceled] = useState(false);

  const [ctx, setCallingContext] = useState({
    caller_phone,
    account,
    contact,
    activity_log,
    calendly_uri,
    calendlyEvent,
    qualificationCriteria,
    startAt,
    endAt,
    liveTransferStatus,
    metadata,
    isMeetingCanceled,

    setMetadata,
    setCallerPhone,
    setAccount,
    setContact,
    setActivityLog,
    setCalendlyUri,
    setCalendlyEvent,
    setCallbackData,
    setQualificationCriteria,
    setStartAt,
    setEndAt,
    setLiveTransferStatus,
    setIsMeetingCanceled,

    clear: () => {
      setCallerPhone("");
      setAccount({});
      setContact({});
      setActivityLog([]);
      setCalendlyUri("");
      setCalendlyEvent(undefined);
      setCallbackData({});
      setQualificationCriteria([]);
      setStartAt("");
      setEndAt("");
      setLiveTransferStatus("");
      setMetadata(undefined);
      setIsMeetingCanceled(false);
    },

    asyncGet,
  } as InCallContextI);

  useEffect(() => {
    setCallingContext({
      ...ctx,
      caller_phone,
      account,
      contact,
      activity_log,
      calendly_uri,
      calendlyEvent,
      callbackData,
      qualificationCriteria,
      startAt,
      endAt,
      liveTransferStatus,
      metadata,
      isMeetingCanceled,
    });
  }, [
    caller_phone,
    account,
    contact,
    activity_log,
    calendly_uri,
    calendlyEvent,
    callbackData,
    qualificationCriteria,
    startAt,
    endAt,
    liveTransferStatus,
    metadata,
    isMeetingCanceled,
  ]);

  return (
    <InCallContext.Provider value={ctx as InCallContextI}>
      {children}
    </InCallContext.Provider>
  );
};

export default InCallContextProvider;
