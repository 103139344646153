import { useQueryState } from "nuqs";
import { WIDGETS_SEARCH_QUERY_PARAM_NAMES } from "./constants";
import { useEffect } from "react";
import { WIDGETS } from "@/constants/widgets";
import { widgets } from ".";
import wait from "wait";

export const useWidgetsSearchQueryParams = () => {
  const [widgetType] = useQueryState(
    WIDGETS_SEARCH_QUERY_PARAM_NAMES.WIDGET_TYPE
  );
  const [campaignId] = useQueryState(
    WIDGETS_SEARCH_QUERY_PARAM_NAMES.CAMPAIGN_ID
  );
  const [accountId] = useQueryState(
    WIDGETS_SEARCH_QUERY_PARAM_NAMES.ACCOUNT_ID
  );

  useEffect(() => {
    console.log(widgetType);
    console.log(campaignId);
    console.log(accountId);

    (async () => {
      await wait(300);

      switch (widgetType) {
        case WIDGETS.MAXED_DIALER:
          widgets.open({
            id: WIDGETS.MAXED_DIALER,
            config: {
              campaignId: campaignId as string,
              accountId: accountId as string,
            },
          });
          break;
      }
    })();
  }, [widgetType]);
};
