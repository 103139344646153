import { FC, useEffect, useMemo, useRef } from "react";
import { Field, Form, Formik } from "formik";
import { FormikProps } from "formik/dist/types";
import * as Yup from "yup";
import toast from "react-hot-toast";

import Avatar from "shared/ui/avatar";
import { clsxMerge, getFullEntityName } from "shared/lib/helpers";
import { TextArea } from "shared/ui/ae-user-input";

import { useGlobalContext } from "@/hooks/use-global-context";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";

import { useAddAccountDispositionNote } from "@/api/routes/account/mutations";
import { AccountDispositionNotesEditorFormI } from "@/modules/pipeline/account-details/interfaces";
import { useADPrimaryContentContext } from "../../context";

const ShareFormikValues = ({
  values,
}: {
  values: AccountDispositionNotesEditorFormI;
}) => {
  const { setNote: setSharedNote } = useADPrimaryContentContext();

  useEffect(() => {
    setSharedNote(values.notes);
  }, [values.notes]);

  return null;
};

interface AccountDetailsV2NoteEditorPropsI {
  className?: string;
  onSuccess?: () => void;
}

const EnhancedTextArea = TextArea(Field);

const NotesEditorFormValidationSchema = Yup.object().shape({
  notes: Yup.string().required("A note is required."),
});

const FORM_INITIAL_VALUES: AccountDispositionNotesEditorFormI = {
  notes: "",
};

export const AccountDetailsV2NoteEditor: FC<
  AccountDetailsV2NoteEditorPropsI
> = ({ className, onSuccess }) => {
  const { glencocoUser } = useGlobalContext();
  const {
    account,
    campaign,
    onAccountUpdate: updateAccount,
    accountHistoryData: { reloadData: reloadAccountHistory },
  } = useAccountDetailsContext();
  /**
   * We want to share notes between sections
   * Use case
   *  - Note is not finished but call ends
   *  - Disposition box should show unfinished note content
   */
  const { setNote: setSharedNote } = useADPrimaryContentContext();
  const formRef = useRef<FormikProps<AccountDispositionNotesEditorFormI>>(null);

  const { mutateAsync: addAccountDispositionNote, isPending } =
    useAddAccountDispositionNote();

  const fullUserName = useMemo(
    () => getFullEntityName(glencocoUser),
    [glencocoUser]
  );

  const resetForm = () => {
    if (formRef.current) {
      formRef.current.resetForm();
    }
  };

  const handleSubmit = ({ notes }: AccountDispositionNotesEditorFormI) => {
    if (!account || !campaign) {
      return;
    }

    return addAccountDispositionNote(
      {
        notes,
        noteType: "note",
        accountContactId: "account",
        accountId: account?.id as string,
        campaignId: campaign?.id,
      },
      {
        onSuccess: () => {
          setSharedNote(undefined);
          toast.success("Note has been added successfully");
        },
        onError: () => {
          toast.error("Failed to add note. Please contact Glencoco.");
        },
      }
    )
      .then(() => {
        updateAccount();
        reloadAccountHistory();
        onSuccess?.();
      })
      .finally(resetForm);
  };

  if (!glencocoUser) {
    return null;
  }

  return (
    <div
      className={clsxMerge(
        "w-full rounded-[10px] border bg-white p-5",
        className
      )}
    >
      <div className="flex w-full items-center gap-3">
        <Avatar className="h-8 w-8" placeholderText={fullUserName} />
        <span className="brand-typography-h7">{fullUserName}</span>
      </div>

      <Formik
        innerRef={formRef}
        initialValues={FORM_INITIAL_VALUES}
        validationSchema={NotesEditorFormValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, isValid }) => (
          <Form>
            <div className="mt-5 w-full">
              <ShareFormikValues values={values} />
              <EnhancedTextArea
                name="notes"
                errors={errors.notes}
                touched={touched.notes}
                inputProps={{
                  rows: 0,
                }}
                inputClassName={clsxMerge(
                  "max-h-[200px] min-h-[50px] w-full bg-white p-0",
                  "border-none outline-none rounded-none"
                )}
                placeholder="Type your note here..."
              />
            </div>

            <div className="mt-4 flex w-full items-center justify-end gap-3">
              <button className="btn-ae-text" onClick={resetForm}>
                Cancel
              </button>

              <button
                className={clsxMerge("btn-ae-default", {
                  disabled: isPending || !isValid,
                  loading: isPending,
                })}
              >
                Add Note
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
