import { FC } from "react";
import { DataI } from "..";
import CallingCalendarForm from "../../../calendar-form";

interface DispositionCallbackCalendarI {
  data?: DataI;
  updateData: (data: DataI) => void;
  handleClose: () => void;
}

export const DispositionCallbackCalendar: FC<DispositionCallbackCalendarI> = ({
  data,
  updateData,
  handleClose,
}) => {
  const handleNotesChange = (notesValue: string) => {
    updateData({ notes: notesValue });
  };

  return (
    <CallingCalendarForm
      isNotes={true}
      notes={data?.notes}
      onNotesChange={handleNotesChange}
      successButton={{
        type: "button",
        text: "Submit",
      }}
      onBookingSuccess={() => {
        // Toasts handled already inside
        handleClose();
      }}
    />
  );
};

export default DispositionCallbackCalendar;
