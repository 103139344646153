import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { PipelineListContactI } from "@/interfaces/pipeline/list";

import { CallsMapI, useWSListDialing } from "./use-ws-list-dialing";

import uniqBy from "lodash/uniqBy";
import take from "lodash/take";
import async from "async";

import { DIALER_LIST_DAILING_STATUS } from "@/constants/dialer";
import { ValueOf } from "next/constants";
import { asyncGet } from "@/helpers/context";
import { LocalStorage } from "@/helpers/local-storage";
import { isDialerWidgetOpen } from "@/helpers/widgets";
import { DayJs } from "shared/lib/helpers/date";
import { glencocoClientAPI } from "@/api/glencoco";
import { GetListMemberResponseI } from "@/api/routes/list";

import { datadogRum } from "@datadog/browser-rum";
import { dd } from "@/helpers/datadog";
import { ERROR_CATEGORIES } from "@/constants/errors";
import { ListMemberUpdateMessageI } from "@/interfaces/list-dialing";
import { GetAccountDetailsDataI } from "@/interfaces/account-details";
import { getAccountDetailsData } from "@/helpers/account-details";

const DEFAULT_MAX_LEADS_DIALING = 3;

/**
 * Used as a trigger to check lead status
 */
export const SOFT_CALL_TIMEOUT_SECONDS = 30;

/**
 * Used as a final safeguard for the scenario when lead is
 * returned from the BE as undefined and is basically may
 * be indefinitely reside in dialing state forever
 * so we have to manually move it to the processed
 */
export const HARD_CALL_TIMEOUT_SECONDS = 60;

export type LeadsMapI = Map<string, PipelineListContactI>;
export type CallsMetadataMapI = Map<string, { startDialingAt?: string }>;
export type AccountDetailsMapI = Map<string, GetAccountDetailsDataI>;

export interface HookLeadsQueueManagerI {
  isListenWS?: boolean;
  setIsListenWS?: Dispatch<SetStateAction<boolean>>;

  calls?: CallsMapI;
  callsMetadataMap?: CallsMetadataMapI;
  accountDetailsMap?: AccountDetailsMapI;
  leadsMap: LeadsMapI;
  leadsQueue: PipelineListContactI[];
  leadsDialing: PipelineListContactI[];
  leadsProcessed: PipelineListContactI[];
  connectedLead?: PipelineListContactI;
  maxLeadsDialing: number;

  setLeadsMap: Dispatch<SetStateAction<LeadsMapI>>;
  setAccountDetailsMap: Dispatch<SetStateAction<AccountDetailsMapI>>;
  setCalls: Dispatch<SetStateAction<CallsMapI>>;
  setLeadsQueue: Dispatch<SetStateAction<PipelineListContactI[]>>;
  setLeadsDialing: Dispatch<SetStateAction<PipelineListContactI[]>>;
  setLeadsProcessed: Dispatch<SetStateAction<PipelineListContactI[]>>;
  setConnectedLead: Dispatch<SetStateAction<PipelineListContactI | undefined>>;
  setMaxLeadsDialing: Dispatch<SetStateAction<number>>;

  isQueuePaused: boolean;
  setIsQueuePaused: Dispatch<SetStateAction<boolean>>;
  pauseQueue: () => void;
  resumeQueue: () => void;

  clear: () => void;

  addToLeadsQueue: (_leads: PipelineListContactI[]) => void;
  moveToDialingList: (
    onSuccess?: (leads: PipelineListContactI[]) => void
  ) => Promise<void>;
  isFreeSpotForDialing: (leadsDialing?: PipelineListContactI[]) => boolean;
  saveCallDate: (membershipId: string) => Promise<void>;
  processTimedoutCalls: () => Promise<void>;
  assignConnectedByListMemberMessageUpdate: (
    listStatusMessageUpdate: ListMemberUpdateMessageI
  ) => Promise<void>;
}

const assignLeadStatus = (
  lead: PipelineListContactI,
  status: ValueOf<typeof DIALER_LIST_DAILING_STATUS>
): PipelineListContactI => {
  return { ...lead, status };
};

const selectorValidLeads = (leads: PipelineListContactI[], listId?: string) => {
  return uniqBy(leads, "list_membership_id").filter((lead) => {
    return !(lead.do_not_call || (listId && lead.list_id !== listId));
  });
};

/**
 * Custom hook to manage the leads queue for dialing.
 *
 * @param {PipelineListContactI[]} [leads] - Initial list of leads to be added to the queue.
 * @returns {Object} - Returns an object containing leads queue state and related functions.
 */
export const useLeadsQueueManager = ({
  leads,
  listId,
}: {
  leads?: PipelineListContactI[];
  listId?: string;
}): HookLeadsQueueManagerI => {
  const { calls, setCalls, isListenWS, setIsListenWS } = useWSListDialing();

  /**
   * Manages additional information related to calls, separated from the main calls map to avoid unnecessary
   * side effects, re-renders, and to distinguish between backend data and frontend UI-related logic.
   *
   * The primary focus is on the `startDialingAt` property, which indicates when to timeout dialing a lead if the
   * backend has not returned the appropriate message.
   *
   */
  const [callsMetadataMap, setCallsMetadataMap] = useState<CallsMetadataMapI>(
    new Map()
  );

  /**
   * Manages additional information related to the lead, for every dialing lead there is prefetched account details
   * that is going to be passed to MAXED DIALER widget as soon as it is connected to the prospect
   */
  const [accountDetailsMap, setAccountDetailsMap] =
    useState<AccountDetailsMapI>(new Map());

  const [maxLeadsDialing, setMaxLeadsDialing] = useState(
    DEFAULT_MAX_LEADS_DIALING
  );

  const [leadsQueue, setLeadsQueue] = useState<PipelineListContactI[]>(
    leads || []
  );
  const [leadsDialing, setLeadsDialing] = useState<PipelineListContactI[]>([]);
  const [leadsProcessed, setLeadsProcessed] = useState<PipelineListContactI[]>(
    []
  );

  const [connectedLead, setConnectedLead] = useState<PipelineListContactI>();

  const [leadsMap, setLeadsMap] = useState<LeadsMapI>(new Map());
  useEffect(
    () =>
      setLeadsMap((prevLeadsMap) => {
        const newLeadsMap = new Map(prevLeadsMap);

        leadsQueue.forEach((lead) => {
          newLeadsMap.set(lead.list_membership_id, lead);
        });

        return newLeadsMap;
      }),

    [leadsQueue]
  );

  /**
   * Get max dialing leads at once from local storage
   */
  useEffect(() => {
    const LS = new LocalStorage();

    if (LS.dialerGlobalMaxLeadsDialing) {
      setMaxLeadsDialing(LS.dialerGlobalMaxLeadsDialing);
    }
  }, []);

  /**
   * useEffect hook to update the local storage whenever maxLeadsDialing state changes.
   *
   * Save max dialing leads at once to local storage
   *
   * @param {number} maxLeadsDialing - The current state of maxLeadsDialing.
   */
  useEffect(() => {
    const LS = new LocalStorage();

    if (maxLeadsDialing) {
      LS.dialerGlobalMaxLeadsDialing = maxLeadsDialing;
    }
  }, [maxLeadsDialing]);

  /**
   * State variable to track if the queue is paused
   * Current applicable solution is the call is connected
   * on dialer list
   * @type {[boolean, React.Dispatch<React.SetStateAction<boolean>>]}
   */
  const [isQueuePaused, setIsQueuePaused] = useState(false);

  /**
   * Pauses the queue by setting isQueuePaused to true.
   */
  const pauseQueue = () => {
    setIsQueuePaused(true);
  };

  /**
   * Resumes the queue by setting isQueuePaused to false.
   */
  const resumeQueue = () => {
    setIsQueuePaused(false);
  };

  /**
   * Update valid leads on hook arg update
   */
  useEffect(() => {
    if (!leads) return;

    setLeadsQueue((leadsQueue) =>
      selectorValidLeads([...leadsQueue, ...leads])
    );
  }, [leads]);

  /**
   * Update valid leads on list id change
   */
  useEffect(() => {
    if (!leadsQueue?.length || !listId) return;

    setLeadsQueue((leadsQueue) => selectorValidLeads(leadsQueue, listId));
  }, [listId]);

  /**
   * Adds a list of leads to the leads queue and filter out invalid.
   *
   * @param {PipelineListContactI[]} _leads - List of leads to be added to the queue.
   */
  const addToLeadsQueue = useCallback(
    (_leads: PipelineListContactI[]) => {
      setLeadsQueue((leads) =>
        selectorValidLeads([...leads, ..._leads], listId)
      );
    },
    [listId]
  );

  /**
   * NOTE leads extracted from the queue that are
   * still not in dialing list
   * waiting for the first socket messages are in "limbo" state
   */

  /**
   * Checks if there is a free spot available for dialing.
   *
   * @param {PipelineListContactI[]} activeDilaingLeads - List of leads that are currently being dialed.
   * @returns {boolean} - Returns true if there is a free spot for dialing, false otherwise.
   */
  const isFreeSpotForDialing = useCallback(
    (paramLeadsDialing?: PipelineListContactI[]) => {
      const leads = paramLeadsDialing || leadsDialing;

      return leads?.length < maxLeadsDialing;
    },
    [leadsDialing]
  );

  /**
   * Checks if there is a free spot in dialing list
   *
   * Fills remaining free spots in leadsDialing list with leads from leadsQueue
   *
   * Note to make it work inside setInterval it is required to async get leadsQueue & leadsDialing
   *
   * @param {PipelineListContactI[]} leadsQueue
   * @param {PipelineListContactI[]} leadsDialing
   * @param onSuccess - callback to facilitate action after leads have being added to the leadsDialing
   */
  const moveToDialingList = async (
    onSuccess?: (leads: PipelineListContactI[]) => void
  ) => {
    const leadsQueue = await asyncGet(setLeadsQueue);
    const leadsDialing = await asyncGet(setLeadsDialing);
    const maxLeadsDialing = await asyncGet(setMaxLeadsDialing);

    const freeSpotsCount = maxLeadsDialing - leadsDialing.length || 0;

    const leads = take(leadsQueue, freeSpotsCount)
      .map((l) => ({
        ...l,
        status: DIALER_LIST_DAILING_STATUS.DIALING,
      }))
      .filter((l) => !!l?.list_membership_id);

    setLeadsDialing((leadsDialing) => [...leadsDialing, ...leads]);

    setLeadsQueue((leadsQueue) =>
      leadsQueue.filter(
        (l) =>
          !leads.map((l) => l.list_membership_id).includes(l.list_membership_id)
      )
    );

    onSuccess?.(leads);
  };

  /**
   * Handles incoming messages from Twilio and updates the call status.
   *
   * This function is used in the Twilio provider's incoming message event handler.
   * When a call is accepted by the browser from Twilio, this function is called to retrieve the membership ID.
   * Upon acquiring the membership ID, the calls object is updated to trigger moving leads from dialing to processed.
   *
   * The update is performed only if the lead exists and there are no other connected leads.
   *
   * @param membershipId
   * @returns
   */
  const assignConnectedByListMemberMessageUpdate = async (
    statusUpdateMessage: ListMemberUpdateMessageI
  ) => {
    const membershipId = statusUpdateMessage?.membership_id;

    if (!membershipId) return;

    const leadsMap = await asyncGet(setLeadsMap);
    const callsMap = await asyncGet(setCalls);

    const calls = Array.from(callsMap, ([, call]) => call);
    const lead = leadsMap.get(membershipId);

    if (
      !lead ||
      calls.some((call) => call.status === DIALER_LIST_DAILING_STATUS.CONNECTED)
    )
      return;

    setCalls((prevCallsMap) => {
      const callsMap = new Map(prevCallsMap);
      callsMap.set(membershipId, {
        membership_id: membershipId,
        status: DIALER_LIST_DAILING_STATUS.CONNECTED,
      });
      return callsMap;
    });
  };

  /**
   * Main Source of Truth
   * Process calls map object to set accurate leads state
   */
  useEffect(() => {
    const lastUpdatedCalls = Array.from(calls, ([, data]) => data);

    /**
     * Update Dialing Leads
     */
    setLeadsDialing(
      lastUpdatedCalls
        .filter((call) => call.status === DIALER_LIST_DAILING_STATUS.DIALING)
        .map((call) =>
          assignLeadStatus(
            leadsMap.get(call.membership_id as string) as PipelineListContactI,
            DIALER_LIST_DAILING_STATUS.DIALING
          )
        )
    );

    /**
     * Update Processed Leads
     */
    setLeadsProcessed(
      lastUpdatedCalls
        .filter((call) => call.status !== DIALER_LIST_DAILING_STATUS.DIALING)
        .map((call) =>
          assignLeadStatus(
            leadsMap.get(call.membership_id as string) as PipelineListContactI,
            call.status
          )
        )
    );

    /**
     * Update connected Lead
     *
     * Update connected lead ONLY
     *  - If no new connected lead is found
     *  - If current is undefined
     *  - If dialer widget is closed
     *
     * NOTE
     * Connected Lead set to undefinded when widget is closed
     */

    setConnectedLead((lead) => {
      const newConnected = lastUpdatedCalls.find(
        (call) => call.status === DIALER_LIST_DAILING_STATUS.CONNECTED
      );

      /**
       * If Widget is open we want to preserve connected lead
       */
      if (isDialerWidgetOpen()) {
        console.log(
          `DEBUGGER setConnectedLead widget is open preserving the lead`,
          lead
        );
        return lead;
      }

      /**
       * If no new connected lead if found
       */
      if (!newConnected) return undefined;

      /**
       * Set new connected lead only
       * If current is undefined
       * If dialer widget is closed
       */
      return !lead && !isDialerWidgetOpen()
        ? assignLeadStatus(
            leadsMap.get(
              newConnected.membership_id as string
            ) as PipelineListContactI,
            DIALER_LIST_DAILING_STATUS.CONNECTED
          )
        : lead;
    });
  }, [calls]);

  /**
   * Effect hook to filter out leads that have already been processed.
   *
   * @param {Array} leadsDialing - List of leads that are currently being dialed.
   * @param {Array} leadsProcessed - List of leads that have already been processed.
   *
   * This effect runs whenever `leadsDialing` or `leadsProcessed` changes.
   * It checks each lead in `leadsDialing` against `leadsProcessed`.
   * If a lead in `leadsDialing` is found in `leadsProcessed`, it is removed from `leadsDialing`.
   */
  useEffect(() => {
    if (leadsDialing?.length) {
      leadsDialing.forEach((leadDialing) => {
        if (
          leadsProcessed.some(
            (leadProcessed) =>
              leadProcessed.list_membership_id ===
              leadDialing.list_membership_id
          )
        ) {
          setLeadsDialing((leadsDialing) =>
            leadsDialing.filter(
              (lead) =>
                lead.list_membership_id !== leadDialing.list_membership_id
            )
          );
        }
      });
    }
  }, [leadsDialing, leadsProcessed]);

  /**
   * Updates the call metadata map with the current date and time for the specified membership ID.
   *
   * Using asynchronous "get" as this function is used in the setInterval
   *
   * @param {string} membershipId - The ID of the membership for which the call date is being saved.
   */
  const saveCallDate = async (membershipId: string) => {
    setCallsMetadataMap((callsMetadataMap) => {
      const cMetadataMap = new Map(callsMetadataMap);
      const meta = cMetadataMap.get(membershipId) || {};

      cMetadataMap.set(membershipId, {
        ...meta,
        startDialingAt: DayJs().toISOString(),
      });

      return cMetadataMap;
    });
  };

  const httpGetPipelineListContact = async (lead: PipelineListContactI) => {
    const API = glencocoClientAPI();

    const ResponseLeadCallStatus = await API.getListMember(
      lead?.list_id as string,
      lead?.list_membership_id
    ).catch((e) => e);

    if (ResponseLeadCallStatus.status !== 200) return undefined;

    return (ResponseLeadCallStatus.data as GetListMemberResponseI)?.contact;
  };

  /**
   *
   * Processes timed-out calls by checking the call metadata for each dialing lead and updating their status if they have timed out.
   *
   * This function retrieves the current calls metadata map and the list of leads dialing asynchronously. It then iterates through each lead,
   * checks if the lead's call has timed out based on the `startDialingAt` property and the defined timeout duration.
   * If a call has timed out, the lead is removed from the dialing list, and the call's status is updated to "TIMEOUT".
   *
   * @async
   * @returns {Promise<void>}
   */
  const processTimedoutCalls = async () => {
    const callsMetadataMap = await asyncGet(setCallsMetadataMap);
    const leadsDialing = await asyncGet(setLeadsDialing);

    let leadsTimedout = leadsDialing.filter((lead) => {
      let isTimedout = false;

      const startDialingAt = callsMetadataMap.get(
        lead.list_membership_id
      )?.startDialingAt;

      if (startDialingAt) {
        isTimedout =
          DayJs(DayJs()).diff(startDialingAt, "second") >
          SOFT_CALL_TIMEOUT_SECONDS;

        return isTimedout;
      }
    });

    if (!leadsTimedout?.length) return;

    /**
     * Re-validation of leads that exceed timeout
     * If some of them are still dialing ->
     * leave them until hard timeout reached,
     * otherwise move to the status that BE returned
     * or if lead is undefined set is as timedout
     *
     */

    /**
     * Get latest leads data
     * https://caolan.github.io/async/v3/docs.html#map
     */
    const freshLeadsTimedout = await async.map<
      PipelineListContactI,
      PipelineListContactI | undefined,
      Error
    >(leadsTimedout, async (lead: PipelineListContactI, callback) => {
      const updatedLead = await httpGetPipelineListContact(lead);

      callback(undefined, updatedLead);
    });

    /**
     * Filter actively dialing lists that do not exceed hard timeout
     * and update statuses according to recently updated leads
     *
     * Takes into account that socket message got lost
     * and lead remains in the dialing state while it
     * was actually updated
     */
    leadsTimedout = leadsTimedout
      .filter((lead, i) => {
        const startDialingAt = callsMetadataMap.get(
          lead.list_membership_id
        )?.startDialingAt;

        return (
          freshLeadsTimedout[i]?.status !==
            DIALER_LIST_DAILING_STATUS.CONNECTED &&
          DayJs(DayJs()).diff(startDialingAt, "second") >
            HARD_CALL_TIMEOUT_SECONDS
        );
      })
      .map(
        (lead) =>
          freshLeadsTimedout.find(
            (l) => l?.list_membership_id === lead.list_membership_id
          ) || lead
      );

    if (!leadsTimedout?.length) return;

    const ddMessage = `${ERROR_CATEGORIES.DIALER_LIST} Leads timed out`;
    dd.error(ddMessage, {
      data: { freshLeadsTimedout, leadsTimedout, leadsDialing },
    });
    datadogRum.addError(ddMessage, {
      data: { freshLeadsTimedout, leadsTimedout, leadsDialing },
    });

    const leadsTimedoutIds = leadsTimedout.map((l) => l.list_membership_id);

    setLeadsDialing((leads) =>
      leads.filter((l) => !leadsTimedoutIds?.includes(l.list_membership_id))
    );
    setCalls((calls) => {
      const callsMap = new Map(calls);

      leadsTimedout.forEach((lead) => {
        callsMap.set(lead.list_membership_id, {
          membership_id: lead.list_membership_id,
          status:
            lead?.status === DIALER_LIST_DAILING_STATUS.DIALING
              ? DIALER_LIST_DAILING_STATUS.TIMEOUT
              : (lead?.status as ValueOf<typeof DIALER_LIST_DAILING_STATUS>),
        });
      });

      return callsMap;
    });
  };

  const updateAccountDetailsByMembershipId = (
    membership_id: string,
    accountDetailsData: GetAccountDetailsDataI
  ) => {
    setAccountDetailsMap((accountDetailsMap) => {
      const newAccountDetailsMap = new Map(accountDetailsMap);
      newAccountDetailsMap.set(membership_id, accountDetailsData);

      return newAccountDetailsMap;
    });
  };

  /**
   * Retrieves and updates account details.
   *
   * This effect checks if account details for a lead in the dialing list have been
   * requested already. If not, it fetches the account details and updates the `accountDetailsMap`
   * with the information, which is passed to the MAXED DIALER widget once the lead is connected.
   */
  useEffect(() => {
    leadsDialing.forEach((lead) => {
      /**
       * If already requested -> don't do second time
       */
      if (accountDetailsMap.get(lead.list_membership_id)) return;

      /**
       * Mark account details as requested by setting an empty object.
       */

      updateAccountDetailsByMembershipId(lead.list_membership_id, {});

      /**
       * Now requesting the data and updating account details
       */
      (async () => {
        const accountDetails = await getAccountDetailsData(
          lead.campaign_id,
          lead.account_id
        );

        updateAccountDetailsByMembershipId(
          lead.list_membership_id,
          accountDetails
        );
      })();
    });
  }, [leadsDialing]);

  /**
   * Clears all the leads states.
   *
   * This function resets the leads queue, leads dialing, and leads processed
   * states to empty arrays. It is used to clear all the leads-related data.
   */
  const clear = () => {
    setLeadsQueue([]);
    setLeadsDialing([]);
    setLeadsProcessed([]);
    setIsQueuePaused(false);
    setConnectedLead(undefined);
    setCalls(new Map());
    setLeadsMap(new Map());
    setCallsMetadataMap(new Map());
    setAccountDetailsMap(new Map());
  };

  return {
    isListenWS,
    setIsListenWS,

    leadsMap,
    leadsQueue,
    leadsDialing,
    leadsProcessed,

    maxLeadsDialing,

    calls,
    callsMetadataMap,
    accountDetailsMap,
    connectedLead,
    isQueuePaused,

    setLeadsMap,
    setAccountDetailsMap,
    setLeadsQueue,
    setLeadsDialing,
    setLeadsProcessed,
    setCalls,
    setConnectedLead,
    setMaxLeadsDialing,
    setIsQueuePaused,
    pauseQueue,
    resumeQueue,

    clear,

    addToLeadsQueue,
    moveToDialingList,
    isFreeSpotForDialing,
    saveCallDate,
    processTimedoutCalls,
    assignConnectedByListMemberMessageUpdate,
  };
};
