import { FC } from "react";

import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { LabelValue } from "@/modules/pipeline/account-details/sidebar/components/label-value";
import { AccountDetailsV2SidebarSection } from "@/modules/pipeline/account-details/sidebar/sections/section";
import { AccountDispositionStatusPill } from "@/modules/pipeline/account-details/sidebar/sections/status-section/status-pill";
import { AccountStatusDetails } from "@/modules/pipeline/account-details/sidebar/sections/status-section/status-details";
import { StatusSectionActions } from "@/modules/pipeline/account-details/sidebar/sections/status-section/actions";

interface StatusSectionPropsI {
  className?: string;
}

export const StatusSection: FC<StatusSectionPropsI> = () => {
  const { accountDetails, campaign, account } = useAccountDetailsContext();
  const accountStatus = accountDetails?.data?.account_status;

  return (
    <AccountDetailsV2SidebarSection
      title="Status"
      actionsSection={<StatusSectionActions accountStatus={accountStatus} />}
    >
      <div className="mt-5 flex flex-col gap-5">
        {accountStatus && (
          <LabelValue
            label="Status"
            value={
              <AccountDispositionStatusPill accountStatus={accountStatus} />
            }
          />
        )}

        {accountStatus && (
          <AccountStatusDetails
            accountStatus={accountStatus}
            campaign={campaign}
            account={account}
          />
        )}
      </div>
    </AccountDetailsV2SidebarSection>
  );
};
