import { Container } from "@/components/base/container";
import { clsxMerge } from "shared/lib/helpers";
import { useGlobalContext } from "@/hooks/use-global-context";
import { V3PageHeaderComponents } from "./components";

export const PrimaryNavigationV3PageHeader = ({
  className,
  title,
  isCurrentTimeVisible = false,
}: {
  className?: string;
  title: string;
  isCurrentTimeVisible?: boolean;
}) => {
  const globalContext = useGlobalContext();
  const { glencocoUser: user } = globalContext;

  return (
    // not sure why "z-10" was used here so just commenting it out for now incase we run into issues later
    <Container
      className={clsxMerge("relative lg:w-full", /*"z-10",*/ className)}
    >
      <div className="navbar flex min-h-[45px] justify-between px-0 pb-5 pt-7">
        <div className="flex w-full items-center">
          <V3PageHeaderComponents.Title user={user} title={title} />

          <V3PageHeaderComponents.LiveTime
            title={title}
            isVisible={isCurrentTimeVisible}
          />
        </div>
      </div>
    </Container>
  );
};
