import { useEffect, useState } from "react";
import { InfiniteScrollWithObserver } from "shared/ui";
import { Notification } from "../notification";
import { NotificationI } from "@/interfaces/notification";
import { clsxMerge } from "shared/lib/helpers";

export const NotificationsInifniteScroll = ({
  notifications,
  loadMore,
  isReachedEnd,
  isLoading,
}: {
  notifications?: Array<NotificationI>;
  loadMore?: () => void;
  isReachedEnd?: boolean;
  isLoading?: boolean;
}) => {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (notifications && notifications?.length > 0 && !isInitialized) {
      setIsInitialized(true);
    }
  }, [notifications?.length, isInitialized]);

  return (
    <InfiniteScrollWithObserver
      isLoading={isLoading as boolean}
      isMore={!isReachedEnd}
      onMore={loadMore}
      isDefaultMessageHidden={true}
    >
      {notifications?.map((notification, i) => (
        <Notification
          key={i}
          {...notification}
          className={clsxMerge({
            "border-b border-b-black/20": i < notifications.length - 1,
          })}
        />
      ))}
    </InfiniteScrollWithObserver>
  );
};
