import { AxiosInstance } from "axios";

import { handlerMetaInterceptor } from "@/api/interceptors/interceptor-request/handler-meta";
import { handlerAuthenticationInterceptor } from "@/api/interceptors/interceptor-request/handler-authentication";

export const interceptorRequest = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    handlerAuthenticationInterceptor,
    handlerMetaInterceptor
  );
};
