import clsx from "clsx";
import { ChangeEvent, FC } from "react";
import { twMerge } from "tailwind-merge";
import { ChevronDownIcon } from "../../icons";
import { clsxMerge } from "../../../lib/helpers";

const VARIATIONS = { DEFAULT: "default", SMALL: "small" };

interface SelectFieldI {
  selectedValue?: string;
  options?: string[];
  label?: string;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  className?: string;
  selectClassName?: string;
  variation?: "default" | "small";
  theme?: "dark" | "light";
}

/* TODO finish theming for small variation */
const SelectField: FC<SelectFieldI> = ({
  selectedValue,
  options,
  label,
  onChange,
  className,
  selectClassName,
  variation,
  theme,
}) => {
  const isDarkTheme = !theme || theme === "dark";
  const isLightTheme = !theme || theme === "light";

  return (
    <>
      {!!(!variation || variation === VARIATIONS.DEFAULT) && (
        <div className={twMerge(clsx("relative", className))}>
          {!!label && (
            <div className="mb-2">
              <label
                className={clsxMerge("ae-typography-detail1", {
                  "text-black/60": isDarkTheme,
                  "text-white": isLightTheme,
                })}
              >
                {label}
              </label>
            </div>
          )}

          <div className="relative">
            <select
              className={clsxMerge(
                "select ae-typography-body1",
                "w-full max-w-xs border-0 bg-none",
                {
                  "bg-black/5 text-black/60": isDarkTheme,
                  "bg-white text-black/60": isLightTheme,
                },
                selectClassName
              )}
              value={selectedValue}
              onChange={onChange}
            >
              {options &&
                options.map((opt, i) => <option key={i}>{opt}</option>)}
            </select>

            <ChevronDownIcon
              className={clsxMerge(
                "pointer-events-none absolute right-4 top-5 h-2 text-black/40"
              )}
            />
          </div>
        </div>
      )}

      {variation === VARIATIONS.SMALL && (
        <div className={twMerge(clsx("relative", className))}>
          <select
            className="select select-sm ae-typography-body2 w-full max-w-xs border-0 bg-white/60 bg-none text-black/60"
            value={selectedValue}
            onChange={onChange}
          >
            {options && options.map((opt, i) => <option key={i}>{opt}</option>)}
          </select>

          <ChevronDownIcon className="pointer-events-none absolute right-4 top-3 h-2 text-black/40" />
        </div>
      )}
    </>
  );
};

export default SelectField;
