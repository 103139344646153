import { LeadCardAvatar } from "./avatar";
import { LeadInfo } from "./lead-info";
import { clsxMerge } from "shared/lib/helpers";

export const LeadCard = () => {
  return (
    <div className="flex h-full items-center justify-start">
      <LeadCardAvatar
        className={clsxMerge("relative justify-start")}
        avatarClassName="relative w-[50px]"
        ripplesClassName="z-[10] relative top-[25px] scale-75"
      />

      <LeadInfo />
    </div>
  );
};
