import { clsxMerge } from "shared/lib/helpers";

import { NotificationsPanel } from "shared/ui/notifications/panel";

import { NotificationsInifniteScroll } from "./components/notifications-infinite-scroll";
import { LoadingSkeleton } from "./components/loading-skeleton";
import {
  LocalNotificationsProvider,
  useLocalNotificationsContext,
} from "./context";
import { useGlobalNotificationsContext } from "@/context/notifications-context";
import { glencocoClientAPI } from "@/api/glencoco";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { NOTIFICATIONS_TABS } from "shared/ui/notifications/panel/tabs";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";

const NotificationsModuleContent = ({ className }: { className?: string }) => {
  const {
    notifications,
    unreadNotifications,
    isReachedEnd,
    isLoading,
    update,
    loadMore,
    setNotifications,
  } = useLocalNotificationsContext();

  const { notificationCounters, reload: reloadNotificationsCounter } =
    useGlobalNotificationsContext();

  const [tab, setTab] = useState<
    ValueOfObjectFields<typeof NOTIFICATIONS_TABS>
  >(NOTIFICATIONS_TABS.ALL);

  useEffect(() => {
    if (notificationCounters?.unread && !isLoading) {
      console.log("Requested the latest");
      update?.();
    }
  }, [notificationCounters]);

  const handleOnMarkReadAll = async () => {
    const API = glencocoClientAPI();

    const MarkReadResponse = await API.markReadAllNotifications().catch(
      (e) => e
    );

    if (MarkReadResponse.status !== 200) {
      toast.error("Failed to mark as read all notifications.");
      return;
    }

    setNotifications?.((notifications) =>
      notifications.map((n) => ({ ...n, is_seen: true }))
    );

    reloadNotificationsCounter?.();
  };

  const handleOnTabChange = (tabId?: string) => {
    setTab(tabId as ValueOfObjectFields<typeof NOTIFICATIONS_TABS>);
  };

  return (
    <NotificationsPanel
      className={clsxMerge(className)}
      unreadCounter={notificationCounters?.unread}
      onMarkReadAll={handleOnMarkReadAll}
      onTabChange={handleOnTabChange}
      isEmpty={
        !isLoading &&
        ((NOTIFICATIONS_TABS.ALL === tab && !notifications?.length) ||
          (NOTIFICATIONS_TABS.UNREAD === tab && !unreadNotifications?.length))
      }
    >
      {NOTIFICATIONS_TABS.ALL === tab && !!notifications?.length && (
        <NotificationsInifniteScroll
          notifications={notifications}
          isReachedEnd={isReachedEnd}
          loadMore={loadMore}
        />
      )}

      {NOTIFICATIONS_TABS.UNREAD === tab && !!unreadNotifications?.length && (
        <NotificationsInifniteScroll
          notifications={unreadNotifications}
          isReachedEnd={isReachedEnd}
          loadMore={loadMore}
        />
      )}

      {isLoading && <LoadingSkeleton />}
    </NotificationsPanel>
  );
};

/**
 * NOTE
 * On notification action click before action and after actions happened
 * is controlled in BaseNotification component
 */
export const NotificationsModule = ({
  className,
  onClose,
}: {
  className?: string;
  onClose?: () => void;
}) => (
  <LocalNotificationsProvider onClosePanel={onClose}>
    <NotificationsModuleContent className={className} />
  </LocalNotificationsProvider>
);
