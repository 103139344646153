import { useMutation, useQueryClient } from "@tanstack/react-query";

import { AccountDispositionNoteI } from "@/modules/pipeline/account-details/interfaces";
import {
  DisqualifyAccountOrContactRequestParamsI,
  SendAccountContactEmailRequestParamsI,
} from "@/api/routes/account/interfaces";
import { useApiClient } from "@/context/api-client";
import { getAccountDetailsQueryKey } from "@/api/routes/account/queries";
import { ProspectDispositionTypeI } from "shared/lib/interfaces/dispositions";

interface UseAddAccountDispositionNoteParamsI extends AccountDispositionNoteI {
  campaignId: string;
  accountId: string;
}

export const useAddAccountDispositionNote = () => {
  const api = useApiClient();

  return useMutation({
    mutationFn: ({
      accountId,
      campaignId,
      notes,
      noteType,
      accountContactId,
    }: UseAddAccountDispositionNoteParamsI) =>
      api.sendAccountDispositionNote(campaignId, accountId, {
        notes,
        accountContactId:
          accountContactId === "account" ? null : accountContactId,
        noteType: noteType,
      }),
  });
};

interface UseDisqualifyAccountOrContactParamsI
  extends DisqualifyAccountOrContactRequestParamsI {
  campaignId: string;
  accountId: string;
}

export const useDisqualifyAccountOrContact = () => {
  const api = useApiClient();

  return useMutation({
    mutationFn: ({
      campaignId,
      accountId,
      ...params
    }: UseDisqualifyAccountOrContactParamsI) =>
      api.disqualifyAccountOrContact(campaignId, accountId, params),
  });
};

interface UseRequestSendEmailParamsI {
  campaignId: string;
  contactId: string;
  request: SendAccountContactEmailRequestParamsI;
}

export const useAccountSendEmail = () => {
  const api = useApiClient();

  return useMutation({
    mutationFn: ({
      campaignId,
      contactId,
      request,
    }: UseRequestSendEmailParamsI) =>
      api.sendAccountEmail(campaignId, contactId, request),
  });
};

interface UseEditNextTouchTimeParamsI {
  campaignId: string;
  accountId: string;
  nextTouchTime: string;
}

export const useEditNextTouchTime = () => {
  const api = useApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      campaignId,
      accountId,
      nextTouchTime,
    }: UseEditNextTouchTimeParamsI) =>
      api.editNextTouchTime(campaignId, accountId, nextTouchTime),
    onSuccess: (_, { campaignId, accountId }) =>
      queryClient.invalidateQueries({
        queryKey: getAccountDetailsQueryKey(campaignId, accountId),
      }),
  });
};

interface UseSetContactAsPrimaryParamsI {
  campaignId: string;
  accountId: string;
  contactId: string;
  isPrimary: boolean;
}

export const useSetUnsetContactAsPrimary = () => {
  const api = useApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      campaignId,
      accountId,
      contactId,
      isPrimary,
    }: UseSetContactAsPrimaryParamsI) =>
      api.setUnsetContactAsPrimary(campaignId, accountId, contactId, isPrimary),
    onSuccess: (_, { campaignId, accountId }) =>
      queryClient.invalidateQueries({
        queryKey: getAccountDetailsQueryKey(campaignId, accountId),
      }),
  });
};

interface UseSetContactTypeParamsI {
  campaignId: string;
  accountId: string;
  contactId: string;
  contactType: ProspectDispositionTypeI;
}

export const useSetContactType = () => {
  const api = useApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      campaignId,
      accountId,
      contactId,
      contactType,
    }: UseSetContactTypeParamsI) =>
      api.setContactType(campaignId, accountId, contactId, contactType),
    onSuccess: (_, { campaignId, accountId }) =>
      queryClient.invalidateQueries({
        queryKey: getAccountDetailsQueryKey(campaignId, accountId),
      }),
  });
};

interface UseRemoveAccountFromListParamsI {
  campaignId: string;
  accountId: string;
  listId: string;
}

export const useRemoveListFromAccount = () => {
  const api = useApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      campaignId,
      accountId,
      listId,
    }: UseRemoveAccountFromListParamsI) =>
      api.removeListFromAccount(campaignId, accountId, listId),
    onSuccess: (_, { campaignId, accountId }) =>
      queryClient.invalidateQueries({
        queryKey: getAccountDetailsQueryKey(campaignId, accountId),
      }),
  });
};

interface UseCancelMeetingParamsI {
  campaignId: string;
  accountId: string;
}

export const useCancelMeeting = () => {
  const api = useApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ campaignId, accountId }: UseCancelMeetingParamsI) =>
      api.cancelMeeting(campaignId, accountId),
    onSuccess: (_, { campaignId, accountId }) =>
      queryClient.invalidateQueries({
        queryKey: getAccountDetailsQueryKey(campaignId, accountId),
      }),
  });
};
