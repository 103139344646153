import { FC, Fragment, useCallback, useMemo } from "react";
import dayjs from "dayjs";
import _groupBy from "lodash/groupBy";

import { ACCOUNT_HISTORY_CARDS_MAP } from "shared/ui/activity-log/constants/history";
import { AccountHistoryItemI } from "@/interfaces/accounts";
import AccountHistoryEmptyState from "./empty-state";
import { useLazyModal } from "shared/lib/hooks";
import {
  ACCOUNT_REPLY_EMAIL_MODAL_ID,
  AccountReplyEmailModal,
} from "@/components/modals/account-reply-email-modal";
import { AccountActivityI } from "shared/lib/interfaces/account";
import { ACCOUNT_ACTIVITY_TYPES } from "shared/lib/constants/account";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";

interface HistoryListPropsI {
  isLoading?: boolean;
  historyData?: AccountHistoryItemI[];
}

export const HistoryList: FC<HistoryListPropsI> = ({
  isLoading = false,
  historyData,
}) => {
  const {
    accountHistoryData: { reloadData: reloadAccountHistory },
  } = useAccountDetailsContext();

  const {
    isOpen: isReplyEmailModalOpen,
    onOpen: onReplyEmailModalOpen,
    onClose: onReplyEmailModalClose,
  } = useLazyModal(ACCOUNT_REPLY_EMAIL_MODAL_ID);

  const groupedHistoryData = useMemo(
    () =>
      _groupBy(historyData || [], ({ created_at }) =>
        dayjs(created_at).format("ll")
      ),
    [historyData]
  );

  const actionClickMap: Record<string, () => void> = useMemo(
    () => ({ [ACCOUNT_ACTIVITY_TYPES.EMAIL_RECEIVED]: onReplyEmailModalOpen }),
    [onReplyEmailModalOpen]
  );

  const getActionClick = useCallback(
    (activityType: AccountActivityI) => {
      if (activityType in actionClickMap) {
        return actionClickMap[activityType];
      } else {
        return undefined;
      }
    },
    [actionClickMap]
  );

  if (isLoading && historyData?.length === 0) {
    return null;
  }

  return historyData && historyData.length > 0 ? (
    <div className="flex max-w-[1100px] flex-col gap-7">
      {Object.entries(groupedHistoryData).map(
        ([dayOfActivities, activitiesOfTheDay], idx) => (
          <div key={idx} className="flex flex-col gap-5">
            <div className="relative flex h-8 w-full items-center justify-center">
              <div className="h-0.5 w-full bg-[#D9D9D9]" />
              <div className="brand-typography-h3 absolute left-1/2 w-[128px] -translate-x-1/2 rounded-full bg-[#555] text-center leading-8 text-white">
                {dayOfActivities}
              </div>
            </div>

            <div className="flex flex-col gap-9 @container">
              {activitiesOfTheDay.map((activity, idx) => {
                const ActivityCard =
                  ACCOUNT_HISTORY_CARDS_MAP[activity.activity_type];

                if (!ActivityCard) {
                  return null;
                }

                return (
                  <Fragment key={idx}>
                    <ActivityCard
                      activity={activity}
                      onActionClick={getActionClick(activity?.activity_type)}
                    />

                    {isReplyEmailModalOpen && activity?.email_metadata?.id && (
                      <AccountReplyEmailModal
                        emailId={activity?.email_metadata?.id}
                        contact={activity.contact}
                        onSuccess={() => {
                          onReplyEmailModalClose();
                          reloadAccountHistory();
                        }}
                        onClose={onReplyEmailModalClose}
                      />
                    )}
                  </Fragment>
                );
              })}
            </div>
          </div>
        )
      )}
    </div>
  ) : (
    <AccountHistoryEmptyState />
  );
};
