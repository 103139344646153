import _mapValues from "lodash/mapValues";

import {
  ACCOUNT_DISPOSITION_STATUS_LABELS,
  ACCOUNT_DISPOSITION_STATUSES,
} from "shared/lib/constants/account";
import { MEETING_OUTCOMES, MEETING_OUTCOMES_MAP } from "@/constants/accounts";
import {
  AccountDispositionStatusI,
  MeetingOutcomeI,
} from "shared/lib/interfaces/account";

const DARK_STATUS_STYLE = "bg-black";

export const ACCOUNT_DISPOSITION_STATUS_PILL_PARAMS = _mapValues(
  {
    [ACCOUNT_DISPOSITION_STATUSES.COLD_LEAD]: "bg-[#67A9F8]",
    [ACCOUNT_DISPOSITION_STATUSES.NURTURE]: "bg-[#F8A467]",
    [ACCOUNT_DISPOSITION_STATUSES.MEETING_SCHEDULED]: "bg-[#F86767]",
    [ACCOUNT_DISPOSITION_STATUSES.FEEDBACK_RECEIVED]: DARK_STATUS_STYLE,
    [ACCOUNT_DISPOSITION_STATUSES.DISQUALIFIED]: DARK_STATUS_STYLE,
  },
  (style, key) => ({
    style,
    label: ACCOUNT_DISPOSITION_STATUS_LABELS[key as AccountDispositionStatusI],
  })
);

export const ACCOUNT_DISPOSITION_SUB_STATUS_PILL_PARAMS = _mapValues(
  {
    [MEETING_OUTCOMES.NO_SHOW]: DARK_STATUS_STYLE,
    [MEETING_OUTCOMES.PENDING]: "bg-[#F86767]",
    [MEETING_OUTCOMES.RESCHEDULED]: "bg-[#F86767]",
    [MEETING_OUTCOMES.CANCELED]: DARK_STATUS_STYLE,
    [MEETING_OUTCOMES.UNQUALIFIED]: DARK_STATUS_STYLE,
    [MEETING_OUTCOMES.QUALIFIED]: "bg-[#83D565]",
  },
  (style, key) => ({
    style,
    label: MEETING_OUTCOMES_MAP[key as MeetingOutcomeI],
  })
);
