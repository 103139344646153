import { AxiosError } from "axios";
import { AxiosInstanceInterceptorRequestExtensionI } from "@/api/interceptors/interface";
import { dd } from "@/helpers/datadog";

import { GLOBALLY_HANDLED_ERRORS } from "@/constants/errors";
import { handleGlobalErrorMessage } from "@/helpers/error";

import { redirectOnAxiosErrorInterceptor } from "lib/auth/redirects-provider";

const ENDPOINT_GROUPS_TO_THROW_AN_ERROR = ["v1/list", "v2/campaign"];

const checkIfShouldThrowExceptionForReactQuery = (url?: string) => {
  if (!url) {
    return false;
  }

  const conditions = [
    ENDPOINT_GROUPS_TO_THROW_AN_ERROR.some((group) => url.includes(group)),
    ["/set_contact_type", "/set_primary", "/edit_next_follow_up_time"].some(
      (endpointEnd) => url.endsWith(endpointEnd)
    ),
  ];

  return conditions.some(Boolean);
};

const isLocal = process.env.NEXT_PUBLIC_ENV === "local";

export const handleOnErrorInterceptor = (
  e: AxiosError<any> & {
    config?: AxiosInstanceInterceptorRequestExtensionI;
  }
) => {
  const url = `${e?.config?.baseURL}${e?.config?.url}`;
  const errorCode = e?.response?.data?.error_code as number;
  const errorContext = {
    data: {
      errorCode,
      error: e,
      pageUrl: window?.location.href,
    },
  };

  if (
    errorCode &&
    Object.keys(GLOBALLY_HANDLED_ERRORS).includes(errorCode.toString())
  ) {
    handleGlobalErrorMessage(errorCode, "Failed to load data.");
  }

  redirectOnAxiosErrorInterceptor(e);

  const startedAt = e?.config?.meta?.requestStartedAt;
  if (!startedAt) console.log(`Execution time undefined`);

  const executionTime =
    new Date().getTime() - (e?.config?.meta?.requestStartedAt || 0);

  const isNetworkError = !!e?.isAxiosError && !e?.response;
  const isTimedout =
    e?.code === "ECONNABORTED" && e?.message?.includes("timeout");

  if (isNetworkError) {
    dd.error(`NETWORK ERROR - ${url}`, errorContext);
  }

  if (!isNetworkError && isTimedout) {
    dd.error(`REQUEST TIMEDOUT $${executionTime}ms - ${url}`, errorContext);
  }

  //github.com/axios/axios/issues/383#issuecomment-234079506

  const message = `Endpoint ${e?.response?.status} ${e?.config?.method} ${url} ${executionTime}ms`;

  if (!(isTimedout || isNetworkError)) {
    dd.error(message, errorContext);
  }

  if (isLocal) {
    console.error(message, errorContext);
  }

  /**
   * TODO this is a temporary solution that needs to be changed ASAP.
   * Without throwing the error, the error is not being caught by react-query,
   * leading to tons of potential unhandled behaviors.
   **/
  if (checkIfShouldThrowExceptionForReactQuery(e.config?.url)) {
    throw e;
  }

  return e;
};
