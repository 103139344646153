import { WIDGETS, widgets } from "@/components/shared/widgets";
import { ACCOUNT_DETAILS_TABS } from "@/constants/account-details";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";

import toast from "react-hot-toast";

import {
  GetAccountInfoResponseI,
  GetCampaignResponseI,
  GetContactDetailsResponseI,
  glencocoClientAPI,
} from "@/api/glencoco";
import {
  AccountExecutiveI,
  AccountI,
  AccountUserDispositionI,
  AccountUserListI,
  ContactDetailI,
} from "@/interfaces/accounts";
import { GetAccountDetailsDataI } from "@/interfaces/account-details";

import { handleErrorMessage } from "@/helpers/error";
import { ERROR_CODES } from "@/constants/errors";
import { CampaignI } from "@/interfaces/campaign";

export const openAccountDetails = ({
  campaignId,
  accountId,
  tab,
}: {
  campaignId?: string;
  accountId?: string;
  tab?: ValueOfObjectFields<typeof ACCOUNT_DETAILS_TABS>;
}) => {
  widgets.trigger({
    id: WIDGETS.MAXED_DIALER,
    config: { campaignId, accountId, tab },
  });
};

export const getAccountDetailsData = async (
  campaignId: string,
  accountId: string
): Promise<GetAccountDetailsDataI> => {
  let isNotApprovedForCampaign = false;
  let account: AccountI | undefined = undefined;
  let accountExecutiveData: AccountExecutiveI | undefined = undefined;
  let accountUserDisposition: AccountUserDispositionI | undefined = undefined;
  let accountUserLists: AccountUserListI[] | undefined = undefined;
  let contacts: ContactDetailI[] | undefined = undefined;
  let campaign: CampaignI | undefined;

  const API = glencocoClientAPI();

  /**
   * Should be always fulfilled
   */
  const settledResponses = await Promise.allSettled([
    API.getCampaign(campaignId).catch((e) => e),
    API.getAccountInfo(campaignId, accountId).catch((e) => e),
    API.getContactDetails(campaignId, accountId).catch((e) => e),
  ]);

  /**
   * TODO improve Typescript
   * TODO optimize getting a campaign as of optmizing opening account widget performance
   * we specifically calling for campaign instead of doing react dependant checks
   */
  const [respCampaign, respAccountInfo, respContactDetails] =
    settledResponses?.map((r) => (r as any).value);

  if (respCampaign.status === 200) {
    const data = respCampaign.data as GetCampaignResponseI;
    campaign = data.campaign;
  }

  if (respAccountInfo.status === 200) {
    const data = respAccountInfo.data as GetAccountInfoResponseI;

    account = data.account;
    accountExecutiveData = data.account_executive;
    accountUserDisposition = data.account_user_disposition;
    accountUserLists = data.user_lists;
  } else {
    const errorCode = respAccountInfo?.response?.data?.error_code as number;

    if (errorCode === ERROR_CODES.USER_NOT_APPROVED_FOR_CAMPAIGN) {
      isNotApprovedForCampaign = true;
    } else {
      handleErrorMessage(
        respAccountInfo?.response?.data?.error_code as number,
        "Failed to fetch Account data"
      );
    }
  }

  if (respContactDetails.status === 200) {
    const data = respContactDetails.data as GetContactDetailsResponseI;

    contacts = data?.items || [];
  } else if (!isNotApprovedForCampaign) {
    toast.error("Failed to fetch Contact details");
  }

  return {
    isNotApprovedForCampaign,

    campaign,
    account,
    accountExecutiveData,
    accountUserDisposition,
    accountUserLists,
    contacts,
  };
};
