import { FC, ReactNode } from "react";
import { clsxMerge } from "shared/lib/helpers";

interface AccountDetailsV2SidebarSectionPropsI {
  className?: string;
  title?: string;
  actionsSection?: ReactNode;
  children?: ReactNode;
}

export const ACCOUNT_SIDEBAR_ACTION_BUTTON_CLASSNAME =
  "ae-link brand-typography-body6 flex items-center gap-2";

export const AccountDetailsV2SidebarSection: FC<
  AccountDetailsV2SidebarSectionPropsI
> = ({ className, title, actionsSection, children }) => (
  <section className={clsxMerge("w-full py-6 pl-6 pr-5", className)}>
    <div className="flex items-start justify-between">
      <h3 className="brand-typography-h2 line-clamp-2 text-ellipsis">
        {title}
      </h3>

      {actionsSection}
    </div>

    {children}
  </section>
);
