import { FC } from "react";
import { useCancelMeeting } from "@/api/routes/account/mutations";
import { toast } from "react-hot-toast";
import { ConfirmActionModal } from "shared/ui/modal/confirm-action";

interface AccountCancelMeetingConfirmationModalPropsI {
  campaignId?: string;
  accountId?: string;
}

export const CONFIRM_CANCEL_MEETING_MODAL_ID = "confirm-cancel-meeting-modal";

export const AccountCancelMeetingConfirmationModal: FC<
  AccountCancelMeetingConfirmationModalPropsI
> = ({ campaignId, accountId }) => {
  const { mutateAsync } = useCancelMeeting();

  const handleCancelMeeting = () => {
    if (!campaignId || !accountId) {
      return toast.error("Campaign ID and Account ID are required");
    }

    return mutateAsync(
      { campaignId, accountId },
      {
        onSuccess: () =>
          toast.success("Meeting has been canceled successfully"),
        onError: () =>
          toast.error("Failed to cancel meeting, please contact Glencoco."),
      }
    );
  };

  return (
    <ConfirmActionModal
      id={CONFIRM_CANCEL_MEETING_MODAL_ID}
      title="Cancel meeting"
      description="Are you sure you want to cancel this meeting?"
      onConfirm={handleCancelMeeting}
    />
  );
};
