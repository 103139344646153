import { DIALER_LIST_DAILING_STATUS, DIALER_STATES } from "@/constants/dialer";
import {
  DefaultCallingContextI,
  InCallContextI,
} from "@/interfaces/contexts/calling";
import { Call, Device } from "@twilio/voice-sdk";

import { PhoneEndCallIcon } from "shared/ui/icons";
import { toast } from "react-hot-toast";
import { DIALER_LIVE_TRANSFER_STATUSES } from "@/constants/dialer";

import {
  CallingService,
  StartCallResponseI,
  TwilioAccessTokenResponseI,
  glenSocketClientAPI,
} from "@/api/glen-socket";
import { glencocoClientAPI } from "@/api/glencoco";

import { defaultLeadInfo } from "@/context/dialer-v1/default-context";

import dayjs from "dayjs";
import { FrequentlyChangingCallingContextEventDispatcher } from "@/context/dialer-v1/frequently-changing";
import { handleErrorMessage } from "./error";
import { AxiosResponse } from "axios";
import { DefaultResponseI } from "shared/lib/interfaces/api";
import { dd } from "./datadog";
import { asyncGet, asyncWaitFor } from "./context";
import { DialerGlobalContextI } from "@/context/dialer-global";
import { isDialerWidgetOpen } from "./widgets";
import wait from "wait";
import { ERROR_CATEGORIES } from "@/constants/errors";
import { GetConnectedListMemberResponseI } from "@/api/routes/list";
import { LOG_CATEGORIES } from "@/constants/logs";
import { SessionStorage } from "./session-storage";
import { GLOBAL_DIALER_STATE } from "@/constants/window-globals";
import { DayJs } from "shared/lib/helpers/date";
import { TwilioSetupConfigI } from "@/interfaces/dialer/twilio";
import { TWILIO_TOKEN_ENDPOINT_VERSIONS } from "@/constants/twilio";

const updateConnectedContact = async ({
  context: defaultCallContext,
  inCallContext,
  dialerGlobalContext,
  call,
  isOutboundCall,
}: {
  context: DefaultCallingContextI;
  inCallContext: InCallContextI;
  dialerGlobalContext?: DialerGlobalContextI;
  call: Call;
  isOutboundCall: boolean;
}) => {
  const callId = call.customParameters.get("call_id") as string;
  let campaignId = call.customParameters.get("campaign_id") as string;
  const API = glencocoClientAPI();

  /**
   * NOTE
   *
   * Usually you don't have to wait for campaign id except if it is a global dialer
   * and you get connected before you get campaignId
   *
   * Recent changes
   * CampaignId is not stable indialing list connected lead. As it assingment not blocking
   * incomming message and might be executed here earlier than campaign id update
   */

  if (!campaignId) {
    campaignId = await asyncWaitFor(defaultCallContext.setCampaignID, {
      isTruthful: true,
      timeout: 2000,
    });
  }

  const resp = await API.getCallContext(campaignId as string, callId).catch(
    (e) => e
  );

  if (resp.status !== 200) {
    dd.rum.error(`${ERROR_CATEGORIES.TWILIO} - failed to update call context`, {
      data: {
        response: resp,
        requestParams: { campaignId, callId },
        call,
        callCustomParams: Array.from(
          call?.customParameters,
          ([key, value]) => ({
            key,
            value,
          })
        ),
      },
    });
    return;
  }

  const account = resp?.data?.account;
  const contact = resp?.data?.contact;
  const activity_log = resp?.data?.activity_log;
  const calendly_uri = resp?.data?.calendly_uri;
  const caller_phone = resp?.data?.caller_phone;

  inCallContext.setCallerPhone(caller_phone);
  inCallContext.setAccount(account);
  inCallContext.setContact(contact);
  inCallContext.setActivityLog(
    activity_log?.length ? activity_log?.reverse() : []
  );
  inCallContext.setCalendlyUri(calendly_uri);

  //TODO remove notfications from here completely

  if (isOutboundCall) {
    toast.success(`Calling ${contact?.first_name} ${contact?.last_name}...`);
    return;
  }

  /**
   * In Account Details sidebar we don't want this notification
   */
  const successNotification = `You're connected with ${contact?.first_name} ${contact?.last_name}`;

  if (defaultCallContext) {
    const isEnabled = await asyncGet(dialerGlobalContext?.setIsEnabled);

    if (!isEnabled) toast.success(successNotification);
  } else {
    toast.success(successNotification);
  }
};

const Calling = {
  async setAudioDevices(context: DefaultCallingContextI, device: Device) {
    await wait(500);

    const inputDevices = Array.from(device?.audio?.availableInputDevices ?? []);

    context.setInputDevices(inputDevices);

    if (inputDevices[0]) {
      device?.audio?.setInputDevice(inputDevices[0][0]);
    }

    context.setOutputDevices(
      Array.from(device.audio?.availableOutputDevices ?? [])
    );
  },

  async startCallAndEnqueueCaller(
    context: DefaultCallingContextI
  ): Promise<boolean> {
    if (!context.campaignId) {
      toast.error(`Unknown Campaign`);
      return false;
    }

    await Calling.safeUpdateTwilioAccessToken(context);

    const API = glenSocketClientAPI();
    const startCallResp = await API.startCalls(context.campaignId).catch(
      (e) => {
        return e;
      }
    );

    let success = false;
    if (startCallResp?.status === 200) {
      success = true;
    } else if (startCallResp?.status === 202) {
      toast(`No leads to call right now. Please try again later`);
      success = false;
    } else {
      handleErrorMessage(
        startCallResp?.response?.data?.error_code as number,
        `Failed to start call`
      );

      success = false;
    }

    if (success) {
      context.setCallingState(DIALER_STATES.DIALING);
      // Register device again to receive call
      await Calling.safeUnregisterDevice(context);
      await Calling.safeRegisterDevice(context);
      toast(`Dialing leads...`);
    }

    return success;
  },

  async safeUpdateTwilioAccessToken(
    context: DefaultCallingContextI,
    config?: TwilioSetupConfigI,
    onSuccess?: (accessTokenResponseData: TwilioAccessTokenResponseI) => void
  ) {
    const campaignId = await asyncGet(context.setCampaignID);

    if (!campaignId) {
      toast.error(`Unknown Campaign`);
      return;
    }

    const { setDevice } = context;
    const currentDevice = await asyncGet(setDevice);

    if (!currentDevice) {
      console.error(`Device not found. Can't update Twilio access token.`);
      return;
    }

    await Calling.updateTwilioAccessToken(currentDevice, config, onSuccess);
  },

  async updateTwilioAccessToken(
    device: Device,
    config?: TwilioSetupConfigI,
    onSuccess?: (accessTokenResponseData: TwilioAccessTokenResponseI) => void
  ) {
    const API = glenSocketClientAPI();

    const getTwilioAccessToken =
      config?.tokenEndpointVersion === TWILIO_TOKEN_ENDPOINT_VERSIONS.V3
        ? API.getTwilioAccessTokenV3
        : API.getTwilioAccessToken;

    const accessTokenResponse = await getTwilioAccessToken().catch((e) => {
      dd.error(`Failed to get new Twilio access token`);
      toast.error(
        `Failed to get refresh calling session. Please refresh the page`
      );
      return e;
    });

    if (accessTokenResponse.status === 200) {
      const SS = new SessionStorage();
      device.updateToken(accessTokenResponse.data.token);

      SS.dialerGlobalTwilioTokenUpdatedAt = new Date().toISOString();

      onSuccess?.(accessTokenResponse.data);
    }
  },

  async safeUnregisterDevice(context: DefaultCallingContextI) {
    const { setDevice } = context;
    const currentDevice = await asyncGet(setDevice);
    if (currentDevice) {
      await currentDevice.unregister().catch((e) => {
        if (e.name === "InvalidStateError") {
          console.log(`Twilio device already unregistered`);
        } else {
          throw e;
        }
      });
    }
  },

  async safeRegisterDevice(context: DefaultCallingContextI) {
    const { setDevice } = context;
    const currentDevice = await asyncGet(setDevice);
    if (currentDevice) {
      await currentDevice.register().catch((e) => {
        if (e.name === "InvalidStateError") {
          console.log(`Twilio device already registered`);
        } else {
          throw e;
        }
      });

      await Calling.setAudioDevices(context, currentDevice);
    }
  },

  /**
   * NOTE: transfer current call to another caller
   *       should be available only for specific
   *       campaigns that allow live transfer.
   * @param {Object} props
   * @param props.context - CallingContext
   * @param props.inCallContext - InCallContext
   * @returns {Promise} Promise as the reponse of API call for the live transfer
   */
  async liveTransfer({
    context,
    inCallContext,
  }: {
    context: DefaultCallingContextI;
    inCallContext: InCallContextI;
  }) {
    const { setCall } = context;
    const currentCall = await asyncGet(setCall);

    if (!currentCall) {
      toast.error(`No call to transfer`);
      return;
    }

    const { isCallOnDemand, callOnDemandConfig } = context;
    const callId =
      isCallOnDemand && callOnDemandConfig?.callId
        ? (callOnDemandConfig?.callId as string)
        : (currentCall?.customParameters.get("call_id") as string);

    const API = glenSocketClientAPI();

    if (!callId) {
      toast.error(`Unknown call id`);
      return;
    }

    const LiveTransferResponse = await API.startLiveTransfer(
      context.campaignId as string,
      {
        call_id: callId,
      }
    ).catch((e) => {
      toast.error(`Failed to start transfer`);
      return e;
    });

    if (LiveTransferResponse?.status === 200) {
      toast.success(`Transfer started`);
      inCallContext.setLiveTransferStatus(
        DIALER_LIVE_TRANSFER_STATUSES.LIVE_TRANSFER_COMPLETE
      );
    } else {
      handleErrorMessage(
        LiveTransferResponse?.response?.data?.error_code as number,
        `Failed to start transfer`
      );
    }

    return LiveTransferResponse as AxiosResponse<DefaultResponseI>;
  },

  async startCall({
    context,
    inCallContext,
    contactId,
    itemId,
  }: {
    context: DefaultCallingContextI;
    inCallContext: InCallContextI;
    contactId?: string;
    itemId?: string;
  }) {
    if (!context.campaignId) {
      toast.error(`Unknown Campaign`);
      return false;
    }

    const API = glenSocketClientAPI();

    await Calling.safeUpdateTwilioAccessToken(context);

    const resp = await API.startDirectCall(context.campaignId, {
      contact_id: contactId,
      item_id: itemId,
    }).catch((e) => {
      toast.error(`Failed to start call`);

      if (context.isCallOnDemand) {
        context.setCallingState(DIALER_STATES.PRE);
      }
      Calling.safeUnregisterDevice(context);
      return e;
    });

    // context.setCallingState(DIALER_STATES.DIALING);

    if (resp?.status === 200) {
      const params = {
        // get the phone number to call from the DOM
        Type: "direct-call",
        CallID: (resp?.data as StartCallResponseI)?.call_id,
        To: (resp?.data as StartCallResponseI)?.to_phone_number,
      };

      context.callOnDemandConfig.setCallId(
        (resp?.data as StartCallResponseI)?.call_id
      );

      const { setDevice } = context;
      const device = await asyncGet(setDevice);
      if (device) {
        await Calling.safeUnregisterDevice(context);
        await Calling.safeRegisterDevice(context);

        device.disconnectAll();

        const call = await device.connect({ params });
        Calling.addCallListeners(
          {
            context,
            inCallContext,
          },
          call
        );

        call.customParameters.set(
          "call_id",
          (resp?.data as StartCallResponseI)?.call_id
        );
        await Calling.acceptCall({ context, inCallContext }, call, true);
        context.setCallingState(DIALER_STATES.CALL);
      } else {
        toast.error(`Failed to connect Twilio device`);
        context.setCallingState(DIALER_STATES.PRE);
        Calling.safeUnregisterDevice(context);
      }
    } else {
      handleErrorMessage(
        resp?.response?.data?.error_code as number,
        "Failed to start call"
      );

      if (context.isCallOnDemand) {
        context.setCallingState(DIALER_STATES.PRE);
      }

      Calling.safeUnregisterDevice(context);
    }
  },

  async acceptCall(
    {
      context,
      inCallContext,
      dialerGlobalContext,
    }: {
      context: DefaultCallingContextI;
      inCallContext: InCallContextI;
      dialerGlobalContext?: DialerGlobalContextI;
    },
    call: Call,
    isOutboundCall = false
  ) {
    const { setCall, setMuted } = context;

    /**
     * NOTE
     *
     * SAFEGUARD for direct calls
     *
     * If is a direct call disconnect all other calls
     */
    // const currentDevice = await asyncGet(context.setDevice);
    // if (!isOutboundCall) {
    //   dd.rum.log(`${LOG_CATEGORIES.TWILIO} - isOutboundCall thus disconnect`);
    //   currentDevice && currentDevice.disconnectAll();
    // }

    context.setLeadInfo(defaultLeadInfo);
    context.setLeadQueue([]);

    setCall(call);

    // if (!isOutboundCall) {
    call.accept();

    Calling.dialerGlobalProvider_handleDeviceIncomingMessage_assignConnectedLead(
      { call, dialerGlobalContext }
    );
    Calling.dialerGlobalProvider_handleDeviceIncomingMessage_trackErrors({
      inCallContext,
      dialerGlobalContext,
    });
    // } else {
    //   console.log(`DEBUGGER didn't accept the call`);
    // }

    const isMute = await asyncGet(setMuted);

    setTimeout(() => {
      call?.mute(isMute);
    }, 200);

    if (call.customParameters.has("call_id")) {
      await updateConnectedContact({
        context,
        inCallContext,
        dialerGlobalContext,
        call,
        isOutboundCall,
      });
    } else {
      dd.rum.error(`${ERROR_CATEGORIES.TWILIO} - no call id`, call);
    }
  },

  async callEnded({
    context,
    inCallContext,
  }: {
    context: DefaultCallingContextI;
    inCallContext?: InCallContextI;
  }) {
    Calling.safeUnregisterDevice(context);
    toast.success("Call ended.", {
      icon: <PhoneEndCallIcon className="w-5 text-base-content" />,
    });

    if (inCallContext) {
      const endAt = dayjs().format();

      inCallContext.setEndAt(endAt);

      let startAt = await asyncGet(inCallContext?.setStartAt);
      const call = await asyncGet(context?.setCall);

      const twilioCallStartAt = call?.parameters?.start_time;
      startAt = startAt || (twilioCallStartAt as string);

      if (!endAt || !startAt) {
        dd.rum.log(
          `${ERROR_CATEGORIES.TWILIO}[CALL DURATION] - failed to measure call length`,
          { data: { startAt, endAt } }
        );
      }

      if (
        endAt &&
        startAt &&
        DayJs(endAt).diff(DayJs(startAt), "seconds") < 2
      ) {
        dd.rum.error(
          `${ERROR_CATEGORIES.TWILIO}[CALL DURATION] - Too short (less than 2 seconds)`,
          { data: { defaultContext: context, inCallContext: inCallContext } }
        );
      }
    }

    context.setCallingState(DIALER_STATES.DISPOSITION);
  },

  async callNextContactOrBackToLeadSelection({
    context,
    inCallContext,
  }: {
    context: DefaultCallingContextI;
    inCallContext: InCallContextI;
  }) {
    const {
      setLeadQueue,
      callerProfile: { setAutoDial },
    } = context;

    const currentQueue = await asyncGet(setLeadQueue);

    if (currentQueue.length) {
      const nextContact = currentQueue[0];

      Calling.startCall({
        context,
        inCallContext,
        contactId: nextContact.id,
      });
    } else {
      const autoDial = await asyncGet(setAutoDial);

      if (autoDial) {
        toast("Finding next contacts...");
        setTimeout(async () => {
          const success = await Calling.startCallAndEnqueueCaller(context);
          if (!success) {
            context.setCallingState(DIALER_STATES.PRE);
          }
        }, 1000);
        context.setCallingState(DIALER_STATES.POST);
      } else {
        setTimeout(() => {
          if (context.isCallOnDemand) {
            context.setCallingState(DIALER_STATES.PRE);
          } else {
            context.setCallingState(DIALER_STATES.LEAD_SELECTION);
          }
        }, 2000);
        context.setCallingState(DIALER_STATES.POST);
        Calling.safeUnregisterDevice(context);
      }
    }
  },

  async clearCallData({
    context,
    inCallContext,
  }: {
    context: DefaultCallingContextI;
    inCallContext?: InCallContextI;
  }) {
    context.setCall(undefined);
    context.setIsCallOnDemand(false);
    context.callOnDemandConfig?.setCallId("");

    inCallContext?.clear();

    await Calling.safeUpdateTwilioAccessToken(context);
  },

  /**
   *
   * Should be called when Global Dialer is active
   *
   * Multiple Calls can be connected during active session
   *
   * Is user
   *  - currently have an active call
   *  - or dialer widget is open (Filling the disposition)
   *
   * no other call should be connected even if successfully routed
   *
   * @param props
   * @param {DialerGlobalContextI} props.dialerGlobalContext
   *
   * @returns {boolean}
   */
  async handleDeviceIncomingMessage_shouldRejectCall({
    callingContext,
    dialerGlobalContext,
  }: {
    callingContext: DefaultCallingContextI;
    dialerGlobalContext?: DialerGlobalContextI;
  }) {
    /**
     * If current device is busy with a call
     */
    const twDevice = await asyncGet(callingContext.setDevice);
    if (twDevice?.isBusy) return true;

    /**
     * Furher is only when related to the dialerGlobalContext
     */
    if (!dialerGlobalContext) return false;

    const isEnabled = await asyncGet(dialerGlobalContext.setIsEnabled);
    const isSessionClosing = await asyncGet(
      dialerGlobalContext.setIsSessionClosing
    );
    /**
     * Trying to accept the call either after session has ended
     * OR session is in the processes of wrapping up
     */
    if (!isEnabled || isSessionClosing) return true;

    /**
     * If Dialer Widget is open and user hasn't finished filling disposition
     */
    if (isDialerWidgetOpen()) return true;

    return false;
  },

  /**
   * Handles incoming device messages and tracks errors for the dialer global provider.
   *
   * This function retrieves the current state from the dialer global context, checks if the dialer is enabled,
   * and if so, it processes leads and logs errors using Datadog if the connected lead is not assigned.
   *
   *
   * @param props
   * @param {DialerGlobalContextI} props.dialerGlobalContext
   *
   * @returns {Promise<void>}
   */
  async dialerGlobalProvider_handleDeviceIncomingMessage_trackErrors({
    inCallContext,
    dialerGlobalContext,
  }: {
    inCallContext?: InCallContextI;
    dialerGlobalContext?: DialerGlobalContextI;
  }) {
    if (!dialerGlobalContext || !inCallContext) return;

    const { setIsEnabled, setIsSessionClosing, setLeadsProcessed } =
      dialerGlobalContext;
    const isEnabled = await asyncGet(setIsEnabled);
    const isSessionClosing = await asyncGet(setIsSessionClosing);

    if (!isEnabled || isSessionClosing) return;

    await wait(4000);

    const endAt = await asyncGet(inCallContext.setEndAt);

    /**
     * If call has already ended
     */
    if (endAt) return;

    const leadsProcessed = await asyncGet(setLeadsProcessed);

    const isError = !leadsProcessed.some(
      (lead) =>
        lead.status === DIALER_LIST_DAILING_STATUS.CONNECTED &&
        !!lead.list_membership_id
    );

    if (!isError) return;

    dd.rum.error(
      `${ERROR_CATEGORIES.DIALER_LIST} - connected lead is not assigned on Twilio incoming message`,
      { data: (window as any)[GLOBAL_DIALER_STATE] }
    );
  },

  /**
   * Handles the assignment of a connected lead upon receiving an incoming message from the dialer device.
   *
   * This function is used in the dialer global provider to handle incoming messages, assigning the connected lead based on the call and context provided.
   * It retrieves the list ID, fetches the connected lead information from the API using the call ID, and updates the context with the connected lead information.
   *
   * The function performs the following steps:
   * 1. Checks if the dialer global context is provided; if not, it returns early.
   * 2. Initializes the API client.
   * 3. Retrieves the necessary context functions and list ID.
   * 4. Fetches the connected lead information using the API and the call ID.
   * 5. If the response status is not 200, it returns early.
   * 6. If the response contains a valid membership ID and the status is 'CONNECTED', it updates the connected lead information in the context.
   *
   * @async
   * @function dialerGlobalProvider_handleDeviceIncomingMessage_assignConnectedLead
   * @param {Object} params - The parameters object.
   * @param {Call} params.call - The call object containing call details.
   * @param {DialerGlobalContextI} [params.dialerGlobalContext] - The context of the dialer global provider.
   * @returns {Promise<void>}
   */
  async dialerGlobalProvider_handleDeviceIncomingMessage_assignConnectedLead({
    call,
    dialerGlobalContext,
  }: {
    call: Call;
    dialerGlobalContext?: DialerGlobalContextI;
  }) {
    if (!dialerGlobalContext) return;

    const API = glencocoClientAPI();

    const {
      setListId,
      assignConnectedByListMemberMessageUpdate,
      setIsEnabled,
      setIsSessionClosing,
      setConnectedLead,
      setLeadsMap,
    } = dialerGlobalContext;
    const listId = await asyncGet(setListId);
    const callId = call.customParameters.get("call_id");

    const isEnabled = await asyncGet(setIsEnabled);
    const isSessionClosing = await asyncGet(setIsSessionClosing);
    const connectedLead = await asyncGet(setConnectedLead);

    if (!isEnabled || isSessionClosing || connectedLead) return;

    const leadsMap = await asyncGet(setLeadsMap);

    const ResponseConnectedLead = await API.getConnectedListMember(
      listId as string,
      callId as string
    ).catch((e) => e);

    if (ResponseConnectedLead.status !== 200) {
      dd.rum.error(
        `${ERROR_CATEGORIES.DIALER_LIST} - Backup method to update connected lead failed in Twilio event handler due to failed request`,
        {
          data: {
            listDialerState: (window as any)[GLOBAL_DIALER_STATE],
            error: ResponseConnectedLead,
          },
        }
      );

      toast.error("Failed to get Contact data");
      return;
    }

    const connectedCall =
      ResponseConnectedLead?.data as GetConnectedListMemberResponseI;

    if (
      connectedCall?.membership_id &&
      connectedCall.status === DIALER_LIST_DAILING_STATUS.CONNECTED
    ) {
      assignConnectedByListMemberMessageUpdate?.(connectedCall);
    } else {
      dd.rum.error(
        `${LOG_CATEGORIES.DIALER_LIST}[CONNECTED LEAD] - backup method to update connected lead in Twilio due to invalid membership_id`,
        {
          data: {
            listDialerState: (window as any)[GLOBAL_DIALER_STATE],
            callData: connectedCall,
            lead: leadsMap?.get(connectedCall?.membership_id),
          },
        }
      );
      toast.error("Failed to get Contact data");
    }
  },

  // ---------------- M A I N  F U N C T I O N  ---------------- \\
  /**
   *
   * Register and unregister all Twillio device listeners
   *
   * List of events
   *  - error
   *  - tokenWillExpire
   *  - deviceChange
   *  - inputVolume
   *  - incoming
   *
   * @param contexes
   * @param device
   */
  addDeviceListeners(
    {
      context,
      inCallContext,
      dialerGlobalContext,
    }: {
      context: DefaultCallingContextI;
      inCallContext: InCallContextI;
      dialerGlobalContext?: DialerGlobalContextI;
    },
    device: Device
  ) {
    // device.on("registered", function () {
    //   console.log("Twilio device is ready to make and receive calls!");
    //   // TODO update device status field in context
    // });
    // device.on("unregistered", function () {
    //   console.log("Twilio device is ready to make and receive calls!");
    //   // TODO update device status field in context
    // });

    /**
     * NOTE: we can update token ONLY WHEN NOT ON THE CALL
     * otherwise call gets dropped
     */
    const protectedUpdateAccessToken = async () => {
      const { setStartAt, setEndAt } = inCallContext;
      const { setDevice } = context;

      const startAt = await asyncGet(setStartAt);
      const endAt = await asyncGet(setEndAt);
      const device = await asyncGet(setDevice);

      const isDeviceHasCalls = device && !!device?.calls?.length;
      const isCallActive = startAt && !endAt;

      if (
        [isDeviceHasCalls, isCallActive].every(
          (condition) => condition === false
        )
      ) {
        Calling.updateTwilioAccessToken(device as Device);
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    device.on("error", (error, call) => {
      console.error("DEVICE_EVENT::error::", error);

      if (error.code == 20101 || error.code == 20104 || error.code == 20105) {
        protectedUpdateAccessToken();
      }
    });
    device.on("tokenWillExpire", () => {
      dd.rum.log(
        `${LOG_CATEGORIES.TWILIO} DEVICE_EVENT::tokenWillExpire`,
        device
      );
      protectedUpdateAccessToken();
    });
    device.audio?.on("deviceChange", async () => {
      dd.rum.log(`${LOG_CATEGORIES.TWILIO} DEVICE_EVENT::deviceChange`, device);
      console.log("DEVICE_EVENT::deviceChange::", device);

      context.setInputDevices(
        Array.from(device?.audio?.availableInputDevices ?? [])
      );

      context.setOutputDevices(
        Array.from(device.audio?.availableOutputDevices ?? [])
      );
    });
    device.audio?.on("inputVolume", (inputVol) => {
      FrequentlyChangingCallingContextEventDispatcher.dispatch.inputVolUpdated(
        inputVol
      );
    });

    device.on("incoming", function (call: Call) {
      dd.rum.log(`${LOG_CATEGORIES.TWILIO} DEVICE_EVENT::incoming`, call);
      console.log("DEVICE_EVENT::incoming::", call);

      (async () => {
        const shouldRejectCall =
          await Calling.handleDeviceIncomingMessage_shouldRejectCall({
            callingContext: context,
            dialerGlobalContext,
          });

        if (shouldRejectCall) {
          dd.rum.log(`[TWILIO][INCOMING] - Rejected call`, { data: { call } });

          call?.reject();

          return;
        }

        Calling.acceptCall(
          { context, inCallContext, dialerGlobalContext },
          call
        );

        const startAt = dayjs().format();

        inCallContext.setStartAt(startAt);

        Calling.addCallListeners(
          {
            context,
            inCallContext,
          },
          call
        );

        return context.setCallingState(DIALER_STATES.CALL);
      })();
    });
  },

  addCallListeners(
    {
      context,
      inCallContext,
    }: {
      context: DefaultCallingContextI;
      inCallContext: InCallContextI;
    },
    call: Call
  ) {
    // Add event listeners to call object
    const callEnded = () => {
      (async () => {
        await Calling.callEnded({ context, inCallContext });
      })();
    };

    call.on("accept", () => {
      console.log("DEVICE_EVENT::accept");
    });

    call.on("messageReceived", (message) => {
      //NOTE the voiceEventSid can be used for tracking the message
      console.log("voiceEventSid: ", message.voiceEventSid);

      // NOTE - custom property provided by the BE to track
      //        when the call actually started.
      const status = message.content?.status;

      if (status === "answered") {
        const startAt = dayjs().format();
        inCallContext.setStartAt(startAt);
      }
    });

    call.on("cancel", () => {
      dd.rum.log(`${LOG_CATEGORIES.TWILIO} DEVICE_EVENT::cancel`);
      callEnded();
    });
    call.on("disconnect", () => {
      dd.rum.log(`${LOG_CATEGORIES.TWILIO} DEVICE_EVENT::disconnect`);

      callEnded();
    });
    call.on("reject", () => {
      dd.rum.log(`${LOG_CATEGORIES.TWILIO} DEVICE_EVENT::reject`);

      callEnded();
    });
    call.on("volume", (inputVol, outputVol) => {
      FrequentlyChangingCallingContextEventDispatcher.dispatch.inputVolUpdated(
        inputVol
      );
      FrequentlyChangingCallingContextEventDispatcher.dispatch.outputVolUpdated(
        outputVol
      );
    });
    call.on("error", () => {
      // TODO (calling) handle errors in https://www.twilio.com/docs/voice/insights/call-quality-events-twilio-client-sdk#error-and-warning-events
    });
    call.on("warning", (warningName, warningData) => {
      // https://www.twilio.com/docs/voice/insights/call-quality-events-twilio-client-sdk#error-and-warning-events
      switch (warningName) {
        case "low-mos":
          toast.error("Call quality is low");

          // 1 = Bad ~ 5 = Excellent
          FrequentlyChangingCallingContextEventDispatcher.dispatch.callQualityUpdated(
            warningData.values.reduce((a: number, b: number) => a + b) /
              warningData.values.length
          );
          break;
        case "error":
          toast.error("Connection failed");
          break;
        case "failed":
          toast.error("Microphone not accessible");
          break;
        case "denied":
          toast.error("Audio device access denied");
          break;
        default:
      }
    });
    call.on("warning-cleared", (warningName) => {
      switch (warningName) {
        case "low-mos":
          FrequentlyChangingCallingContextEventDispatcher.dispatch.callQualityUpdated(
            5
          );

          break;
        default:
      }
    });
  },

  socketMessageHandler(msg: any) {
    console.log(msg);
  },

  // TODO Refactor logic to handle it in more expected manner
  // TODO callNextContactOrBackToLeadSelection shouldn't be a part of this function
  async exit({
    context,
    inCallContext,
  }: {
    context: DefaultCallingContextI;
    inCallContext: InCallContextI;
  }) {
    if (context.isCallOnDemand) {
      // NOTE
      // Remove activity event id from url
      // so if user reloads the page the previous call won't start
      const url = new URL(window.location.href);
      const pathname = url.pathname?.split("?")[0];
      const splittedPathname = pathname.split("/");

      if (splittedPathname.length === 4) {
        splittedPathname.pop();
        const newURL = `${url.origin}${splittedPathname.join("/")}`;

        history?.replaceState({}, document.title, newURL);
      }
    }

    context.setCallingState(DIALER_STATES.POST);

    Calling.clearCallData({ context, inCallContext });
    Calling.callNextContactOrBackToLeadSelection({
      context,
      inCallContext,
    });
  },

  async cleanupAndExit({
    context,
    inCallContext,
  }: {
    context: DefaultCallingContextI;
    inCallContext: InCallContextI;
  }) {
    if (context.isCallOnDemand) {
      // NOTE
      // Remove activity event id from url
      // so if user reloads the page the previous call won't start
      const url = new URL(window.location.href);
      const pathname = url.pathname?.split("?")[0];
      const splittedPathname = pathname.split("/");

      if (splittedPathname.length === 4) {
        splittedPathname.pop();
        const newURL = `${url.origin}${splittedPathname.join("/")}`;

        history?.replaceState({}, document.title, newURL);
      }
    }

    Calling.clearCallData({ context, inCallContext });

    context.setCallingState(DIALER_STATES.PRE);
  },
};

export const getWebSocketConnection = (callingCtx: DefaultCallingContextI) => {
  const API = glenSocketClientAPI();

  // Create Websocket connection
  const newWS = API.getWebSocketConnection(
    // on error
    (err) => {
      console.log(`Connection error: ${err}`);
    },
    // on close
    () => {
      console.log("Connection with the backend closed");
    },
    // on open
    async (ws: WebSocket) => {
      if (callingCtx?.campaignId) {
        CallingService(ws).setup(callingCtx?.campaignId);
      }

      callingCtx.socket = ws;
    },
    (msg) => Calling.socketMessageHandler(msg)
  );

  if (newWS === null) {
    toast.error(`Failed to create connection`);
    return;
  }

  return newWS;
};

export const disconnectMic = () => {
  ((window as any).DIALER_MODULE_AUDIO_STREAM as MediaStream)
    ?.getTracks()
    .forEach((track) => {
      track.stop();
    });

  (window as any).DIALER_MODULE_AUDIO_STREAM = null;
};

export const askPermissionMic = () => {
  return new Promise((resolve, reject) => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        disconnectMic();

        (window as any).DIALER_MODULE_AUDIO_STREAM = stream;
        resolve(true);
      })
      .catch(() => {
        reject(false);
      });
  });
};

export const endSession = async (
  callContext: DefaultCallingContextI,
  callbackFn: () => void
) => {
  await Calling.safeUnregisterDevice(callContext);

  const API = glenSocketClientAPI();

  await API.setAutoDial(false).catch((e) => e);

  const { setSocket, setCall, setDevice } = callContext;
  const currentSocket = await asyncGet(setSocket);

  if (currentSocket) {
    CallingService(currentSocket).endSession();
    setSocket(undefined);
  }

  const currentCall = await asyncGet(setCall);
  if (currentCall) {
    currentCall.disconnect();
    dd.rum.log(
      `${LOG_CATEGORIES.TWILIO}[DISCONNECT] - manual disconnect endSession`
    );
    setCall(undefined);
  }

  callContext.setLeadInfo(defaultLeadInfo);

  await Calling.safeUpdateTwilioAccessToken(callContext);
  const currentDevice = await asyncGet(setDevice);
  if (currentDevice) {
    currentDevice.disconnectAll();
  }

  const campaignId = await asyncGet(callContext.setCampaignID);

  if (!campaignId) {
    toast.error(`Unknown Campaign`);
    return;
  }

  API.dequeueCaller(campaignId)
    .then(() => {
      callContext.setLeadInfo(defaultLeadInfo);
      callContext.setLeadQueue([]);
      callbackFn();
    })
    .catch((err) => {
      toast.error("Failed to end session : ", err.message);
    });
};

export default Calling;
