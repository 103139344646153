import { InternalAxiosRequestConfig } from "axios";
import { AxiosInstanceInterceptorRequestExtensionI } from "../interface";

export const handlerMetaInterceptor = (
  request: InternalAxiosRequestConfig<any> &
    AxiosInstanceInterceptorRequestExtensionI
) => {
  // to avoid overwriting if another interceptor
  // already defined the same object (meta)
  request.meta = request.meta || {};
  request.meta.requestStartedAt = new Date().getTime();

  return request;
};
