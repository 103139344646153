import { FC, useMemo } from "react";

import { clsxMerge } from "shared/lib/helpers";
import { ACCOUNT_SIDEBAR_ACTION_BUTTON_CLASSNAME } from "@/modules/pipeline/account-details/sidebar/sections/section";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { EDIT_CONTACT_MODAL_ID } from "@/modals/edit-contact-modal";
import { SET_UNSET_PRIMARY_LEAD_CONFIRMATION_MODAL_ID } from "@/modals/set-primary-lead-confirmation-modal";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { ContactDetailI } from "shared/lib/interfaces/account";
import { DELETE_ACCOUNT_LEAD_CONFIRMATION_MODAL_ID } from "@/modals/delete-account-lead-confirmation-modal";
import { SET_CONTACT_TYPE_MODAL_ID } from "@/modals/set-contact-type-modal";
import { DISQUALIFY_MODAL_ID } from "@/modals/disqualify-modal";
import { ActionMenu } from "@/components/shared/action-menu";

interface AccountDetailsV2LeadItemEditLeadMenuPropsI {
  contact?: ContactDetailI;
  isDeleteContactAllowed?: boolean;
  isDisqualified: boolean;
}

const getSetLabel = (currentValue: boolean) => (currentValue ? "Unset" : "Set");

export const AccountDetailsV2LeadItemEditLeadMenu: FC<
  AccountDetailsV2LeadItemEditLeadMenuPropsI
> = ({ contact, isDeleteContactAllowed, isDisqualified }) => {
  const { setFocusedContact } = useAccountDetailsContext();

  const isPrimaryContact = !!contact?.is_primary_lead;

  const actions = useMemo(() => {
    const basicOptions = [
      {
        title: "Edit lead",
        handler: () => {
          setFocusedContact(contact);
          modalHelpers.lazyOpen(EDIT_CONTACT_MODAL_ID);
        },
      },
      {
        title: `${getSetLabel(isPrimaryContact)} as primary lead`,
        handler: () => {
          setFocusedContact(contact);
          modalHelpers.lazyOpen(SET_UNSET_PRIMARY_LEAD_CONFIRMATION_MODAL_ID);
        },
      },
      {
        title: "Set contact type",
        handler: () => {
          setFocusedContact(contact);
          modalHelpers.lazyOpen(SET_CONTACT_TYPE_MODAL_ID);
        },
      },
    ];

    if (!isDisqualified) {
      basicOptions.push({
        title: "Disqualify",
        handler: () => {
          setFocusedContact(contact);
          modalHelpers.lazyOpen(DISQUALIFY_MODAL_ID);
        },
      });
    }

    if (isDeleteContactAllowed) {
      basicOptions.push({
        title: "Delete",
        handler: () => {
          setFocusedContact(contact);
          modalHelpers.lazyOpen(DELETE_ACCOUNT_LEAD_CONFIRMATION_MODAL_ID);
        },
      });
    }

    return basicOptions;
  }, [isDeleteContactAllowed, isPrimaryContact, setFocusedContact]);

  return (
    <ActionMenu
      actions={actions}
      itemClassName="w-max"
      trigger={
        <button
          className={clsxMerge(
            ACCOUNT_SIDEBAR_ACTION_BUTTON_CLASSNAME,
            "-translate-y-1"
          )}
        >
          Edit
        </button>
      }
    />
  );
};
