import isEqual from "lodash/isEqual";
import xorWith from "lodash/xorWith";
import isEmpty from "lodash/isEmpty";

export function deleteEmptyKeys(obj: object) {
  Object.keys(obj).forEach((key) => {
    if (!obj[key as keyof object]) {
      delete obj[key as keyof object];
    }
  });

  return obj;
}

export function rotate<T>(arr: Array<T>): Array<T> {
  if (!Array.isArray(arr)) return arr;

  const newArray = [...arr];

  if (newArray.length > 1) {
    newArray.unshift(newArray.pop() as T);
  }

  return newArray;
}

//TODO check usage and replace in favour of lodash function
export function trimString(
  text: string,
  maxChars: number,
  ellipsesDirection: "left" | "right" = "right"
) {
  if (text.length < maxChars) {
    return text;
  }

  if (ellipsesDirection == "left") {
    return "..." + text.slice(-maxChars);
  } else {
    return text.slice(0, maxChars) + "...";
  }
}

export function formatUSPhoneNumber(phoneNumberString?: string) {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }

  return phoneNumberString;
}

export const createKeyValuePair = (key: string, value: any) => ({
  key,
  value,
});

export function fixUrl(url?: string) {
  if (!url) return url;

  const preparedUrl = url.trim();

  if (!preparedUrl.includes("http") && !preparedUrl.includes("https")) {
    return `https://${preparedUrl}`;
  }

  return preparedUrl;
}

export const checkIfClient = () => typeof window !== "undefined";

export const pluralizeNoun = (noun: string, count: number): string => {
  if (count === 1) {
    return noun;
  }

  if (
    noun.endsWith("y") &&
    !["a", "e", "i", "o", "u"].includes(noun[noun.length - 2])
  ) {
    return noun.slice(0, -1) + "ies";
  } else if (["s", "x", "z", "ch", "sh"].some((s) => noun.endsWith(s))) {
    return noun + "es";
  }

  return noun + "s";
};

export const getYesNoFromBoolean = (value: boolean | null | undefined) => {
  switch (value) {
    case true:
      return "Yes";
    case false:
      return "No";
    default:
      return "";
  }
};

export const cacheBreaker = (url: string) => {
  return url?.includes("?")
    ? `${url}&d=${new Date().valueOf()}`
    : `${url}?d=${new Date().valueOf()}`;
};

interface EntityWithFirstAndLastNameI {
  first_name?: string;
  last_name?: string;
}

export const getFullEntityName = <T extends EntityWithFirstAndLastNameI>(
  caller?: T | null,
  emptyNamePlaceholder = "[Name is not available]"
) =>
  `${caller?.first_name || ""} ${caller?.last_name || ""}`.trim() ||
  emptyNamePlaceholder;

/**
 * Compares two arrays for equality, checking if they contain the same elements.
 *
 * This function uses `xorWith` to perform a symmetric difference between two arrays
 * with a custom comparator (`isEqual`). If the result is empty, it means the arrays
 * are equal.
 *
 * @link https://stackoverflow.com/a/37066038
 *
 * @template T
 * @param {Array<T>} x - The first array to compare.
 * @param {Array<T>} y - The second array to compare.
 * @returns {boolean} - Returns `true` if the arrays are equal, `false` otherwise.
 */
export const isArrayEqual = <T>(arr1: Array<T>, arr2: Array<T>): boolean => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;
  if (arr1?.length !== arr2?.length) return false;

  return isEmpty(xorWith(arr1, arr2, isEqual));
};
