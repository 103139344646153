import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";

import { fixUrl, formatNumbers } from "shared/lib/helpers";

import {
  LabelValue,
  createRecord,
} from "@/modules/pipeline/account-details/sidebar/components/label-value";

import { formatMoneyDollars } from "shared/lib/helpers/numbers";
import {
  ACCOUNT_SIDEBAR_ACTION_BUTTON_CLASSNAME,
  AccountDetailsV2SidebarSection,
} from "@/modules/pipeline/account-details/sidebar/sections/section";
import { modal } from "@/modals/index";
import { EDIT_ACCOUNT_MODAL_ID } from "@/modals/edit-account-modal";

export const AccountSection = ({ className }: { className?: string }) => {
  const { campaign, account } = useAccountDetailsContext();

  const accountInfoItems = [
    createRecord("Campaign Name", campaign?.name || null),
    createRecord(
      "Website",
      account?.website ? (
        <a
          className="ae-link w-full cursor-pointer"
          target="_blank"
          href={fixUrl(account?.website)}
          rel="noreferrer"
        >
          {fixUrl(account?.website)}
        </a>
      ) : null,
      "truncate h-5"
    ),
    createRecord(
      "Revenue",
      account?.revenue
        ? `${formatMoneyDollars(account.revenue, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}`
        : null
    ),
    createRecord(
      "Employees",
      account?.employee_count
        ? `${formatNumbers(account?.employee_count)}`
        : null
    ),
    createRecord("Industry", account?.industry || null),
    createRecord("HQ State", account?.hq_state || null),
  ];

  return (
    <AccountDetailsV2SidebarSection
      title={account?.name || "Account"}
      className={className}
      actionsSection={
        <button
          className={ACCOUNT_SIDEBAR_ACTION_BUTTON_CLASSNAME}
          onClick={() => modal.trigger(EDIT_ACCOUNT_MODAL_ID)}
        >
          Edit
        </button>
      }
    >
      <div className="mt-4 grid w-full grid-cols-2">
        {accountInfoItems?.map((record, i) => (
          <LabelValue key={i} className="mb-5" {...record} />
        ))}
      </div>
    </AccountDetailsV2SidebarSection>
  );
};
