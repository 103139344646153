import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import isYesterday from "dayjs/plugin/isYesterday";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjsDuration from "dayjs/plugin/duration";
import "dayjs/locale/en";

import { DATE_FORMAT } from "shared/lib/constants/time";
import { checkIfClient } from "./utils";

dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(isYesterday);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(relativeTime);
dayjs.extend(dayjsDuration);

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export const DayJs = dayjs;

export const safeGetDate = (
  date: string | Date | Dayjs | undefined,
  format: string = DATE_FORMAT,
  timezone?: string
) => {
  if (checkIfClient()) {
    let dayObject = date ? dayjs(date) : dayjs();

    if (timezone) {
      dayObject = dayObject.tz(timezone);
    }

    return dayObject.format(format);
  }

  return "";
};

export const isDifferentDay = (date1: string, date2: string) => {
  const formattedDate1 = dayjs(date1).format("YYYY-MM-DD");
  const formattedDate2 = dayjs(date2).format("YYYY-MM-DD");

  return formattedDate1 !== formattedDate2;
};

export interface TimeDurationsI {
  hours?: number;
  minutes?: number;
  days?: number;
}

export const convertTimeToNormalizedDuration = ({
  hours: _hours = 0,
  minutes: _minutes = 0,
  days: _days = 0,
}: TimeDurationsI) => {
  const totalHours = _hours + _days * 24 + _minutes / 60;

  const days = Math.floor(totalHours / 24);
  const remainingHours = totalHours % 24;
  const remainingMinutes = Math.round(
    (remainingHours - Math.floor(remainingHours)) * 60
  );

  return {
    days: days,
    hours: Math.floor(remainingHours),
    minutes: remainingMinutes,
  };
};

// https://stackoverflow.com/questions/15083548/convert-12-hour-hhmm-am-pm-to-24-hour-hhmm
export const convertTime12to24 = (time12h: string) => {
  const [time, modifier] = time12h.split(" ");

  const timeDecomposed = time.split(":");

  let hours = timeDecomposed[0];
  const minutes = timeDecomposed[1];

  if (hours === "12") {
    hours = "00";
  }

  if (modifier.toLocaleLowerCase() === "pm") {
    hours = (parseInt(hours, 10) + 12).toString();
  }

  return {
    hours: parseInt(hours),
    minutes: parseInt(minutes),
    formatted: `${hours}:${minutes}`,
  };
};

// export const convertMinutes

export const getCurrentYear = () => dayjs().year();

export const duration = dayjs.duration;
