import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { DIALER_LIST_DAILING_STATUS, DIALER_STATES } from "@/constants/dialer";
import { WIDGETS } from "@/constants/widgets";
import Router from "@/helpers/router";

import { useCallingContext } from "@/hooks/dialer/use-dialer-context";
import { useDialerGlobalContext } from "@/hooks/dialer/use-dialer-global-context";
import { useRouter } from "next/router";

import { EventHandler, useEffect } from "react";

export const DialerWidgetController = () => {
  const { setCallingState, setCall, setCampaignID } = useCallingContext();
  const { connectedLead, setConnectedLead, setCalls } =
    useDialerGlobalContext();
  const router = useRouter();

  /**
   * As soon as connected lead is established in the global dialer context
   * it is required to set the dialing state to Call in the comoponent
   * where callingContext is available
   */
  useEffect(() => {
    if (connectedLead) {
      if (connectedLead?.campaign_id) setCampaignID(connectedLead?.campaign_id);

      setCallingState(DIALER_STATES.CALL);
    }
  }, [connectedLead]);

  useEffect(() => {
    const handleEventOpenWidget = () => {
      setConnectedLead?.((connectedLead) => {
        return connectedLead;
      });
    };

    const handleEventCloseWidget = () => {
      /**
       * The referrence to acceptCall function in CallingHelper
       */
      setCallingState(DIALER_STATES.DIALING);

      /**
       * Make sure call is cleared to avoid disconnects
       */
      setCall(undefined);

      /**
       * Due to delay on the BE side to ensure stable dialing to the next lead
       * and accurate statuses
       */
      setConnectedLead?.((lead) => {
        if (lead) {
          setCalls?.((prevCallsMap) => {
            const newCallsMap = new Map(prevCallsMap);
            const connectedLead = newCallsMap.get(lead.list_membership_id);

            if (!connectedLead) return prevCallsMap;

            newCallsMap.set(lead.list_membership_id, {
              ...connectedLead,
              status: DIALER_LIST_DAILING_STATUS.HAD_CONVERSATION,
            });

            return newCallsMap;
          });
        }

        return undefined;
      });

      /**
       * Redirect back to dialing list
       */
      router.push(Router.lists());
    };

    const closeWidgetEventArgs = [
      CUSTOM_EVENTS.WIDGETS[WIDGETS.MAXED_DIALER].CLOSE,
      handleEventCloseWidget as EventHandler<any>,
    ] as [type: string, listener: EventListenerOrEventListenerObject];

    const openWidgetEventArgs = [
      CUSTOM_EVENTS.WIDGETS[WIDGETS.MAXED_DIALER].OPEN,
      handleEventOpenWidget as EventHandler<any>,
    ] as [type: string, listener: EventListenerOrEventListenerObject];

    window.document.addEventListener(...openWidgetEventArgs);
    window.document.addEventListener(...closeWidgetEventArgs);

    return () => {
      window.document.removeEventListener(...openWidgetEventArgs);
      window.document.removeEventListener(...closeWidgetEventArgs);
    };
  }, []);

  return null;
};
