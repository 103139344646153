import { AxiosInstance } from "axios";

import {
  GetAccountHistoryResponseI,
  GetAccountHistoryV2ResponseI,
} from "@/api/glencoco";
import {
  DisqualifyAccountOrContactRequestParamsI,
  GetAccountDetailsResponse,
  ReplyAccountContactEmailRequestParamsI,
  SendAccountContactEmailRequestParamsI,
} from "@/api/routes/account/interfaces";
import { AccountDispositionNoteI } from "@/modules/pipeline/account-details/interfaces";
import { ProspectDispositionTypeI } from "shared/lib/interfaces/dispositions";

export const AccountAPIEndpoints = (API: AxiosInstance) => ({
  getAccountDetails: (campaignId: string, accountId: string) =>
    API.get<GetAccountDetailsResponse>(
      `v2/campaign/${campaignId}/account/${accountId}`
    ),

  sendAccountEmail: (
    campaignId: string,
    contactId: string,
    params: SendAccountContactEmailRequestParamsI
  ) =>
    API.post(`/v1/campaign/${campaignId}/contact/${contactId}/followup_email`, {
      ...params,
    }),

  replyAccountContactEmail: (
    emailId: string,
    params: ReplyAccountContactEmailRequestParamsI
  ) =>
    API.post(`v1/email/${emailId}/respond`, {
      ...params,
    }),

  // TODO: Remove - This endpoint is not used in the codebase
  disqualifyAccount: (
    campaignId: string,
    accountId: string,
    callDispositionId: string,
    notes: string
  ) =>
    API.post(`/v1/campaign/${campaignId}/account/${accountId}/disqualify`, {
      call_disposition: callDispositionId,
      notes,
    }),

  // TODO: Remove -This endpoint is not used in the codebase
  getAccountHistory: (
    campaignId: string,
    accountId: string,
    nextToken: string | undefined
  ) =>
    API.post<GetAccountHistoryResponseI>(
      `v1/campaign/${campaignId}/account/${accountId}/call_history`,
      {
        next_token: nextToken,
      }
    ),

  getAccountHistoryV2: (
    campaignId: string,
    accountId: string,
    nextToken: string | undefined
  ) =>
    API.post<GetAccountHistoryV2ResponseI>(
      `v2/campaign/${campaignId}/account/${accountId}/call_history`,
      {
        next_token: nextToken,
      }
    ),

  // This endpoint is reused across Add note and Disqualify account modals
  sendAccountDispositionNote: (
    campaignId: string,
    accountId: string,
    params: AccountDispositionNoteI
  ) =>
    API.post(`v1/campaign/${campaignId}/account/${accountId}/disposition`, {
      notes: params.notes,
      contact_id: params.accountContactId,
      note_type: params.noteType,
    }),

  disqualifyAccountOrContact: (
    campaignId: string,
    accountId: string,
    params: DisqualifyAccountOrContactRequestParamsI
  ) =>
    API.post(`v2/campaign/${campaignId}/account/${accountId}/disqualify`, {
      notes: params.notes,
      contact_id: params.contactId,
      disqualification_disposition: params.disqualificationDisposition,
    }),

  editNextTouchTime: (
    campaignId: string,
    accountId: string,
    nextTouchTime: string
  ) =>
    API.post(
      `v1/campaign/${campaignId}/account/${accountId}/edit_next_follow_up_time`,
      { next_follow_up_time: nextTouchTime }
    ),

  setUnsetContactAsPrimary: (
    campaignId: string,
    accountId: string,
    contactId: string,
    isPrimary: boolean
  ) =>
    API.post(
      `v1/campaign/${campaignId}/account/${accountId}/contact/${contactId}/set_primary`,
      { set: isPrimary }
    ),

  setContactType: (
    campaignId: string,
    accountId: string,
    contactId: string,
    contactType: ProspectDispositionTypeI
  ) =>
    API.post(
      `v1/campaign/${campaignId}/account/${accountId}/contact/${contactId}/set_contact_type`,
      { contact_type: contactType }
    ),

  removeListFromAccount: (
    campaignId: string,
    accountId: string,
    listId: string
  ) =>
    API.post(
      `v1/campaign/${campaignId}/account/${accountId}/list/${listId}/remove`
    ),

  cancelMeeting: (campaignId: string, accountId: string) =>
    API.post(`v1/campaign/${campaignId}/account/${accountId}/cancel_meeting`),
});
