import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
  ChangeEvent,
  KeyboardEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from "react";
import _debounce from "lodash/debounce";
import { twMerge } from "tailwind-merge";
import clsx from "clsx";

import { XMarkIcon } from "@heroicons/react/24/solid";
import TextField from "shared/ui/ae-user-input/text-field";

interface SearchFieldPropsI {
  value?: string;
  inputId?: string; // May be useful for focusing on the input
  className?: string;
  iconClassName?: string;
  isIconVisible?: boolean;
  inputContainerClassName?: string;
  inputClassName?: string;
  loaderClassName?: string;
  placeholder?: string;
  onSearch?: (val: string) => void;
  isLoadingData?: boolean;
  isLoadingIndicatorVisible?: boolean;
  debounceTime?: number;
  isDisabled?: boolean;
  isSearchButtonVisible?: boolean;
  isClearable?: boolean;
  searchButtonClassName?: string;
  isEnterTracked?: boolean;
}

const EnhancedTextField = TextField();

const DEFAULT_DEBOUNCE_TIME = 700;

const SearchField: FC<SearchFieldPropsI> = ({
  value,
  inputId,
  className,
  iconClassName,
  isIconVisible = true,
  inputContainerClassName,
  inputClassName,
  loaderClassName,
  placeholder = "",
  onSearch = () => {},
  isLoadingIndicatorVisible = true,
  isLoadingData,
  debounceTime = DEFAULT_DEBOUNCE_TIME,
  isDisabled = false,
  isSearchButtonVisible = false,
  isClearable,
  searchButtonClassName,
  isEnterTracked = false,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(value || "");

  const debouncedSearch = useCallback(
    _debounce(
      (val: string) => {
        onSearch(val);
      },
      debounceTime,
      { leading: false, trailing: true }
    ),
    [onSearch]
  );

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const value = ev?.target?.value;
    setSearchValue(value);
    setIsLoading(!!value);

    if (!isSearchButtonVisible) {
      debouncedSearch(value);
    } else if (value === "") {
      onSearch("");
    }
  };

  const onKeyPress = (ev: KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === "Enter" && isEnterTracked) {
      onSearch((ev.target as HTMLInputElement).value);
    }
  };

  const handleSearchSubmit = () => {
    if (isSearchButtonVisible) {
      onSearch(searchValue);
    }
  };

  useEffect(() => {
    setIsLoading(!!isLoadingData);
  }, [isLoadingData]);

  useEffect(() => {
    setSearchValue(value || "");
  }, [value]);

  return (
    <div
      className={twMerge(
        clsx(
          "flex w-[420px] items-center rounded-lg border-2 border-black/10",
          className,
          {
            "pointer-events-none cursor-not-allowed opacity-80": isDisabled,
          }
        )
      )}
    >
      {isIconVisible && (
        <MagnifyingGlassIcon
          className={twMerge(clsx("ml-5 h-4 w-4 text-black/60", iconClassName))}
        />
      )}

      <EnhancedTextField
        name="search"
        placeholder={placeholder}
        className={twMerge(clsx("m-0 h-full", inputContainerClassName))}
        inputClassName={twMerge(
          clsx(
            "bg-white border-none focus:outline-none focus:bg-white",
            inputClassName
          )
        )}
        inputProps={{
          value: searchValue,
          id: inputId,
          onChange: handleChange,
          onKeyPress,
        }}
      />

      {isLoadingIndicatorVisible && isLoading && (
        <button
          className={clsx(
            "btn btn-ghost cursor-default hover:bg-white",
            {
              loading: isLoading,
            },
            loaderClassName
          )}
        />
      )}

      {isClearable && searchValue && (
        <button
          className={clsx("btn-text mr-2", searchButtonClassName)}
          onClick={() => {
            setSearchValue("");
            onSearch("");
          }}
        >
          <XMarkIcon className="h-4 w-4" />
        </button>
      )}

      {isSearchButtonVisible && (
        <button
          className={clsx(twMerge("btn-ae-default", searchButtonClassName))}
          onClick={handleSearchSubmit}
        >
          <MagnifyingGlassIcon className="h-4 w-4" />
        </button>
      )}
    </div>
  );
};

export default SearchField;
