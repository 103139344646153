import { Field } from "formik";

import {
  DISPOSITIONS_V3,
  DISPOSITIONS_V3_MAP,
} from "shared/lib/constants/dispositions";
import { clsxMerge } from "shared/lib/helpers";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";
import { Dropdown } from "shared/ui";
import { DropdownOptionI } from "shared/ui/user-input/dropdown";
import { NextTouchTimeDropdown } from "./next-touch-time-dropdown";

const DISPOSITION_OPTIONS: DropdownOptionI[] = Object.values(
  DISPOSITIONS_V3
).map((dispoKey) => ({
  label: DISPOSITIONS_V3_MAP[dispoKey],
  value: dispoKey,
}));

const EnhancedDropdown = Dropdown(Field);

export const DispositionRow = ({
  names,
  disposition,
  errors,
  touched,
  className,
  customNextTouchTime,
}: {
  names: Array<string>;
  disposition?: ValueOfObjectFields<typeof DISPOSITIONS_V3>;
  errors?: Record<string, string>;
  touched?: Record<string, boolean>;
  className?: string;
  customNextTouchTime?: string;
}) => {
  return (
    <div
      className={clsxMerge(
        "flex w-full animate-fadein flex-wrap items-center gap-x-4",
        className
      )}
    >
      <EnhancedDropdown
        name={names[0]}
        label="Disposition"
        placeholder="Select"
        options={DISPOSITION_OPTIONS}
        errors={errors?.[names[0]]}
        touched={touched?.[names[0]]}
        className="relative mb-0 w-full max-w-[240px] animate-fadein"
        labelContentClassName="brand-typography-body2"
        inputClassName="text-black"
        errorsClassName="absolute -bottom-[22px] whitespace-nowrap"
      />

      {![
        DISPOSITIONS_V3.DO_NOT_CALL as string,
        DISPOSITIONS_V3.QC_NOT_MET_FOR_ACCOUNT,
        DISPOSITIONS_V3.OTHER_DO_NOT_FOLLOW_UP,
      ].includes(disposition as string) && (
        <NextTouchTimeDropdown
          name={names[1]}
          errors={errors?.[names[1]]}
          touched={touched?.[names[1]]}
          customNextTouchTime={customNextTouchTime}
        />
      )}
    </div>
  );
};
