import Router from "@/helpers/router";

export const LOGO_VARIATION = {
  DEFAULT: "default",
  LIGHT: "default",
  DARK: "dark",
  WHITE: "white",
  BLACK: "black",
  SMALL_WHITE: "small_white",
} as const;

export const LOGO_VARIATION_MAP = {
  [LOGO_VARIATION.DEFAULT]:
    "https://glencoco-assets.s3.amazonaws.com/logos/v2/logo-glencoco-v2-default.svg",
  [LOGO_VARIATION.WHITE]:
    "https://glencoco-assets.s3.amazonaws.com/logos/v2/logo-glencoco-v2-mono-white.svg",
  [LOGO_VARIATION.BLACK]:
    "https://glencoco-assets.s3.amazonaws.com/logos/v2/logo-glencoco-v2-mono-black.svg",
  [LOGO_VARIATION.DARK]:
    "https://glencoco-assets.s3.amazonaws.com/logos/v2/logo-glencoco-v2-dark.svg",
  [LOGO_VARIATION.SMALL_WHITE]:
    "https://glencoco-assets.s3.amazonaws.com/logos/logo-glencoco-sm-white.png",
};

const getLogoSrc = (
  variation?: (typeof LOGO_VARIATION)[keyof typeof LOGO_VARIATION]
) => {
  return (
    LOGO_VARIATION_MAP[
      variation as (typeof LOGO_VARIATION)[keyof typeof LOGO_VARIATION]
    ] || LOGO_VARIATION_MAP[LOGO_VARIATION.DEFAULT]
  );
};

export const Logo = ({
  variation,
  width,
  className,
  href,
}: {
  variation?: (typeof LOGO_VARIATION)[keyof typeof LOGO_VARIATION];
  width?: string;
  className?: string;
  href?: string;
}) => (
  <a href={href || Router.home()} className={className}>
    <img
      src={getLogoSrc(variation)}
      alt="Glencoco Logo"
      width={width || "89px"}
    />
  </a>
);
