import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { glencocoClientAPI } from "@/api/glencoco";
import {
  ContactToListI,
  CreateOrUpdateListRequestParamsI,
} from "@/api/routes/list";
import toast from "react-hot-toast";
import { pluralizeNoun } from "shared/lib/helpers";

const LISTS_QUERY_KEY = "lists";

export const useFetchLists = () =>
  useQuery({
    queryKey: [LISTS_QUERY_KEY],
    queryFn: glencocoClientAPI().getLists,
  });

export const useCreateList = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (parameters: CreateOrUpdateListRequestParamsI) =>
      glencocoClientAPI().createList(parameters),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [LISTS_QUERY_KEY],
      }),
  });
};

export const useBulkAddContactsToList = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      contacts,
      list_ids,
    }: {
      contacts: ContactToListI[];
      list_ids: string[];
    }) =>
      glencocoClientAPI().bulkAddContactsToListRequest({
        contacts,
        list_ids,
      }),
    onSuccess: (_, { contacts: { length }, list_ids: listIds }) => {
      toast.success(
        `${
          length > 1 ? "Contacts have" : "Contact has"
        } been added to the specified ${pluralizeNoun("list", length)}`
      );

      listIds.forEach((listId) => {
        queryClient.invalidateQueries({
          queryKey: [LISTS_QUERY_KEY, listId, "contacts"],
        });
      });
    },
    onError: (_, { contacts: { length } }) => {
      toast.error(
        `Failed to add ${pluralizeNoun(
          "contact",
          length
        )} to the specified ${pluralizeNoun("list", length)}`
      );
    },
  });
};
