import { FC } from "react";
import { useRouter } from "next/router";

import { clsxMerge } from "shared/lib/helpers";
import { getListIcon } from "@/components/modules/pipeline/open/list/lists/activities/items/item";
import { AccountUserListI } from "@/interfaces/accounts";
import { CloseIcon } from "shared/ui/icons";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import Router from "@/helpers/router";

import { useLazyModal } from "shared/lib/hooks";
import { ConfirmRemoveContactsFromList } from "@/components/modals/lists/confirm-remove-contacts-from-list";
import { ACCOUNT_LIST_TYPES } from "@/modules/pipeline/constants";
import { useRemoveListFromAccount } from "@/api/routes/account/mutations";

interface AccountDetailsV2ListItemPropsI {
  className?: string;
  listData: AccountUserListI;
  campaignId?: string;
  accountId?: string;
}

const CONFIRM_REMOVE_LIST_FROM_ACCOUNT = "confirm-remove-list-from-account";

export const AccountDetailsV2ListItem: FC<AccountDetailsV2ListItemPropsI> = ({
  className,
  listData,
  campaignId,
  accountId,
}) => {
  const isExclusive = listData?.list_type === ACCOUNT_LIST_TYPES.EXCLUSIVE;
  const router = useRouter();
  const { onAccountUpdate } = useAccountDetailsContext();

  const { mutateAsync: removeListFromAccount } = useRemoveListFromAccount();

  const { isOpen, onOpen, onClose } = useLazyModal(
    CONFIRM_REMOVE_LIST_FROM_ACCOUNT
  );

  const handleRemove = async () => {
    if (!campaignId || !accountId) {
      return;
    }

    await removeListFromAccount(
      {
        campaignId,
        accountId,
        listId: listData.list_id as string,
      },
      {
        onSuccess: () => {
          onClose();
          onAccountUpdate();
        },
      }
    );
  };

  return (
    <div
      title={listData?.name}
      // An external wrapper serving to create a border gradient effect
      className={clsxMerge(
        "w-fit rounded-lg border-2",
        {
          "border-transparent bg-gradient-to-r from-[#4C6192] via-[#7E46CA] to-[#CB36AD]":
            isExclusive,
        },
        className
      )}
    >
      <div
        className={clsxMerge(
          "flex w-fit max-w-[260px] items-center",
          "cursor-pointer gap-2 transition-colors",
          "rounded-md p-2",
          "hover:bg-[rgb(247,247,247)]",
          {
            "bg-white": isExclusive,
          }
        )}
        onClick={() => router.push(Router.lists(listData.list_id))}
      >
        <span className="text-[#F88167]">
          {listData.list_type &&
            getListIcon({
              list_type: listData.list_type,
              isExclusive,
            })}
        </span>

        <p
          className={clsxMerge("b-typography-detail2 truncate", {
            "bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 bg-clip-text text-transparent":
              isExclusive,
          })}
        >
          {listData?.name}
        </p>

        <CloseIcon
          className={clsxMerge(
            "w-4 min-w-4 cursor-pointer rounded-full transition-colors",
            "hover:bg-black hover:text-white"
          )}
          onClick={(e) => {
            e.stopPropagation();
            onOpen();
          }}
        />
      </div>

      {isOpen && (
        <ConfirmRemoveContactsFromList
          id={CONFIRM_REMOVE_LIST_FROM_ACCOUNT}
          title={`Remove these leads from the list ${listData.name}?`}
          onConfirm={() => handleRemove()}
          onCancel={onClose}
          onClose={onClose}
        />
      )}
    </div>
  );
};
