import { useMemo } from "react";
import { StarIcon } from "@heroicons/react/24/solid";

import { clsxMerge } from "shared/lib/helpers";
import { PrimaryNavCampaignsSelector } from "@/components/primary-navigation/v3/campaigns-selector";
import { Logo } from "@/components/primary-navigation/shared/logo";
import { NavItemsRow } from "@/components/primary-navigation/v3/nav-bar/nav-items-row";
import {
  NAV_V3_ITEMS_PRIMARY,
  NAV_V3_ITEMS,
  NAV_GROUP_NAMES,
} from "@/components/primary-navigation/v3/constants";
import Router from "@/helpers/router";
import { useOnboarding } from "@/hooks/use-onboarding";
import { MobileDropdownMenu } from "@/components/primary-navigation/v3/nav-bar/mobile-dropdown-menu";
import { NotificationsButton } from "@/components/primary-navigation/shared/notifications-button";
import { PrimaryNavigationItemCollapsableWithIconI } from "@/components/primary-navigation/v3/interfaces";
import { MessagesButton } from "../../shared/messages-button";
import { NavDropdown } from "./nav-items-row/components/nav-dropdown";
import { NavButtonPrimary } from "./nav-items-row/components/nav-button-primary";
import { isUserApprovedToCall } from "@/helpers/campaign";
import { useGlobalContext } from "@/hooks/use-global-context";
import { PRIMARY_NAVIGATION_V3_CONTAINER_ID } from "@/constants/element-ids";
import { TodayPerformanceButton } from "@/components/primary-navigation/shared/today-performance-button";

/**
 * @returns the black navbar that goes along the top of the app UI.
 */
export const PrimaryNavigationV3NavBar = () => {
  const { campaigns } = useGlobalContext();
  const { step, isOnboarded } = useOnboarding();

  const isApprovedToCall = useMemo(
    () => campaigns?.some((c) => isUserApprovedToCall(c.campaign_user_status)),
    [campaigns]
  );

  const ONBOARDING_NAV_ITEM: PrimaryNavigationItemCollapsableWithIconI =
    useMemo(
      () => ({
        text: (
          <div className="flex items-center justify-between">
            <span className="mr-7">Getting Started</span>
            <span className="ae-typography-detail1 badge rounded-[4px] border-none bg-[#F88167] text-white">
              {step} of 2
            </span>
          </div>
        ),
        icon: <StarIcon className="w-4" />,
        href: Router.onboarding(),
        isCollapsed: true,
        disabled: false,
      }),
      [step, isOnboarded]
    );

  const settingsNavItems: PrimaryNavigationItemCollapsableWithIconI[] =
    useMemo(() => {
      if (!isOnboarded) {
        return [NAV_V3_ITEMS[NAV_GROUP_NAMES.ONBOARDING]];
      }

      return [NAV_V3_ITEMS[NAV_GROUP_NAMES.SETTINGS]];
    }, [step, isOnboarded, ONBOARDING_NAV_ITEM]);

  return (
    <div
      id={PRIMARY_NAVIGATION_V3_CONTAINER_ID}
      className={clsxMerge(
        "navbar z-30 justify-between bg-black p-0",
        "w-screen min-w-[800px]"
      )}
    >
      <div className="navbar-start h-full">
        <div className="shrink-0 pl-10">
          <Logo variation="small_white" width="16px" />
        </div>

        {isOnboarded ? (
          <>
            <PrimaryNavCampaignsSelector className="px-7" />

            <NavButtonPrimary
              navItem={NAV_V3_ITEMS[NAV_GROUP_NAMES.HOME]}
              containerClassName="mr-1 xl:mr-2"
            />

            <NavDropdown
              navItem={NAV_V3_ITEMS[NAV_GROUP_NAMES.LEADS]}
              dropdownClassName="mr-1 xl:mr-2"
              disabled={!isApprovedToCall}
            />

            <NavItemsRow data={NAV_V3_ITEMS_PRIMARY} hideOnResponsive />
          </>
        ) : (
          <NavItemsRow data={[ONBOARDING_NAV_ITEM]} className="ml-[50px]" />
        )}
      </div>

      <div className="navbar-end sticky right-0 z-20 h-full w-fit">
        {isOnboarded && (
          <div className="flex h-full items-center gap-1 xl:gap-2">
            <TodayPerformanceButton />
            <NotificationsButton />
            <MessagesButton />
            <NavButtonPrimary
              navItem={NAV_V3_ITEMS[NAV_GROUP_NAMES.SALES_FLOOR]}
              isBtnIconOnly
            />
          </div>
        )}

        <NavItemsRow
          data={settingsNavItems}
          className="xl:mx-1"
          dropdownClassName="dropdown-end"
        />

        {/* VISIBLE ONLY ON WINDOW < 1024px WIDTH */}
        {isOnboarded && (
          <MobileDropdownMenu
            data={[
              ...NAV_V3_ITEMS_PRIMARY,
              NAV_V3_ITEMS[NAV_GROUP_NAMES.SETTINGS],
            ]}
          />
        )}
      </div>
    </div>
  );
};
