import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

import { ConnectedLabel } from "./connected";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import { DIALER_CALL_STATUS } from "@/constants/dialer";
import { DialingLabel } from "./dialing";

dayjs.extend(duration);

export const DialerConnectionLabel = ({
  className,
}: {
  className?: string;
}) => {
  const callStatus = useDialerCallStatus();
  const { startAt } = useInCallContext();

  const isConnected = callStatus === DIALER_CALL_STATUS.DURING && !!startAt;
  const isDialing = callStatus === DIALER_CALL_STATUS.DURING && !startAt;

  return (
    <>
      {isConnected && <ConnectedLabel className={className} />}
      {isDialing && <DialingLabel className={className} />}
    </>
  );
};
