import { FC, useMemo } from "react";
import {
  CalendarIcon,
  EnvelopeOpenIcon,
  PhoneArrowDownLeftIcon,
  PhoneXMarkIcon,
  StarIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

import { ListPillI } from "@/api/routes/list";
import { clsxMerge, getS3AssetPath } from "shared/lib/helpers";
import { PIPELINE_SYSTEM_LISTS } from "@/modules/pipeline/open/constants";
import { ACCOUNT_LIST_TYPES } from "@/modules/pipeline/constants";

interface ListActivitiesItemI extends ListPillI {
  isActive?: boolean;
  isExclusive?: boolean;
  onClick?: () => void;
}

// Fixed, pre-defined icons only for lists generated/maintained by the system
export const LISTS_ICON_MAP = {
  [PIPELINE_SYSTEM_LISTS.BOOKED_MEETINGS]: <CalendarIcon className="w-4" />,
  [PIPELINE_SYSTEM_LISTS.CALLBACKS]: <PhoneArrowDownLeftIcon className="w-4" />,
  [PIPELINE_SYSTEM_LISTS.EMAIL_ACTIONS]: <EnvelopeOpenIcon className="w-4" />,
  [PIPELINE_SYSTEM_LISTS.FEEDBACK_RECEIVED]: <StarIcon className="w-4" />,
  [PIPELINE_SYSTEM_LISTS.MISSED_CALLS]: <PhoneXMarkIcon className="w-4" />,
};

export const getListIcon = ({
  list_type: listType,
  isExclusive,
}: Pick<ListActivitiesItemI, "isExclusive" | "list_type">) => {
  if (isExclusive) {
    return (
      <img
        alt="Dedicated List"
        src={getS3AssetPath("platform/icons/award-icon.webp")}
        className="w-[18px] min-w-[18px]"
      />
    );
  }

  if (listType in LISTS_ICON_MAP) {
    return LISTS_ICON_MAP[listType as keyof typeof LISTS_ICON_MAP];
  }

  return <UserIcon className="w-4" />;
};

export const ListActivitiesItem: FC<ListActivitiesItemI> = ({
  name: _name,
  list_type,
  isExclusive,
  isActive,
  onClick = () => {},
}) => {
  const name = useMemo(
    () => (list_type === ACCOUNT_LIST_TYPES.CALLBACKS ? "Nurture" : _name),
    [list_type, _name]
  );

  return (
    <div
      role="button"
      className={clsxMerge("w-full rounded-lg border-2", {
        "border-[#4474E3]": isActive,
        "border-transparent bg-gradient-to-r from-[#4C6192] via-[#7E46CA] to-[#CB36AD]":
          isExclusive,
      })}
      onClick={onClick}
    >
      <div
        title={name}
        className={clsxMerge(
          "relative flex items-center gap-2",
          "rounded-md bg-white p-2",
          isExclusive ? "pr-0" : "pr-6",
          {
            "bg-[#E8F3FF]": isActive,
            "hover:bg-[rgba(247,247,247)]": !isActive,
          }
        )}
      >
        <div className="text-[#F88167]">
          {getListIcon({ list_type, isExclusive })}
        </div>

        <p
          className={clsxMerge("b-typography-detail2 mr-2 truncate", {
            "bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 bg-clip-text text-transparent":
              isExclusive,
          })}
        >
          {name}
        </p>
      </div>
    </div>
  );
};
