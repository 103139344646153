import { useRef, useState, FC } from "react";
import toast from "react-hot-toast";
import { FormikProps } from "formik";

import { AccountI } from "@/interfaces/accounts";
import { CampaignI } from "@/interfaces/campaign";
import { checkIfClient } from "shared/lib/helpers";
import NewLeadForm, { CreateNewLeadFormI } from "./new-lead-form";
import { handleErrorMessage } from "@/helpers/error";
import Modal, { ModalChildrenFnPropsI } from "shared/ui/modal";
import { useApiClient } from "@/context/api-client";

interface CreateNewLeadModalPropsI {
  modalId?: string;
  account?: AccountI;
  campaign?: CampaignI;
  handleNewLeadSubmitAsBacklog?: (data: CreateNewLeadFormI) => Promise<boolean>;
  onSuccess?: () => void;
}

export const CREATE_NEW_LEAD_MODAL_ID = "create-new-lead-modal";

const CreateNewLeadModalContent: FC<
  ModalChildrenFnPropsI & CreateNewLeadModalPropsI
> = ({
  handleClose,
  campaign,
  account,
  handleNewLeadSubmitAsBacklog,
  onSuccess,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const formikRef = useRef<FormikProps<CreateNewLeadFormI>>(null);
  const apiClient = useApiClient();

  const submitNewLeadToAccountAsBacklogDefault = async (
    data: CreateNewLeadFormI
  ) => {
    if (campaign?.id && account?.id) {
      const resp = await apiClient
        .addLeadToAccount(campaign.id, account.id, {
          first_name: data.first_name,
          last_name: data.last_name,
          title: data.title,
          phone: data.phone,
          mobile: data.mobile,
          email: data.email,
          linkedin_url: data.linkedin_url,
        })
        .catch((e) => e);

      if (resp.status === 200) {
        toast.success("Success");
        return true;
      } else {
        handleErrorMessage(
          resp?.response?.data?.error_code as number,
          "Failed to add new lead. Please contact Glencoco."
        );
      }
    }

    return false;
  };

  const onFormSubmitHandler = async (data: CreateNewLeadFormI) => {
    setIsDisabled(true);

    const handleLeadCreation = handleNewLeadSubmitAsBacklog
      ? handleNewLeadSubmitAsBacklog
      : submitNewLeadToAccountAsBacklogDefault;

    const isSubmitSuccess = await handleLeadCreation(data);

    if (isSubmitSuccess) {
      onSuccess?.();
      close();
    }

    setIsDisabled(false);
  };

  const close = () => {
    if (checkIfClient()) {
      formikRef.current?.resetForm();
      handleClose();
    }
  };

  return (
    <div className="relative min-w-[520px] overflow-hidden rounded-xl">
      <NewLeadForm
        account={account}
        campaign={campaign}
        onSubmit={onFormSubmitHandler}
        onCancel={close}
        loading={isDisabled}
      />
    </div>
  );
};

const CreateNewLeadModal: FC<CreateNewLeadModalPropsI> = (props) => (
  <Modal
    id={CREATE_NEW_LEAD_MODAL_ID}
    title="Create New Lead"
    description="Add a new lead to this account"
  >
    {(modalProps) => <CreateNewLeadModalContent {...props} {...modalProps} />}
  </Modal>
);

export default CreateNewLeadModal;
