import { FC } from "react";
import clsx from "clsx";

import RenderHTML from "shared/ui/render-html";
import { EmailMetadataI } from "shared/lib/interfaces/account";
import { clsxMerge } from "shared/lib/helpers";

interface HistoryCardEventDateBasePropsI {
  emailMetadata: EmailMetadataI;
  isSenderHighlighted?: boolean;
  isReceiverHighlighted?: boolean;
}

const HIGHLIGHTED_EMAIL_CLASSNAME = "text-white bg-black rounded px-2 py-1";

export const HistoryCardEmailDetails: FC<HistoryCardEventDateBasePropsI> = ({
  emailMetadata,
  isSenderHighlighted = false,
  isReceiverHighlighted = false,
}) => (
  <div className="flex w-full flex-col gap-5">
    <div className="@xl:flex hidden flex-col gap-1">
      <div className="flex items-center">
        <span className="b-typography-h5 w-[70px]">From:</span>
        <span
          className={clsx(
            "brand-typography-body3",
            isSenderHighlighted && HIGHLIGHTED_EMAIL_CLASSNAME
          )}
        >
          {emailMetadata.from}
        </span>
      </div>

      <div className="flex">
        <span className="b-typography-h5 w-[70px]">To:</span>

        <div className="flex flex-wrap gap-2">
          {emailMetadata.to.map((to, idx) => (
            <span
              key={idx}
              className={clsx(
                "brand-typography-body3",
                isReceiverHighlighted && HIGHLIGHTED_EMAIL_CLASSNAME
              )}
            >
              {to}
            </span>
          ))}
        </div>
      </div>

      <div className="b-typography-h5 flex">
        <span className="w-[70px]">Subject:</span>
        <span>{emailMetadata.subject}</span>
      </div>
    </div>

    <RenderHTML
      className={clsxMerge(
        "brand-typography-body6 whitespace-pre-line text-black/80",
        "rounded-lg border bg-[#F4F4F4] p-3",
        "@xl:rounded-none @xl:border-none @xl:bg-transparent @xl:p-0"
      )}
      html={emailMetadata.body}
    />
  </div>
);
