import { FC, useMemo } from "react";
import { Field, Form, Formik } from "formik";
import clsx from "clsx";
import * as Yup from "yup";

import TextFieldFactory from "shared/ui/user-input/text-field";
import TextAreaFactory from "shared/ui/user-input/text-area";

import { ValueOfObjectFields } from "shared/lib/interfaces/utils";
import { APII, glencocoClientAPI } from "@/api/glencoco";
import { MarkTodoDoneRequestI } from "@/api/routes/dialer";
import { useApi } from "shared/lib/hooks/use-api";
import Modal, { ModalChildrenFnPropsI, ModalPropsI } from "shared/ui/modal";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import { LeadCardV2MetadataI } from "@/modules/calling/cards/lead-card/v2";
import { getEmailsFromString, yupEmailListSchema } from "shared/lib/helpers";

import Calling from "@/helpers/calling";
import { useCatchupsContext } from "@/context/dialer-v2/catchups";

export type MarkDoneModeI = ValueOfObjectFields<typeof MARK_DONE_MODES>;

interface MarkDoneModalContentPropsI {
  mode: MarkDoneModeI;
}

interface MarkDoneFormValuesI {
  note: string;
  emailNote: string;
  emailsRaw: string;
}

export const MARK_DONE_MODES = {
  DEFAULT: "NONE",
  SEND_EMAIL: "SEND_FOLLOWUP_EMAIL",
  DISQUALIFY_LEAD: "DISQUALIFY_LEAD",
};

const MARK_DONE_MODAL_LABELS = {
  [MARK_DONE_MODES.DEFAULT]: {
    title: "Mark Completed",
    subtitle: "Optional: Add any notes about this lead",
    actionButtonLabel: "Mark Completed",
  },
  [MARK_DONE_MODES.SEND_EMAIL]: {
    title: "Mark Completed & Send Email",
    subtitle: "",
    actionButtonLabel: "Send",
  },
  [MARK_DONE_MODES.DISQUALIFY_LEAD]: {
    title: "Disqualify Lead",
    subtitle: "Add notes to disqualify this lead",
    actionButtonLabel: "Disqualify Lead",
  },
} as const;

const archiveWithEmailValidationSchema = Yup.object().shape({
  emailNote: Yup.string().required("Note is required"),
  emailsRaw: yupEmailListSchema,
});

const markDoneFetcher = (
  api: APII,
  todoId: string,
  request: MarkTodoDoneRequestI
) => api.markDone(todoId, request);

const Input = TextFieldFactory(Field);
const Textarea = TextAreaFactory(Field);

const MarkDoneModalContent: FC<
  MarkDoneModalContentPropsI & ModalChildrenFnPropsI
> = ({ mode, handleClose }) => {
  const isSendEmailMode = mode === MARK_DONE_MODES.SEND_EMAIL;

  const callingContext = useCallingContext();
  const inCallContext = useInCallContext();
  const { fetchCatchupTodos } = useCatchupsContext();
  const { metadata, contact } = inCallContext;

  const formInitialValues = useMemo(
    () => ({
      note: "",
      emailNote: "",
      emailsRaw: contact?.email || "",
    }),
    [contact?.email]
  );

  const { actionButtonLabel } = MARK_DONE_MODAL_LABELS[mode];

  const [, markDone] = useApi({
    apiFactory: glencocoClientAPI,
    fetcher: markDoneFetcher,
    shouldCallAutomatically: false,
    errorBuilder: () => "Failed to mark completed",
  });

  const handleSubmit = async ({
    note,
    emailNote,
    emailsRaw,
  }: MarkDoneFormValuesI) => {
    await markDone(
      (metadata as LeadCardV2MetadataI).todoId,
      isSendEmailMode
        ? {
            type: mode,
            email_request: {
              notes: emailNote,
              emails: getEmailsFromString(emailsRaw),
            },
          }
        : {
            type: mode,
            note,
          }
    );

    handleClose();
    Calling.exit({ context: callingContext, inCallContext });
    return fetchCatchupTodos();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formInitialValues}
      onSubmit={handleSubmit}
      validationSchema={
        isSendEmailMode ? archiveWithEmailValidationSchema : null
      }
    >
      {({ isSubmitting, errors, touched }) => (
        <Form className="flex flex-col">
          {isSendEmailMode ? (
            <>
              <Input
                name="emailsRaw"
                label="Send Followup Email(s) To (comma-separated):"
                placeholder="Enter email addresses for a followup email to be sent"
                errors={errors.emailsRaw}
                touched={touched.emailsRaw}
              />

              <Textarea
                name="emailNote"
                label="Share notes to the AE about what to put in the email"
                placeholder="Share any notes you have about what to put in the email"
                className="mb-4"
                errors={errors.emailNote}
                touched={touched.emailNote}
              />
            </>
          ) : (
            <Textarea
              name="note"
              label=""
              placeholder="Add notes"
              className="mb-4"
              errors={errors.note}
              touched={touched.note}
            />
          )}

          <div className="flex justify-end gap-2">
            <button type="button" className="btn-nofill" onClick={handleClose}>
              Cancel
            </button>

            <button
              type="submit"
              className={clsx("btn-ae-default w-[150px]", {
                disabled: isSubmitting,
              })}
            >
              {actionButtonLabel}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const MarkDoneGeneralModal: FC<
  Omit<ModalPropsI, "children"> & MarkDoneModalContentPropsI
> = ({ mode, ...modalProps }) => {
  const { title, subtitle } = MARK_DONE_MODAL_LABELS[mode];

  return (
    <Modal {...modalProps} title={title} description={subtitle}>
      {(modalContentProps) => (
        <MarkDoneModalContent mode={mode} {...modalContentProps} />
      )}
    </Modal>
  );
};

export const MARK_DONE_MODAL_ID = "mark-done-modal";
export const MarkDoneModal = () => (
  <MarkDoneGeneralModal
    id={MARK_DONE_MODAL_ID}
    mode={MARK_DONE_MODES.DEFAULT}
  />
);

export const MARK_DONE_SEND_EMAIL_MODAL_ID = "mark-done-send-email-modal";
export const MarkDoneSendEmailModal = () => (
  <MarkDoneGeneralModal
    id={MARK_DONE_SEND_EMAIL_MODAL_ID}
    mode={MARK_DONE_MODES.SEND_EMAIL}
  />
);

export const MARK_DONE_DISQUALIFY_LEAD_MODAL_ID = "mark-done-do-not-call-modal";
export const MarkDoneDisqualifyLeadModal = () => (
  <MarkDoneGeneralModal
    id={MARK_DONE_DISQUALIFY_LEAD_MODAL_ID}
    mode={MARK_DONE_MODES.DISQUALIFY_LEAD}
  />
);
