// External Libraries
import { Formik, Form, FormikErrors } from "formik";
import * as Yup from "yup";
import wait from "wait";
import {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Portal } from "react-portal";

// Shared/Utilities
import { clsxMerge } from "shared/lib/helpers";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
import { convertTime12to24, DayJs } from "shared/lib/helpers/date";
import {
  DISPOSITIONS_V3,
  NEXT_TOUCH_TIME_OPTIONS,
  PROSPECT_DISPOSITION_TYPES,
} from "shared/lib/constants/dispositions";
import { TIMEZONES } from "shared/lib/constants/timezones";

// Components
import { WIDGETS, widgets } from "@/components/shared/widgets";
import {
  DATE_TIME_CALENDAR_MODAL_ID,
  DateTimeCalendarModal,
} from "@/components/modals/date-time-calendar-modal";
import { DispositionConversationRow } from "./conversation-row";
import { DispositionNoteRow } from "./note-row";
import { DispositionDecisionMakerRow } from "./decision-maker-row";
import { DispositionRow } from "./disposition-row";
import { DispositionActionsRow } from "./actions-row";

// Hooks
import { useDispositionSubmission } from "./use-disposition-submission-hook";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";

// Context and Constants
import { useADPrimaryContentContext } from "@/modules/pipeline/account-details/primary-content/context";
import {
  DISPOSITION_CONTROL_KEYS,
  MIN_CONVERSATION_TIME_SECONDS,
} from "./constants";

// Interfaces
import { FormValidationSchemaI } from "./interface";

const FormDefaultValidationSchema = Yup.object().shape({});

const FormDispositionValidationSchema = Yup.object().shape({
  disposition_type: Yup.string().required("You must select an option"),
  disposition: Yup.string().when("disposition_type", (disposition_type) => {
    return ![
      PROSPECT_DISPOSITION_TYPES.BAD_DATA,
      PROSPECT_DISPOSITION_TYPES.NOT_RELEVANT,
    ].includes(disposition_type)
      ? Yup.string().required("Disposition is required to send feedback")
      : Yup.string().optional();
  }),
});

/**
 * NOTE
 *
 * The primary purpose of this component is to handle
 * default dropdown states based on selected values
 */
const DropdownsValueSetter = ({
  isDisposition,
  values,
  setValues,
  setCustomNextTouchTime,
}: {
  isDisposition?: boolean;
  values: FormValidationSchemaI;
  setValues: (
    values: SetStateAction<FormValidationSchemaI>,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<FormValidationSchemaI>>;
  setCustomNextTouchTime: Dispatch<SetStateAction<string | undefined>>;
}) => {
  useEffect(() => {
    if (!isDisposition) {
      setValues({
        ...values,
        disposition: undefined,
        disposition_type: undefined,
        next_touch_time: undefined,
      });
    }
  }, [isDisposition]);

  useEffect(() => {
    switch (values.disposition_type) {
      case [
        PROSPECT_DISPOSITION_TYPES.BAD_DATA,
        PROSPECT_DISPOSITION_TYPES.NOT_RELEVANT,
      ].includes(values.disposition_type as string)
        ? values.disposition_type
        : "undefined0":
        setValues({
          ...values,
          disposition: undefined,
          next_touch_time: undefined,
        });
        break;
    }
  }, [values.disposition_type]);

  useEffect(() => {
    switch (values.disposition) {
      /**
       * Hacky way to squize switch case scenario into
       * handling specific similar condition
       */
      case [
        DISPOSITIONS_V3.DO_NOT_CALL,
        DISPOSITIONS_V3.QC_NOT_MET_FOR_ACCOUNT,
        DISPOSITIONS_V3.OTHER_DO_NOT_FOLLOW_UP,
      ].includes(values.disposition as string)
        ? values.disposition
        : "undefined0":
        setValues({
          ...values,
          next_touch_time: undefined,
        });
        break;

      case [
        DISPOSITIONS_V3.NOT_QUALIFIED_NEED as string,
        DISPOSITIONS_V3.NOT_QUALIFIED_TIMING,
        DISPOSITIONS_V3.PITCH_REJECTED,
        DISPOSITIONS_V3.INTRO_REJECTED,
      ].includes(values.disposition as string)
        ? values.disposition
        : "undefined1":
        setValues({
          ...values,
          next_touch_time: NEXT_TOUCH_TIME_OPTIONS.DAYS_45,
        });
        break;

      case [
        DISPOSITIONS_V3.INTERESTED as string,
        DISPOSITIONS_V3.OTHER_FOLLOW_UP,
      ].includes(values.disposition as string)
        ? values.disposition
        : "undefined2":
        setValues({
          ...values,
          next_touch_time: NEXT_TOUCH_TIME_OPTIONS.DAYS_3,
        });
        break;
    }
  }, [values.disposition]);

  useEffect(() => {
    if (values.next_touch_time === NEXT_TOUCH_TIME_OPTIONS.CUSTOM) {
      modalHelpers.open(DATE_TIME_CALENDAR_MODAL_ID);
    } else {
      setCustomNextTouchTime?.(undefined);
    }
  }, [values.next_touch_time]);

  const handleSelectCustomDateTime = (
    date: string,
    time: string,
    timezone: string
  ) => {
    const { hours, minutes } = convertTime12to24(time);
    const customNextTouchTime = DayJs(date)
      .hour(hours)
      .minute(minutes)
      .tz(TIMEZONES.find((tz) => tz.fullZoneName === timezone)?.tzCode, true)
      .toISOString();

    setCustomNextTouchTime?.(customNextTouchTime);

    modalHelpers.close(DATE_TIME_CALENDAR_MODAL_ID);
  };

  /**
   * We want to switch value to NONE if user select CUSTOM time
   * then canceled the modal
   */
  const handleOnClose = async () => {
    await wait(1000);

    setCustomNextTouchTime?.((customNextTouchTime) => {
      if (!customNextTouchTime) {
        setValues({ ...values, next_touch_time: NEXT_TOUCH_TIME_OPTIONS.NONE });
      }

      return customNextTouchTime;
    });
  };

  return (
    <>
      {values.next_touch_time === NEXT_TOUCH_TIME_OPTIONS.CUSTOM && (
        <Portal>
          <DateTimeCalendarModal
            title="Set Next Touch Time"
            descritpion="Specify date and time when to remind you to call back"
            onSuccess={handleSelectCustomDateTime}
            onClose={handleOnClose}
          />
        </Portal>
      )}
    </>
  );
};

export const DispositionSection = ({ className }: { className?: string }) => {
  const { setCall } = useCallingContext();
  const { clear: clearInCallContext, startAt, endAt } = useInCallContext();
  const { note, setNote: setSharedNote } = useADPrimaryContentContext();

  /**
   * Values used ouside formik values flow
   */
  const [isDispoConversation, setIsDispoConversation] = useState<boolean>();
  const [customNextTouchTime, setCustomNextTouchTime] = useState<string>();

  useEffect(() => {
    if (startAt && endAt) {
      if (
        Math.abs(DayJs(startAt).diff(endAt, "seconds")) >
        MIN_CONVERSATION_TIME_SECONDS
      ) {
        setIsDispoConversation(true);
      }
    }
  }, [startAt, endAt]);

  const formInitialValues: FormValidationSchemaI = useMemo(
    () => ({
      disposition: undefined,
      note: note || "",
      next_touch_time: undefined,
      disposition_type: undefined,
      controlKey: undefined,
    }),
    [note]
  );

  const DispositionSubmissionHookParams = useMemo(
    () => ({
      isDispoConversation,
      nextTouchTimeCustom: customNextTouchTime,
      onSuccess: (data: FormValidationSchemaI) => {
        setCall(undefined);
        setSharedNote("");
        clearInCallContext();

        if (data?.controlKey === DISPOSITION_CONTROL_KEYS.DIALING_LIST) {
          widgets.close({ id: WIDGETS.MAXED_DIALER });
        }
      },
    }),
    [isDispoConversation, customNextTouchTime]
  );
  const { handleDipositionSubmission } = useDispositionSubmission(
    DispositionSubmissionHookParams
  );

  return (
    <section
      style={
        {
          "--pulse-alt-start-color": "rgba(68,116,227,.7)",
          "--pulse-alt-end-color": "rgba(0,0,0,0)",
        } as CSSProperties
      }
      className={clsxMerge(
        "mt-3 w-full animate-fadein rounded-[10px] border border-[#4474E3] bg-white p-5",

        "animate-pulse-alt",
        className
      )}
    >
      <h6 className="mb-1 font-semibold text-[#4474E3] typography-header-8-bold">
        What happened on the call?
      </h6>

      <DispositionConversationRow
        className="mb-2"
        value={isDispoConversation}
        onChange={(value) => {
          setIsDispoConversation(value);
        }}
      />

      <Formik
        enableReinitialize
        validationSchema={
          isDispoConversation
            ? FormDispositionValidationSchema
            : FormDefaultValidationSchema
        }
        initialValues={formInitialValues}
        onSubmit={handleDipositionSubmission}
      >
        {({
          errors,
          touched,
          isValid,
          isSubmitting,
          values,
          setValues,
          handleSubmit,
        }) => (
          <Form className="flex grow flex-col">
            <DropdownsValueSetter
              isDisposition={isDispoConversation}
              values={values}
              setValues={setValues}
              setCustomNextTouchTime={setCustomNextTouchTime}
            />

            {typeof isDispoConversation === "boolean" &&
              isDispoConversation && (
                <>
                  <DispositionDecisionMakerRow
                    name="disposition_type"
                    errors={errors.disposition_type}
                    touched={touched.disposition_type}
                    className="mb-2"
                  />

                  {!!values.disposition_type &&
                    ![
                      PROSPECT_DISPOSITION_TYPES.BAD_DATA,
                      PROSPECT_DISPOSITION_TYPES.NOT_RELEVANT,
                    ].includes(values.disposition_type as string) && (
                      <DispositionRow
                        names={["disposition", "next_touch_time"]}
                        disposition={values.disposition}
                        errors={errors}
                        touched={touched}
                        customNextTouchTime={customNextTouchTime}
                        className="mb-6"
                      />
                    )}
                </>
              )}

            <DispositionNoteRow
              name={"note"}
              errors={errors.note}
              touched={touched.note}
            />

            <DispositionActionsRow
              values={values}
              setValues={setValues}
              isValid={typeof isDispoConversation === "boolean" && isValid}
              isSubmitting={isSubmitting}
              onSubmit={handleSubmit}
            />
          </Form>
        )}
      </Formik>
    </section>
  );
};
