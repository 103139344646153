import { FC, Fragment } from "react";

import UICheckbox from "shared/ui/ae-user-input/checkbox";
import { SelectedQualificationCriteriaQuestionI } from "shared/lib/interfaces/campaign";

interface QualificationCriteriaSummaryPropsI {
  className?: string;
  qualificationCriteria?: Array<SelectedQualificationCriteriaQuestionI>;
}

const Checkbox = UICheckbox();

export const AccountHistoryCardQualificationCriteria: FC<
  QualificationCriteriaSummaryPropsI
> = ({ className, qualificationCriteria }) =>
  qualificationCriteria ? (
    <section className={className}>
      <div className="@xl:grid hidden grid-cols-[1fr_100px]">
        <div className="brand-typography-h6 mb-4 text-black">
          Qualification Criteria From Caller
        </div>
        <div className="brand-typography-h6 mb-4 text-center text-black">
          AE Agreed
        </div>

        {qualificationCriteria?.map((qc, i) => (
          <Fragment key={i}>
            <div className="flex items-center">
              <Checkbox
                name={`checkbox-${i}`}
                inputProps={{
                  checked: qc.user_is_checked,
                  disabled: true,
                }}
              />

              <label className="ae-typography-body2 text-base-content/60">
                {qc.question}
              </label>
            </div>

            <div className="ae-typography-body2 text-center">
              {qc.is_checked ? "Yes" : "No"}
            </div>
          </Fragment>
        ))}
      </div>
    </section>
  ) : null;
