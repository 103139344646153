import { DIALER_STATES } from "@/constants/dialer";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import { useMemo } from "react";
import { DIALER_CALL_STATUS } from "@/constants/dialer";

export const useDialerCallStatus = () => {
  const { callingState } = useCallingContext();
  const { startAt, endAt } = useInCallContext();

  return useMemo(() => {
    if (DIALER_STATES.CALL === callingState) return DIALER_CALL_STATUS.DURING;
    if (DIALER_STATES.CALL !== callingState && startAt && endAt)
      return DIALER_CALL_STATUS.AFTER;

    return DIALER_CALL_STATUS.BEFORE;
  }, [callingState, startAt, endAt]);
};
