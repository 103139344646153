import React, { FC, useCallback } from "react";
import throttle from "lodash/throttle";
import {
  useInfiniteScrollWithObserver,
  INFINITE_SCROLL_WITH_OBSERVER_INDICATOR,
} from "shared/lib/hooks/infinite-scrolling/use-infinite-scroll-with-observer";

interface InfiniteScrollWithObserverPropsI {
  isLoading: boolean;
  hasError?: boolean;
  isMore: boolean;
  isEmpty?: boolean;
  isDefaultMessageHidden?: boolean;

  endOfListMessage?: string;
  onMore?: () => void;
  onReload?: () => void;
  children?: any;
  loadMoreThrottleInterval?: number;
}

const DEFAULT_END_OF_LIST_MESSAGE =
  "You've reached the bottom, it's only up from here!";

const InfiniteScrollStates: FC<InfiniteScrollWithObserverPropsI> = ({
  isLoading,
  hasError,
  isMore,
  isEmpty,
  isDefaultMessageHidden,
  endOfListMessage,
  onReload,
}) => {
  if (hasError) {
    return (
      <div className="flex w-full flex-col items-center justify-center gap-2">
        <span className="text-black/80">
          An error occurred, please try again
        </span>

        <button className="btn-ae-default" onClick={onReload}>
          Reload the table
        </button>
      </div>
    );
  }

  if (isEmpty) {
    return <p className="py-6 text-black/40">No results found</p>;
  }

  if (!isMore && !isDefaultMessageHidden) {
    return <p className="py-6 text-black/40">{endOfListMessage}</p>;
  }

  if (isLoading) {
    return (
      <button className="btn loading btn-link cursor-default bg-transparent text-[#0F35FF]" />
    );
  }

  return null;
};

const InfiniteScrollWithObserver: FC<InfiniteScrollWithObserverPropsI> = ({
  children,
  isLoading,
  isMore,
  hasError = false,
  isEmpty = false,
  endOfListMessage = DEFAULT_END_OF_LIST_MESSAGE,
  isDefaultMessageHidden,
  onMore = () => {},
  onReload = () => {},
  loadMoreThrottleInterval = 400,
}) => {
  const fetchMore = useCallback(throttle(onMore, loadMoreThrottleInterval), [
    onMore,
    loadMoreThrottleInterval,
  ]);

  useInfiniteScrollWithObserver({
    isLoading,
    hasMore: isMore,
    fetchMore,
  });

  return (
    <>
      {children}

      <div id={INFINITE_SCROLL_WITH_OBSERVER_INDICATOR} className="h-1" />

      <div className="brand-typography-body3 flex w-full justify-center">
        <InfiniteScrollStates
          isLoading={isLoading}
          isMore={isMore}
          isEmpty={isEmpty}
          hasError={hasError}
          isDefaultMessageHidden={isDefaultMessageHidden}
          endOfListMessage={endOfListMessage}
          onReload={onReload}
        />
      </div>
    </>
  );
};

export default InfiniteScrollWithObserver;
