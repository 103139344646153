import { ChangeEvent, FC, useMemo, useState } from "react";
import clsx from "clsx";
import toast from "react-hot-toast";

import { SidebarSlide } from "shared/ui/sidebarV2/components/slide";
import { SIDEBAR_SIZE } from "shared/ui/sidebarV2/lib/constants";
import { useFetchLists } from "@/modules/pipeline/shared/upload-leads-sidebar/queries";
import { TextArea, TextField } from "shared/ui/ae-user-input";
import { AddUserToListSidebarListsDropdown } from "./lists-dropdown";
import {
  useBulkAddContactsToList,
  useCreateList,
} from "@/modules/pipeline/open/list/lists/queries";
import { clsxMerge } from "shared/lib/helpers";

import { AddUserToListsLSidebarLeadsDropdown } from "./leads-dropdown";
import { CampaignI } from "@/interfaces/campaign";
import { ContactDetailI } from "shared/lib/interfaces/account";
import { useEffectOnce } from "shared/lib/hooks/use-effect-once";

const EnhancedTextField = TextField();
const EnhancedTextArea = TextArea();

interface AddUserToListSlidePropsI {
  campaign?: CampaignI;
  contacts?: ContactDetailI[];
  onAccountUpdate?: () => void;
  onClose?: () => void;
}

export const AddUserToListSlide: FC<AddUserToListSlidePropsI> = ({
  campaign,
  contacts,
  onAccountUpdate = () => {},
  onClose = () => {},
}) => {
  const { data: listsData } = useFetchLists();
  const { mutateAsync: bulkAddContactsToList } = useBulkAddContactsToList();
  const { mutateAsync: createList } = useCreateList();

  const [isVisible, setIsVisible] = useState(false);

  const [selectedContactId, setSelectedContactId] = useState<string>();
  const [selectedListId, setSelectedListId] = useState<string>();
  const [newListName, setNewListName] = useState<string>();
  const [newListDescription, setNewListDescription] = useState<string>();

  const userEnabledLists = useMemo(
    () => listsData?.data.user_lists.filter((item) => !item?.is_default_list),
    [listsData]
  );

  const addUserToExistingList = async (listId: string) => {
    if (selectedContactId && campaign?.id && listId) {
      const resp = await bulkAddContactsToList({
        contacts: [{ contact_id: selectedContactId, campaign_id: campaign.id }],
        list_ids: [listId],
      });

      if (resp.status === 200) {
        return true;
      }
    }

    return false;
  };

  const addUserToNewList = async () => {
    if (!newListName) {
      return false;
    }

    const createListResp = await createList({
      name: newListName,
      description: newListDescription,
    });

    if (createListResp.status === 200) {
      const success = await addUserToExistingList(createListResp.data.list_id);

      if (success) {
        return true;
      }
    } else {
      toast.error("Failed to create list, please try again.");
    }

    return false;
  };

  const handleSubmitForm = async () => {
    let isSuccess = false;
    if (!selectedContactId) {
      return false;
    }

    if (selectedListId) {
      // using an existing list
      isSuccess = await addUserToExistingList(selectedListId);
    } else if (newListName) {
      // create new list first and then go to next step
      isSuccess = await addUserToNewList();
    }

    if (isSuccess) {
      onAccountUpdate();
      onClose();
    }
  };

  useEffectOnce(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  });

  return (
    <SidebarSlide
      size={SIDEBAR_SIZE.MD}
      title="Add to list or Create list"
      headerClassName="bg-white b-typography-h4 py-3"
      onClose={onClose}
    >
      <article
        className={clsx("relative transition-opacity duration-300", {
          "opacity-0": !isVisible,
          "opacity-100": isVisible,
        })}
      >
        <section className="mb-10">
          <label className="b-typography-h6 mb-1.5 block">
            Select the contact name
          </label>

          <AddUserToListsLSidebarLeadsDropdown
            items={contacts}
            onUpdate={(contact) => setSelectedContactId(contact?.contact_id)}
          />
        </section>

        <section className="mb-2">
          <label className="b-typography-h6 mb-1.5 block">
            Select list to add lead to
          </label>

          <AddUserToListSidebarListsDropdown
            lists={userEnabledLists}
            onUpdate={(list) => setSelectedListId(list?.id)}
            disabled={!userEnabledLists || !userEnabledLists.length}
          />

          {!userEnabledLists ||
            (!userEnabledLists.length && (
              <p className="b-typography-detail2 p-2 opacity-40">
                You have not yet created any custom lists to import leads to.
                Please create one below.
              </p>
            ))}
        </section>

        <div className="divider my-6">or</div>

        <section>
          <EnhancedTextField
            name="name"
            placeholder="Add list name"
            label="List name"
            labelClassName="text-black b-typography-h6"
            inputClassName="bg-white"
            inputProps={{
              onChange: (e: ChangeEvent<HTMLInputElement>) =>
                setNewListName(e.target.value),
              disabled: !!selectedListId,
            }}
          />

          <EnhancedTextArea
            name="description"
            placeholder="Describe the purpose of this custom list. For example - This is list for leads with verified titles."
            label="Description"
            labelClassName="text-black b-typography-h6"
            inputClassName={clsxMerge("max-h-[50vh] min-h-[220px] bg-white")}
            inputProps={{
              onChange: (e: ChangeEvent<HTMLInputElement>) =>
                setNewListDescription(e.target.value),
              disabled: !!selectedListId,
            }}
          />
        </section>

        <section className="z-100 fixed bottom-0 left-0 flex w-full justify-between bg-[#F2F2F2] px-6 py-4">
          <button className="btn-transparent" onClick={onClose}>
            Cancel
          </button>

          <button
            className="btn-ae-default w-[152px]"
            onClick={handleSubmitForm}
            disabled={!selectedContactId || (!selectedListId && !newListName)}
          >
            Save
          </button>
        </section>
      </article>
    </SidebarSlide>
  );
};
