import { FC, useMemo } from "react";
import { EnvelopeIcon } from "@heroicons/react/24/outline";

import {
  AccountHistoryCard,
  AccountHistoryCardBasePropsI,
} from "shared/ui/activity-log/cards-v2/base";
import { HistoryCardActivityType } from "shared/ui/activity-log/cards-v2/base/activity-type";
import { HistoryCardContactInfo } from "shared/ui/activity-log/cards-v2/base/contact-info";
import { HistoryCardEmailDetails } from "shared/ui/activity-log/cards-v2/base/email-details";
import { HistoryCardEmailOpens } from "shared/ui/activity-log/cards-v2/base/email-opens";

const ACTIVITY_TYPE_CONFIG_SENT = {
  icon: EnvelopeIcon,
  title: "Email sent",
};

const ACTIVITY_TYPE_CONFIG_OPENED = {
  icon: EnvelopeIcon,
  title: "Email opened",
  isHot: true,
};

const EMAIL_OPENS_TO_CONSIDER_OPEN = 2;

// This component covers both "Email sent" and "Email opened" activities
const HistoryCardEmailSentV2: FC<AccountHistoryCardBasePropsI> = ({
  activity,
}) => {
  const emailMetadata = activity.email_metadata;

  const activityTypeConfig = useMemo(() => {
    if (
      emailMetadata?.email_opens &&
      emailMetadata.email_opens.length >= EMAIL_OPENS_TO_CONSIDER_OPEN
    ) {
      return ACTIVITY_TYPE_CONFIG_OPENED;
    }

    return ACTIVITY_TYPE_CONFIG_SENT;
  }, [emailMetadata]);

  return (
    <AccountHistoryCard createdAt={activity.created_at}>
      <div className="flex flex-col gap-4">
        <HistoryCardActivityType config={activityTypeConfig} />

        {emailMetadata && (
          <HistoryCardEmailOpens emailOpens={emailMetadata.email_opens} />
        )}

        <HistoryCardContactInfo contact={activity.contact} />
      </div>

      {emailMetadata && (
        <div className="flex flex-col gap-4">
          <HistoryCardEmailDetails
            isReceiverHighlighted
            emailMetadata={emailMetadata}
          />
        </div>
      )}
    </AccountHistoryCard>
  );
};

export default HistoryCardEmailSentV2;
