import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";

import {
  AccountExecutiveI,
  AccountHistoryItemI,
  AccountI,
  AccountUserDispositionI,
  AccountUserListI,
  ContactDetailI,
} from "@/interfaces/accounts";
import { CampaignI } from "@/interfaces/campaign";
import { useInfinitePagination } from "shared/lib/hooks/index";
import { APII, glencocoClientAPI } from "@/api/glencoco";
import { UseInfinitePaginationReturnI } from "shared/lib/hooks/infinite-scrolling/use-infinite-pagination";
import { ACCOUNT_DETAILS_TABS } from "@/constants/account-details";
import { ValueOfObjectFields } from "shared/lib/interfaces/utils";
import {
  useFetchAccountDetails,
  UseFetchAccountDetailsApiI,
} from "@/api/routes/account/queries";

export interface AccountDetailsContextI {
  account?: AccountI;
  campaign?: CampaignI;
  contacts?: Array<ContactDetailI>;
  accountUserDisposition?: AccountUserDispositionI;

  accountHistoryData: UseInfinitePaginationReturnI<AccountHistoryItemI>;

  focusedContact: ContactDetailI | undefined;
  setFocusedContact: (contact?: ContactDetailI) => void;

  onAccountUpdate: () => void;

  accountExecutiveData?: AccountExecutiveI;
  accountUserLists?: AccountUserListI[];
  tab?: ValueOfObjectFields<typeof ACCOUNT_DETAILS_TABS>;
  setTab: (tab: ValueOfObjectFields<typeof ACCOUNT_DETAILS_TABS>) => void;

  accountDetails: UseFetchAccountDetailsApiI;
}

export interface AccountDetailsProviderI {
  account?: AccountI;
  campaign?: CampaignI;
  contacts?: Array<ContactDetailI>;
  accountUserDisposition?: AccountUserDispositionI;
  accountDetails?: any;
  accountHistory?: Array<AccountHistoryItemI>;
  onAccountUpdate: () => void;
  accountExecutiveData?: AccountExecutiveI;
  accountUserLists?: AccountUserListI[];
  tab?: ValueOfObjectFields<typeof ACCOUNT_DETAILS_TABS>;
}

const accountHistoryFetcher = (
  campaignId: string,
  accountId: string,
  nextToken: string | undefined
) => {
  const api = glencocoClientAPI();

  return api.getAccountHistoryV2(campaignId, accountId, nextToken);
};

const AccountDetailsContext = createContext<AccountDetailsContextI>({
  focusedContact: undefined,
  setFocusedContact: () => {},
  onAccountUpdate: () => {},
  setTab: () => {},
  accountHistoryData: {
    isLoading: false,
    isReachedEnd: false,
    data: [],
    reloadData: () => {},
    loadMore: () => {},
  },
  accountDetails: {} as UseFetchAccountDetailsApiI,
});

export const AccountDetailsProvider: FC<
  AccountDetailsProviderI & PropsWithChildren
> = ({
  campaign: campaignData,
  account: accountData,
  contacts: contactsData,
  accountUserDisposition: accountUserDispositionData,
  children,
  onAccountUpdate,
  accountExecutiveData,
  accountUserLists: accountUserListsData,
  tab: accountDetailsTab,
}) => {
  const [focusedContact, setFocusedContact] = useState<ContactDetailI>();

  const [campaign, setCampaign] = useState(campaignData);
  useEffect(() => setCampaign(campaignData), [campaignData]);

  const [account, setAccount] = useState(accountData);
  useEffect(() => setAccount(accountData), [accountData]);

  const accountDetailsApi = useFetchAccountDetails(campaign?.id, account?.id);

  const [contacts, setContacts] = useState(contactsData);
  useEffect(() => setContacts(contactsData), [contactsData]);

  const [accountExecutive, setAccountExecutive] =
    useState(accountExecutiveData);
  useEffect(
    () => setAccountExecutive(accountExecutive),
    [accountExecutiveData]
  );

  const [accountUserDisposition, setAccountUserDisposition] = useState(
    accountUserDispositionData
  );
  useEffect(
    () => setAccountUserDisposition(accountUserDispositionData),
    [accountUserDispositionData]
  );

  const [accountUserLists, setAccountUserLists] =
    useState(accountUserListsData);
  useEffect(
    () => setAccountUserLists(accountUserListsData),
    [accountUserListsData]
  );

  const accountHistoryData = useInfinitePagination<AccountHistoryItemI, APII>({
    isInitiallyLoading: true,
    apiFactory: glencocoClientAPI,
    collectionKeyInResponse: "activity_logs",
    fetchMore: (nextToken) =>
      accountHistoryFetcher(
        campaign?.id as string,
        account?.id as string,
        nextToken
      ),
    errorMessage: "Failed to load account history. Please contact Glencoco.",
  });

  const [tab, setTab] = useState<
    ValueOfObjectFields<typeof ACCOUNT_DETAILS_TABS>
  >(accountDetailsTab || ACCOUNT_DETAILS_TABS.ACCOUNT_HISTORY);

  useEffect(() => {
    if (accountDetailsTab) {
      setTab(accountDetailsTab);
    }
  }, [accountDetailsTab]);

  const handleAccountUpdate = useCallback(() => {
    onAccountUpdate?.();

    return accountDetailsApi.refetch();
  }, [onAccountUpdate, accountDetailsApi]);

  return (
    <AccountDetailsContext.Provider
      value={{
        campaign,
        account,
        contacts,
        accountUserDisposition,
        accountHistoryData,
        focusedContact,
        setFocusedContact,
        onAccountUpdate: handleAccountUpdate,
        accountExecutiveData,
        accountUserLists,
        tab,
        setTab,
        accountDetails: accountDetailsApi,
      }}
    >
      {children}
    </AccountDetailsContext.Provider>
  );
};

export const useAccountDetailsContext = (): AccountDetailsContextI =>
  useContext(AccountDetailsContext);
