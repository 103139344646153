import { FC } from "react";
import { Portal } from "react-portal";

import SidebarV2 from "shared/ui/sidebarV2";
import { AddUserToListSlide } from "./add-user-to-list-slide";
import { useAccountDetailsContext } from "@/components/modules/pipeline/account-details/context";
import { ContactDetailI } from "shared/lib/interfaces/account";
import { CampaignI } from "@/interfaces/campaign";

interface AddUserToListSidebarPropsI {
  isOpen: boolean;
  campaign?: CampaignI;
  contacts?: ContactDetailI[];
  onAccountUpdate?: () => void;
  onClose?: () => void;
}

export const AddUserToListSidebar: FC<AddUserToListSidebarPropsI> = ({
  isOpen,
  contacts: _contacts,
  campaign: _campaign,
  onAccountUpdate: _onAccountUpdate,
  onClose,
}) => {
  const { campaign, contacts, onAccountUpdate } = useAccountDetailsContext();

  return (
    <Portal>
      <SidebarV2 isOpen={isOpen} onClose={onClose}>
        <AddUserToListSlide
          campaign={_campaign || campaign}
          contacts={_contacts || contacts}
          onAccountUpdate={_onAccountUpdate || onAccountUpdate}
          onClose={onClose}
        />
      </SidebarV2>
    </Portal>
  );
};
