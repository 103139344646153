import { clsxMerge } from "shared/lib/helpers";
import { FC } from "react";

interface SpinnerPropsI {
  className?: string;
}

export const Spinner: FC<SpinnerPropsI> = ({ className }) => (
  <span
    className={clsxMerge(
      "btn loading btn-link cursor-default text-black",
      className
    )}
  />
);
