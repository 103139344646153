import EditAccountModal, {
  EDIT_ACCOUNT_MODAL_ID,
} from "@/components/modals/edit-account-modal";
import { CUSTOM_EVENTS } from "@/constants/custom-events";
import { dispatchCustomEvent } from "@/helpers/events";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import { AccountI } from "@/interfaces/accounts";
import { Portal } from "react-portal";
import { clsxMerge } from "shared/lib/helpers";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";

const DIALER_WIDGET_EDIT_ACCOUNT_MODAL_ID = `dialer_widget_${EDIT_ACCOUNT_MODAL_ID}`;

export const EditCampaignButton = ({ className }: { className?: string }) => {
  const { campaign } = useCallingContext();
  const { account, setAccount } = useInCallContext();

  const handleOpenModal = () =>
    modalHelpers.open(DIALER_WIDGET_EDIT_ACCOUNT_MODAL_ID);

  const handleOnSuccessEditCampaign = (data: AccountI) => {
    setAccount(data);

    dispatchCustomEvent({
      eventType: CUSTOM_EVENTS.ACCOUNT.RELOAD,
    });
  };

  return (
    <>
      <button
        className={clsxMerge("btn-transparent", className)}
        onClick={handleOpenModal}
      >
        Edit
      </button>

      <Portal>
        <EditAccountModal
          modalId={DIALER_WIDGET_EDIT_ACCOUNT_MODAL_ID}
          account={account}
          campaign={campaign}
          onSubmitSuccess={handleOnSuccessEditCampaign}
        />
      </Portal>
    </>
  );
};
