import { CUSTOM_EVENTS } from "@/constants/custom-events";

import Calling from "@/helpers/calling";
import { useCallingContext } from "@/hooks/dialer/use-dialer-context";
import { useDialerGlobalContext } from "@/hooks/dialer/use-dialer-global-context";

import {
  Dispatch,
  EventHandler,
  SetStateAction,
  useEffect,
  useRef,
} from "react";
import { DayJs } from "shared/lib/helpers/date";
import { asyncGet } from "@/helpers/context";
import { isDialerWidgetOpen } from "@/helpers/widgets";
import { PipelineListContactI } from "@/interfaces/pipeline/list";
import { SessionStorage } from "@/helpers/session-storage";
import { TWILIO_TOKEN_ENDPOINT_VERSIONS } from "@/constants/twilio";
import { TwilioAccessTokenResponseI } from "@/api/glen-socket";
import { WIDGETS } from "@/constants/widgets";

/**
 * Before every connected call we want to safely register, unregister device and update the token
 * so we won't have to update token during the call and essentially dropping the active call
 */
export const TwilioDeviceRegistrator = () => {
  const context = useCallingContext();
  const {
    isEnabled,
    pauseQueue,
    resumeQueue,
    setIsQueuePaused,
    setLeadsDialing,
    setConnectedLead,
    setIsTwilioTokenUpdating,
    setTwilioIdentity,
  } = useDialerGlobalContext();

  /**
   * Check if token update is required through local storage
   * Set leadsQueue on pause and
   * Check if leadsQueue is paused
   * Check if widget is closed
   * Check if not a single lead is dialing and no one is connected
   *
   * Update the token
   * Re-register device
   * Resume leadsQueue
   */
  const sessionTokenUpdate = async () => {
    const MAX_TOKEN_LIFESPAN_MINUTES = 10;
    const SS = new SessionStorage();

    /**
     * dialerGlobalTwilioTokenUpdatedAt is set in
     * TwilioProvider
     * Calling.updateTwilioAccessToken
     */
    const twilioTokenUpdatedAt = SS.dialerGlobalTwilioTokenUpdatedAt;

    /**
     * Twilio token is not expired
     */
    if (
      twilioTokenUpdatedAt &&
      DayJs(DayJs()).diff(twilioTokenUpdatedAt, "minute") <=
        MAX_TOKEN_LIFESPAN_MINUTES
    )
      return;

    /**
     * It is required to set a boolean that token is updaing
     * so sessionQueueProcessor won't unpause according to other conditions
     */
    setIsTwilioTokenUpdating?.(true);
    pauseQueue?.();

    const isQueuePaused = await asyncGet(
      setIsQueuePaused as Dispatch<SetStateAction<boolean>>
    );

    /**
     * Leads Queue is not on pause OR Dialer Widget is still open
     */
    if (!isQueuePaused || isDialerWidgetOpen()) return;

    const leadsDialing = await asyncGet(
      setLeadsDialing as Dispatch<SetStateAction<PipelineListContactI[]>>
    );
    const connectedLead = await asyncGet(
      setConnectedLead as Dispatch<SetStateAction<PipelineListContactI>>
    );

    /**
     * There are active leads that are still dialing or there is a connected lead
     */
    if (leadsDialing.length || connectedLead) return;

    /**
     * Save to session storage twilio token update date
     */
    console.log(`try safeUpdateTwilioAccessToken`);
    await Calling.safeUpdateTwilioAccessToken(
      context,
      {
        tokenEndpointVersion: TWILIO_TOKEN_ENDPOINT_VERSIONS.V3,
      },
      (accessTokenResponseData: TwilioAccessTokenResponseI) => {
        setTwilioIdentity(accessTokenResponseData.identity);
      }
    );
    console.log(`try safeUnregisterDevice`);
    await Calling.safeUnregisterDevice(context);
    console.log(`try safeUnregisterDevice`);
    await Calling.safeRegisterDevice(context);

    console.log(`try setIsTwilioTokenUpdating`);
    setIsTwilioTokenUpdating?.(false);
    console.log(`try resumeQueue`);
    resumeQueue?.();
  };

  const intervalRef = useRef<number>();
  useEffect(() => {
    if (isEnabled) {
      const sessionQueueInterval = window.setInterval(() => {
        sessionTokenUpdate();
      }, 1000);

      intervalRef.current = sessionQueueInterval;
    } else {
      clearInterval(intervalRef.current);
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [isEnabled]);

  useEffect(() => {
    const handleEventCloseWidget = () => {
      (async () => {
        await Calling.safeUnregisterDevice(context);
        await Calling.safeRegisterDevice(context);
      })();
    };

    const closeWidgetEventArgs = [
      CUSTOM_EVENTS.WIDGETS[WIDGETS.MAXED_DIALER].CLOSE,
      handleEventCloseWidget as EventHandler<any>,
    ] as [type: string, listener: EventListenerOrEventListenerObject];

    window.document.addEventListener(...closeWidgetEventArgs);

    return () => {
      window.document.removeEventListener(...closeWidgetEventArgs);
    };
  }, [context]);

  return null;
};
