import { FC } from "react";

import { AccountDetailsStatusI } from "@/api/routes/account/interfaces";
import { getAccountDispositionStatusParams } from "@/modules/pipeline/account-details/sidebar/sections/status-section/utils";
import { clsxMerge } from "shared/lib/helpers";

interface AccountDispositionStatusPillPropsI {
  className?: string;
  accountStatus: AccountDetailsStatusI;
}

export const AccountDispositionStatusPill: FC<
  AccountDispositionStatusPillPropsI
> = ({ className, accountStatus }) => {
  const { style, label } = getAccountDispositionStatusParams(accountStatus);

  if (!label) {
    return null;
  }

  return (
    <div
      className={clsxMerge(
        "w-max min-w-[182px] px-2 py-1.5",
        "rounded text-center text-white typography-body-3-black",
        style,
        className
      )}
    >
      {label}
    </div>
  );
};
