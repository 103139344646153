import { useDialerDirectCall } from "@/hooks/dialer/use-dialer-direct-call";
import { CallButton as MiniDialerCallButton } from "@/modules/calling/control-menu/v2/buttons/call-button";
import { useMemo } from "react";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { getFullEntityName } from "shared/lib/helpers";
import { PhoneIcon } from "shared/ui/icons";
import ButtonMenuItem from "@/components/shared/button-menu-item";
import { ButtonMenu } from "shared/ui";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import { DIALER_CALL_STATUS } from "@/constants/dialer";

export const CallButton = () => {
  const status = useDialerCallStatus();

  const { startDirectCall } = useDialerDirectCall();

  const { campaign, contacts } = useAccountDetailsContext();

  const addButtonMenuItems = useMemo(
    () =>
      contacts?.map((contact) => {
        const startCall = (contactId: string) => () => {
          startDirectCall({
            contact: {
              id: contactId,
            },
          });
        };

        return (
          <ButtonMenuItem
            text={getFullEntityName(contact)}
            onClick={startCall(contact.contact_id as string)}
            icon={<PhoneIcon className="mr-1 h-3 w-3" />}
          />
        );
      }) || [],
    [contacts, campaign]
  );

  return DIALER_CALL_STATUS.DURING === status ? (
    <MiniDialerCallButton />
  ) : (
    <ButtonMenu
      containerClassName="dropdown-top"
      contentClassName="mb-2"
      position="end"
      className="p-0 "
      renderTrigger={() => (
        <MiniDialerCallButton onStartCall={async () => {}} />
      )}
      items={addButtonMenuItems}
    />
  );
};
