import { FC, useState } from "react";
import clsx from "clsx";
import { twMerge } from "tailwind-merge";
import {
  DISPOSITIONS,
  DISPOSITION_DETAILS,
  DISPOSITION_DETAILS_MAP,
} from "@/constants/dispositions";
import {
  useCallingContext,
  useInCallContext,
} from "@/hooks/dialer/use-dialer-context";
import { RadioButton } from "shared/ui";
import DispositionCallbackCalendar from "./disposition-callback-calendar";
import DispositionDetails from "./disposition-details";
import Calling from "@/helpers/calling";
import { toast } from "react-hot-toast";
import { glencocoClientAPI } from "@/api/glencoco";
import { combineActivityNotes } from "@/helpers/activity-log";
import { dd } from "@/helpers/datadog";
import { Cookies } from "shared/lib/helpers";
import { useEffectOnce } from "shared/lib/hooks";

const EnhancedRadioButton = RadioButton();

// TODO - avoid recursive imports;
export interface DataI {
  notes?: string;
  disposition?: (typeof DISPOSITIONS)[keyof typeof DISPOSITIONS];
  dispositionDetails?: keyof typeof DISPOSITION_DETAILS;
}

interface SetDispositionCardI {
  scrollToBottom?: () => void;
  isWrapperNoStyles?: boolean;
  isHideSetCallbackQuestion?: boolean;
  isHideLeadDescription?: boolean;

  onSuccess?: () => void;
}

const SetDispositionCard: FC<SetDispositionCardI> = ({
  scrollToBottom,
  isWrapperNoStyles,
  isHideSetCallbackQuestion,
  isHideLeadDescription,
  onSuccess = () => {},
}) => {
  const callingContext = useCallingContext();
  const { campaignId, call, isCallOnDemand, callOnDemandConfig } =
    callingContext;
  const inCallContext = useInCallContext();
  const { account, contact } = inCallContext;

  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isCallback, setIsCallback] = useState<boolean>();

  const [data, setData] = useState<DataI>({
    notes: combineActivityNotes(inCallContext).join("\r\n"),
  });

  const handleModuleExit = () => {
    Calling.exit({
      context: callingContext,
      inCallContext,
    });
  };

  const submitData = async (
    dispositionDetails?: keyof typeof DISPOSITION_DETAILS_MAP,
    optional: {
      is_not_a_direct_line?: boolean;
      has_technical_issues?: boolean;
    } = {}
  ) => {
    setLoading(true);

    if (isCallback) {
      // no need to submit because it is already done inside <CallingCallbackCard />
      setTimeout(() => {
        handleModuleExit();
        setLoading(false);
        onSuccess();
      }, 1000);
      return;
    }

    if (!campaignId) {
      toast.error("Missing campaign Id.");
      setLoading(false);
      return;
    }

    const API = glencocoClientAPI();

    const callId =
      isCallOnDemand && callOnDemandConfig?.callId
        ? (callOnDemandConfig?.callId as string)
        : (call?.customParameters.get("call_id") as string);
    const requestBody = {
      notes: data.notes || "",
      call_disposition:
        DISPOSITION_DETAILS_MAP[
          dispositionDetails ||
            (data.dispositionDetails as keyof typeof DISPOSITION_DETAILS_MAP)
        ],
      ...optional,
    };

    const resp = await API.addCallDisposition(
      campaignId,
      callId,
      requestBody
    ).catch((e) => e);

    if (resp.status === 200) {
      toast.success("Disposition data submitted");
      handleModuleExit();
      onSuccess();
    } else {
      const cookies = Cookies();
      const user = cookies.getUser();

      dd.error("Failed to submit call disposition info", {
        user,
        call: {
          status: resp?.status,
          error: resp,
          call: call,
          customParameters: Object.fromEntries(call?.customParameters || []),
          campaignId,
          callId,
          requestBody,
        },
      });

      toast.error("Failed to submit call disposition info");
    }

    setTimeout(() => setLoading(false), 1000);
  };

  const handleCallbackChange = (value: boolean) => {
    setIsCallback(value);

    if (scrollToBottom) {
      scrollToBottom();
    }
  };

  const updateData = (values: DataI) => {
    setData({ ...data, ...values });
  };

  useEffectOnce(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  });

  return (
    <div
      className={twMerge(
        isWrapperNoStyles
          ? ""
          : clsx(
              "w-[530px] rounded-[10px] bg-white px-8 py-6",
              "transition-[opacity,transform] duration-500",
              {
                "scale-50 opacity-0": !isVisible,
                "scale-100 opacity-100": isVisible,
              }
            )
      )}
    >
      <section>
        <h2 className="ae-typography-h4 mb-2">
          Meeting notes for the Account Executive
        </h2>
        <p className="ae-typography-body2 mb-6 text-black/60">
          {isHideSetCallbackQuestion
            ? "Please select a disposition that best matches the outcome of the call"
            : "Please leave notes for the Account Executive to review before the meeting."}
        </p>
      </section>

      {!isHideLeadDescription && (
        <section className="mb-6 flex gap-8">
          <div className="flex flex-col gap-3">
            <div className="ae-typography-detail1">Lead Name</div>
            <div className="ae-typography-detail1">Lead Title</div>
            <div className="ae-typography-detail1">Company Name</div>
          </div>

          <div className="flex flex-col gap-3">
            <div className="ae-typography-detail1 text-black/60">
              {contact?.first_name || "-"} {contact?.last_name}
            </div>
            <div className="ae-typography-detail1 text-black/60">
              {contact?.title || "-"}
            </div>
            <div className="ae-typography-detail1 text-black/60">
              {account?.name || "-"}
            </div>
          </div>
        </section>
      )}

      {!isHideSetCallbackQuestion && (
        <div className="mb-6">
          <label className="label">
            <span className="ae-typography-detail1">
              Would you like to set a callback?
            </span>
          </label>
          <div className="flex justify-between gap-3">
            <EnhancedRadioButton
              name="yes_callback"
              value="yes"
              label="Yes"
              inputProps={{
                checked: isCallback === true,
                onChange: () => handleCallbackChange(true),
              }}
            />
            <EnhancedRadioButton
              name="no_callback"
              value="no"
              label="No"
              inputProps={{
                checked: isCallback === false,
                onChange: () => handleCallbackChange(false),
              }}
            />
          </div>
        </div>
      )}

      {isCallback === true && (
        <DispositionCallbackCalendar
          data={data}
          updateData={updateData}
          handleClose={handleModuleExit}
        />
      )}

      {(isHideSetCallbackQuestion || isCallback === false) && (
        <DispositionDetails
          updateData={updateData}
          handleSave={submitData}
          data={data}
          loading={loading}
        />
      )}
    </div>
  );
};

export default SetDispositionCard;
