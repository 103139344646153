import { useEffect } from "react";
import toast from "react-hot-toast";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import {
  BulkDisqualifyContactsFromListRequestI,
  BulkMarkSeenContactsFromListRequestI,
  BulkRemoveContactsFromListRequestI,
  BulkUpdateListsRequestI,
  ContactToListI,
  CreateOrUpdateListRequestParamsI,
  GetListsRequestFilterParamsI,
} from "@/api/routes/list";
import { pluralizeNoun } from "shared/lib/helpers";
import { useDebouncedValue } from "shared/lib/hooks/use-debounced-value";
import { ListTableSortingStateI } from "./workspace/table/interfaces";
import { useApiClient } from "@/context/api-client";

export const LISTS_QUERY_KEY = "lists";
export const SEARCH_CONTACTS_QUERY_KEY = "contacts";

// Get all lists
export const useFetchLists = () => {
  const api = useApiClient();

  const listsApi = useQuery({
    queryKey: [LISTS_QUERY_KEY],
    queryFn: api.getLists,
  });

  useEffect(() => {
    if (listsApi.isError) {
      toast.error("Failed to load your lists, please try to reload the page.");
    }
  }, [listsApi.isError]);

  return listsApi;
};

export type FetchListsApiI = ReturnType<typeof useFetchLists>;

// List CRUD
export const useCreateList = () => {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: (parameters: CreateOrUpdateListRequestParamsI) =>
      api.createList(parameters),
    onSuccess: (_, { name }) => {
      toast.success(`List '${name}' has been created!`);

      queryClient.invalidateQueries({
        queryKey: [LISTS_QUERY_KEY],
      });
    },
    onError: () => toast.error("Failed to create new list."),
  });
};

export const useUpdateLists = () => {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: (parameters: BulkUpdateListsRequestI) =>
      api.bulkUpdateLists(parameters),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [LISTS_QUERY_KEY] }),
    onError: () => toast.error("Failed to update lists."),
  });
};

export const useFetchListDetails = (listId: string, isSearchMode = false) => {
  const api = useApiClient();

  const listDetailsApi = useQuery({
    enabled: !!listId?.trim() && !isSearchMode,
    queryKey: [LISTS_QUERY_KEY, listId],
    queryFn: () => api.getListDetails(listId),
  });

  useEffect(() => {
    if (listDetailsApi.isError) {
      toast.error("Failed to load list details.");
    }
  }, [listDetailsApi.isError]);

  return listDetailsApi;
};

export const useUpdateList = (listId: string) => {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: (parameters: CreateOrUpdateListRequestParamsI) =>
      api.updateList(listId, parameters),
    onSuccess: () => {
      toast.success("List details have been updated!");

      queryClient.invalidateQueries({
        queryKey: [LISTS_QUERY_KEY],
      });
    },
    onError: () => toast.error("Failed to update this list."),
  });
};

export const useDeleteList = (listId: string) => {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: () => api.deleteList(listId),
    onSuccess: () => {
      toast.success("List has been removed.");

      queryClient.invalidateQueries({
        queryKey: [LISTS_QUERY_KEY],
      });
    },
    onError: () => toast.error("Failed to remove this list."),
  });
};

// Contacts queries and mutations
export const useFetchListContacts = (
  listId: string,
  sortParams: ListTableSortingStateI,
  filters?: GetListsRequestFilterParamsI,
  isNurtureList = false
) => {
  const api = useApiClient();

  let cleanFilters: GetListsRequestFilterParamsI = {};

  if (filters?.campaign_filters?.length) {
    cleanFilters = {
      ...cleanFilters,
      campaign_filters: filters?.campaign_filters,
    };
  }

  if (filters?.timezone_filters?.length) {
    cleanFilters = {
      ...cleanFilters,
      timezone_filters: filters?.timezone_filters,
    };
  }

  if (isNurtureList && filters?.list_subsection) {
    cleanFilters = {
      ...cleanFilters,
      list_subsection: filters?.list_subsection,
    };
  }

  const listsFetcher = isNurtureList
    ? api.getListContactsV2
    : api.getListContacts;

  const listContactsApi = useInfiniteQuery({
    enabled: !!listId?.trim(),
    queryKey: [LISTS_QUERY_KEY, listId, "contacts", sortParams, cleanFilters],
    queryFn: ({ pageParam }) =>
      listsFetcher(listId, {
        next_token: pageParam,
        ...(sortParams || {}),
        ...(cleanFilters || {}),
      }),
    initialPageParam: undefined as string | null | undefined,
    getNextPageParam: (lastPage) => lastPage.data?.next_token,
  });

  useEffect(() => {
    if (listContactsApi.isError) {
      toast.error(
        "Failed to load portion of contacts. Please try to reload the page."
      );
    }
  }, [listContactsApi.isError]);

  return listContactsApi;
};

export const useBulkAddContactsToList = () => {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: ({
      contacts,
      list_ids,
    }: {
      contacts: ContactToListI[];
      list_ids: string[];
    }) =>
      api.bulkAddContactsToListRequest({
        contacts,
        list_ids,
      }),
    onSuccess: (_, { contacts: { length }, list_ids: listIds }) => {
      toast.success(
        `${
          length > 1 ? "Contacts have" : "Contact has"
        } been added to the specified ${pluralizeNoun("list", length)}`
      );

      listIds.forEach((listId) => {
        queryClient.invalidateQueries({
          queryKey: [LISTS_QUERY_KEY, listId, "contacts"],
        });
      });
    },
    onError: (_, { contacts: { length } }) => {
      toast.error(
        `Failed to add ${pluralizeNoun(
          "contact",
          length
        )} to the specified ${pluralizeNoun("list", length)}`
      );
    },
  });
};

export const useBulkRemoveContactsFromList = (listId: string) => {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: ({ membership_ids }: BulkRemoveContactsFromListRequestI) =>
      api.bulkRemoveContactsFromListRequest(listId, {
        membership_ids,
      }),
    onSuccess: () => {
      toast.success("Contacts have been removed from the list.");

      queryClient.invalidateQueries({
        queryKey: [LISTS_QUERY_KEY, listId, "contacts"],
      });
    },
    onError: () => toast.error("Failed to remove contacts from this list."),
  });
};

export const useBulkMarkSeenContactsFromList = (listId: string) => {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: ({ membership_ids }: BulkMarkSeenContactsFromListRequestI) =>
      api.bulkMarkSeenContactsFromListRequest(listId, {
        membership_ids,
      }),
    onSuccess: () => {
      toast.success("Contacts have been marked as seen.");

      queryClient.invalidateQueries({
        queryKey: [LISTS_QUERY_KEY, listId, "contacts"],
      });
    },
    onError: () => toast.error("Failed to mark contacts as seen."),
  });
};

export const useBulkDisqualifyContactsFromList = (listId: string) => {
  const queryClient = useQueryClient();
  const api = useApiClient();

  return useMutation({
    mutationFn: ({
      membership_ids,
      reason,
    }: BulkDisqualifyContactsFromListRequestI) =>
      api.bulkDisqualifyContactsFromListRequest(listId, {
        membership_ids,
        reason,
      }),
    onSuccess: () => {
      toast.success("Contacts have been disqualified from the list.");

      queryClient.invalidateQueries({
        queryKey: [LISTS_QUERY_KEY, listId, "contacts"],
      });
    },
    onError: () => toast.error("Failed to disqualify contacts from the list."),
  });
};

// Global search
export const useSearchContacts = (searchTerm?: string) => {
  const debouncedSearchTerm = useDebouncedValue(searchTerm, 400);
  const api = useApiClient();

  return useInfiniteQuery({
    enabled: !!debouncedSearchTerm?.trim(),
    queryKey: [SEARCH_CONTACTS_QUERY_KEY, debouncedSearchTerm],
    queryFn: ({ pageParam }) =>
      api.searchContacts({
        next_token: pageParam,
        search_term: debouncedSearchTerm || "",
      }),
    initialPageParam: undefined as string | null | undefined,
    getNextPageParam: (lastPage) => lastPage.data?.next_token,
  });
};

export type SearchContactsApiI = ReturnType<typeof useSearchContacts>;
