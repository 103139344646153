import { useMemo } from "react";

import Router from "@/helpers/router";
import { isUserApprovedToCall } from "@/helpers/campaign";
import { useGlobalContext } from "@/hooks/use-global-context";
import { clsxMerge } from "shared/lib/helpers";
import { Avatar, Tooltip, TooltipContent, TooltipTrigger } from "shared/ui";

export const PrimaryNavCampaignsSelector = ({
  className,
}: {
  className?: string;
}) => {
  const globalContext = useGlobalContext();
  const { campaigns } = globalContext;

  const isApprovedToCall = useMemo(
    () => campaigns?.some((c) => isUserApprovedToCall(c.campaign_user_status)),
    [campaigns]
  );

  return (
    <div className={clsxMerge("", className)}>
      <div className="dropdown">
        {isApprovedToCall ? (
          <label
            className={clsxMerge(
              "btn-ae-default min-w-[138px] bg-white",
              "text-[14px] font-bold text-black",
              "hover:bg-[#ECECEC]",
              {
                "disabled !text-black": !campaigns?.some((c) =>
                  isUserApprovedToCall(c.campaign_user_status)
                ),
              }
            )}
            tabIndex={1}
          >
            Start Calling
            <span
              className={clsxMerge(
                "ml-2",
                "h-0 w-0",
                "border-l-[5px] border-r-[5px] border-t-[6px]",
                "border-l-transparent border-r-transparent border-t-black"
              )}
            />
          </label>
        ) : (
          <Tooltip>
            <TooltipContent className="w-[280px]">
              You'll need to complete one of the training modules for a company
              before you can start dialing! Check out the "Discover Campaigns"
              tab under "Campaigns" to select a campaign.
            </TooltipContent>
            <TooltipTrigger>
              <label
                className={clsxMerge(
                  "btn-ae-default min-w-[138px]",
                  "text-[14px] font-bold text-black",
                  {
                    "disabled !bg-white !text-black opacity-30":
                      !campaigns?.some((c) =>
                        isUserApprovedToCall(c.campaign_user_status)
                      ),
                  }
                )}
                tabIndex={1}
              >
                Start Calling
                <span
                  className={clsxMerge(
                    "ml-2",
                    "h-0 w-0",
                    "border-l-[5px] border-r-[5px] border-t-[6px]",
                    "border-l-transparent border-r-transparent border-t-black"
                  )}
                />
              </label>
            </TooltipTrigger>
          </Tooltip>
        )}

        <ul
          tabIndex={1}
          className="dropdown-content mt-3 max-h-[50vh] w-[200px] overflow-y-auto rounded-md bg-base-100 px-0 shadow-lg"
        >
          {campaigns
            ?.filter((c) => isUserApprovedToCall(c.campaign_user_status))
            .map((c, i) => (
              <li key={i} className={clsxMerge("my-1 px-2")}>
                <a
                  href={Router.session(c.id)}
                  target="_blank"
                  rel="noreferrer"
                  className={clsxMerge(
                    "flex items-center",
                    "brand-typography-body6",
                    "p-2",
                    "w-full rounded-md",
                    "hover:bg-black/10"
                  )}
                >
                  <Avatar
                    src={c.logo_s3_url}
                    alt="company logo"
                    className="mr-2 h-6 w-6"
                    placeholderText={c.name}
                  />

                  <span className="ae-typography-body2">{c.name}</span>
                </a>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};
