import { CloseIcon } from "shared/ui/icons";

import { clsxMerge } from "shared/lib/helpers";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import { DIALER_CALL_STATUS } from "@/constants/dialer";

import { Portal } from "react-portal";
import { ConfirmActionModal } from "shared/ui/modal/confirm-action";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";
// import {
//   useCallingContext,
//   useInCallContext,
// } from "@/hooks/dialer/use-dialer-context";

// import { useCountdownTimer } from "shared/lib/hooks/use-countdown-timer";
// import { DayJs } from "shared/lib/helpers/date";

const CONFIRM_ACTION_MODAL_ID = "confirm-action-modal-close-widget";

/**
 * We want to simplify and when clicking outside of the sidebar
 * execute same logic when clicking close button
 */
export const MAXED_DIALER_CLOSE_BUTTON_ID = "maxed-dialer-close-button";

export const MaxedDialerWidgetCloseButton = ({
  onClick,
}: {
  onClick?: () => void;
}) => {
  // const { call, device, setCallingState } = useCallingContext();
  // const { startAt } = useInCallContext();

  // const countdown = useCountdownTimer({
  //   targetDate: DayJs(startAt).add(8, "seconds").toDate(),
  //   updateInterval: 1000,
  // });

  const callStatus = useDialerCallStatus();

  // const handleCloseWidget = () => {
  //   call?.disconnect();
  //   device?.disconnectAll();

  //   setCallingState(DIALER_STATES.POST);

  //   dd.rum.log(
  //     `${LOG_CATEGORIES.TWILIO}[DISCONNECT] - manual disconnect MaxedDialerWidgetCloseButton`
  //   );

  //   setTimeout(() => {
  //     onClick?.();
  //   }, 1000);
  // };

  const openConfirmationModal = () => {
    modalHelpers.open(CONFIRM_ACTION_MODAL_ID);
  };

  return (
    <>
      <button
        id={MAXED_DIALER_CLOSE_BUTTON_ID}
        className={clsxMerge(
          "btn btn-square btn-sm",
          "absolute right-2 top-2",
          "z-20"
        )}
        onClick={
          callStatus === DIALER_CALL_STATUS.BEFORE
            ? onClick
            : openConfirmationModal
        }
      >
        <CloseIcon className="w-6 text-white" />
      </button>

      <Portal>
        {/* <ConfirmActionModal
          title="End Call and Close Widget"
          description="Are you sure you want to end the call and close the dialer widget? This action cannot be undone, and any ongoing conversation will be terminated. Please confirm or cancel your action."
          id={CONFIRM_ACTION_MODAL_ID}
          onConfirm={handleCloseWidget}
          confirmButtonText={
            startAt && countdown !== "00s" ? countdown : "Confirm"
          }
          confirmButtonDisabled={!!(startAt && countdown !== "00s")}
        /> */}

        <ConfirmActionModal
          title="Disposition is required"
          description="You must finish the call and fill out the disposition before proceeding."
          id={CONFIRM_ACTION_MODAL_ID}
          cancelButtonText="Close"
          // onConfirm={handleCloseWidget}
          confirmButtonText={"Understood"}
        />
      </Portal>
    </>
  );
};
