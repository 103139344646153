import { useEffect } from "react";

import { ValueOfObjectFields } from "shared/lib/interfaces/utils";

import { AccountDetailsNavigation as Navigation } from "./navigation";
import { AccountHistorySection } from "./sections/account-history-section";
import { AccountMessagesSection } from "./sections/messages-section";

import { AccountSummarySection } from "./sections/account-summary-section";
import { DispositionSection } from "./sections/disposition-section";
import { AccountDetailsV2NoteEditor } from "./sections/note-editor-section";
import { ACCOUNT_DETAILS_TABS } from "@/constants/account-details";

import { useAccountDetailsContext } from "../context";
import { useDialerCallStatus } from "@/hooks/dialer/use-dialer-call-status";
import { DIALER_CALL_STATUS } from "@/constants/dialer";
import { ADPrimaryContentProvider } from "./context";

type TabI = ValueOfObjectFields<typeof ACCOUNT_DETAILS_TABS>;

export const PrimaryContent = () => {
  const { tab, setTab } = useAccountDetailsContext();
  const callStatus = useDialerCallStatus();

  const handleTabChange = (tabId?: string) => {
    void setTab(tabId as TabI);
  };

  useEffect(() => {
    return () => {
      void setTab(ACCOUNT_DETAILS_TABS.ACCOUNT_HISTORY);
    };
  }, []);

  return (
    <ADPrimaryContentProvider>
      <div className="px-2 pb-40 xl:px-12">
        <div className="mx-auto max-w-[1100px]">
          <AccountSummarySection className="mt-3" />

          {DIALER_CALL_STATUS.AFTER === callStatus && <DispositionSection />}

          {DIALER_CALL_STATUS.AFTER !== callStatus && (
            <AccountDetailsV2NoteEditor className="mt-5" />
          )}

          <Navigation selected={tab} onChange={handleTabChange} />

          {tab === ACCOUNT_DETAILS_TABS.ACCOUNT_HISTORY && (
            <AccountHistorySection />
          )}

          {tab === ACCOUNT_DETAILS_TABS.MESSAGES && <AccountMessagesSection />}
        </div>
      </div>
    </ADPrimaryContentProvider>
  );
};
