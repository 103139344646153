import { FC, useState } from "react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

import { useLongPolling } from "shared/lib/hooks";
import { clsxMerge, formatUSPhoneNumber } from "shared/lib/helpers";
import { SECONDS_IN_HOUR } from "shared/lib/constants/time";
import { CallConnectionQuality } from "./call-connection-quality";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";

dayjs.extend(duration);

interface DialerConnectionLabelPropsI {
  className?: string;
}

export const ConnectedLabel: FC<DialerConnectionLabelPropsI> = ({
  className,
}) => {
  const { contact } = useInCallContext();
  const [secondsElapsed, setSecondsElapsed] = useState(0);

  useLongPolling(() => {
    setSecondsElapsed((prev) => prev + 1);
  }, 1000);

  return (
    <div
      className={clsxMerge(
        "min-w-[170px]",
        "w-max rounded-xl bg-success-content px-2 py-1",
        "brand-typography-h7 animate-fadein text-white",
        className
      )}
    >
      <div className="flex items-center justify-center gap-1.5">
        <CallConnectionQuality />

        <span>Connected</span>

        <span>
          {dayjs
            .duration(secondsElapsed, "seconds")
            .format(secondsElapsed >= SECONDS_IN_HOUR ? "HH:mm:ss" : "mm:ss")}
        </span>
      </div>
      <div className="brand-typography-detail2 flex items-center justify-center text-white/60">
        {formatUSPhoneNumber(
          contact?.mobile ? contact?.mobile : contact?.phone
        )}
      </div>
    </div>
  );
};
