import { glencocoClientAPI } from "@/api/glencoco";
import DangerousActionConfirmationModal from "@/components/modals/dangerous-action-confirmation-modal";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { ContactDetailI } from "shared/lib/interfaces/account";

export const DELETE_ACCOUNT_LEAD_CONFIRMATION_MODAL_ID =
  "delete_account_lead_confirmation_modal";

export const DeleteAccountLeadConfirmationModal = ({
  campaignId,
  contact,
  onSuccess = () => {},
}: {
  campaignId?: string;
  contact?: ContactDetailI;
  onSuccess?: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    if (campaignId && contact?.contact_id) {
      const API = glencocoClientAPI();
      setIsLoading(true);

      const deleteAccountLeadResponse = await API.deleteAccountLead(
        campaignId,
        contact.contact_id
      ).catch((e) => e);

      if (deleteAccountLeadResponse.status === 200) {
        onSuccess();
      } else {
        toast.error("Failed to delete lead. Please contact Glencoco team.");
      }
    }
  };

  return (
    <DangerousActionConfirmationModal
      modalId={DELETE_ACCOUNT_LEAD_CONFIRMATION_MODAL_ID}
      title="Are you sure you want to delete this lead?"
      description={`Please confirm you want to delete the lead - ${contact?.first_name} ${contact?.last_name}`}
      mainButtonText="Yes, delete"
      isLoading={isLoading}
      callback={onSubmit}
    />
  );
};
