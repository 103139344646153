import { FC, ReactNode, useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";

import CenteredSpinner from "shared/ui/spinners/centered-spinner";

interface DataStatesWrapperPropsI {
  viewName?: string; // The name of the view, like "Today's Performance", "Productivity", etc.

  api: {
    isFetching: boolean;
    isSuccess: boolean;
    isError: boolean;
    refetch?: () => void;
    isEmpty?: boolean;
  };

  loading?: ReactNode;
  error?: ReactNode;
  empty?: ReactNode;
  children?: ReactNode;
}

/**
 * This component is a wrapper for pending, success, error and empty states
 * for data from API calls / react query. See the example of usage on the user
 * performance dashboard.
 *
 * @component
 * @param viewName - Name of a view, which should be used for error and empty states.
 * @param api - collection of API states and, optionally, refetch function.
 * @param api.isFetching - State: API is fetching data.
 * @param api.isSuccess - State: API has successfully fetched data.
 * @param api.isError - State: API has failed to fetch data.
 * @param api.isEmpty - State: API has successfully fetched data, but it's empty. Usually it's a user defined state.
 * @param refetch - Function to re-fetch data from API in case an error occurred.
 * @param loading - Custom loading state.
 * @param error - Custom error state.
 * @param empty - Custom empty state.
 * @param children - Children to render when data is successfully fetched.
 */
export const DataStatesWrapper: FC<DataStatesWrapperPropsI> = ({
  viewName = "data",
  api,
  loading,
  error,
  empty,
  children,
}) => {
  const { isFetching, isSuccess, isError, isEmpty, refetch } = api;

  // Error content is reused across both API error and error boundary fallback.
  const errorContent = useMemo(
    () =>
      error || (
        <div className="flex h-full w-full flex-col items-center justify-center gap-2">
          <span className="typography-body-4 text-center">
            An error occurred while loading {viewName}.
          </span>

          {refetch && (
            <button className="btn-ae-default" onClick={() => refetch()}>
              Reload
            </button>
          )}
        </div>
      ),
    [viewName, refetch]
  );

  if (isError) {
    return errorContent;
  }

  if (isFetching) {
    return loading || <CenteredSpinner className="h-full w-full" />;
  }

  if (isSuccess) {
    if (isEmpty) {
      return (
        empty || (
          <div className="flex h-full min-h-[120px] w-full flex-col items-center justify-center rounded-xl bg-[#F4F4F4]">
            <span className="typography-body-4-bold text-center text-[#666]">
              No {viewName} data available.
            </span>
          </div>
        )
      );
    }

    return (
      <ErrorBoundary fallbackRender={() => errorContent}>
        {children}
      </ErrorBoundary>
    );
  }

  return null;
};
