import { createContext, useState, useEffect, useContext } from "react";
import isEqual from "lodash/isEqual";

import { glencocoClientAPI } from "@/api/glencoco";

import { NotificationCountersI } from "@/interfaces/notification";
import { useWSNotifications } from "@/hooks/use-ws-notifications";
import { useLongPolling } from "shared/lib/hooks/use-longpolling";

import { useEffectOnce } from "shared/lib/hooks/use-effect-once";

export interface NotificationsContextI {
  notificationCounters?: NotificationCountersI;
  reload?: () => Promise<boolean>;
}

const NOTIFICATIONS_UPDATE_INTERVAL_MS = 15000;

const NotificationsContext = createContext({});

// NOTE Old notificatiosn
export const GlobalNotificationsProvider = ({
  children,
  context,
}: {
  children: any;
  context?: NotificationsContextI;
}) => {
  const { isNewNotifications, clearNotifications } = useWSNotifications();

  const [notificationCounters, setNotificationCounters] =
    useState<NotificationCountersI>();

  const setNotifications = (
    updatedNotificationCounters?: NotificationCountersI
  ) => {
    //NOTE use deep comparison if notification object is actually different.
    if (!isEqual(notificationCounters, updatedNotificationCounters)) {
      setNotificationCounters(updatedNotificationCounters);
    }
  };

  const updateNotificationCount = async () => {
    const API = glencocoClientAPI();

    const GetNotificationsResponse = await API.getNotificationsCount().catch(
      (e) => e
    );
    if (GetNotificationsResponse.status === 200) {
      setNotifications(GetNotificationsResponse.data);

      return true;
    } else {
      return false;
    }
  };

  const [nc, setGlobalContext] = useState({
    ...context,

    setNotifications,
    reload: updateNotificationCount,
  } as NotificationsContextI);

  useEffect(() => {
    if (isNewNotifications) {
      (async () => {
        const isSuccessUpadingNotificationCount =
          await updateNotificationCount();

        if (isSuccessUpadingNotificationCount) clearNotifications();
      })();
    }
  }, [isNewNotifications]);

  useLongPolling(updateNotificationCount, NOTIFICATIONS_UPDATE_INTERVAL_MS);

  useEffectOnce(() => {
    // NOTE - Check if there are any notifications ---------------------------
    updateNotificationCount();
  });

  useEffect(() => {
    setGlobalContext({
      ...nc,

      notificationCounters,
    });
  }, [notificationCounters]);

  return (
    <NotificationsContext.Provider value={nc as NotificationsContextI}>
      {children}
    </NotificationsContext.Provider>
  );
};

export const useGlobalNotificationsContext = (): NotificationsContextI =>
  useContext(NotificationsContext);

export default NotificationsContext;
