import { useEffect, useState } from "react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { CustomSelectFieldOptionI } from "shared/ui/ae-user-input/select-field-custom";
import clsx from "clsx";
import {
  DISPOSITIONS,
  DISPOSITIONS_STRING_MAP,
  DISPOSITION_DETAILS,
} from "@/constants/dispositions";
import { RadioButton } from "shared/ui";
import { Checkbox } from "shared/ui/ae-user-input";
// TODO - avoid recursive imports
import { DataI } from "..";
import { useInCallContext } from "@/hooks/dialer/use-dialer-context";
import dayjs from "dayjs";

const EnhancedRadioButton = RadioButton();
const EnhancedCheckbox = Checkbox();

const MAX_CALL_DURATION_TO_SKIP_DISPOSITION_IN_SECONDS = 11;

const DISPOSITION_OPTIONS = [
  {
    label: DISPOSITIONS_STRING_MAP[DISPOSITIONS.TENTATIVE_INTEREST],
    value: DISPOSITIONS.TENTATIVE_INTEREST,
    bgClassname: "bg-[#DFF4B4]",
    hoverBgClassname: "hover:bg-black hover:text-white",
  },
  {
    label: DISPOSITIONS_STRING_MAP[DISPOSITIONS.NO_PICK_UP],
    value: DISPOSITIONS.NO_PICK_UP,
    bgClassname: "bg-[#F2FBE1]",
    hoverBgClassname: "hover:bg-black hover:text-white",
  },
  {
    label: DISPOSITIONS_STRING_MAP[DISPOSITIONS.NOT_INTERESTED],
    value: DISPOSITIONS.NOT_INTERESTED,
    bgClassname: "bg-[#FFEFEB]",
    hoverBgClassname: "hover:bg-black hover:text-white",
  },
  {
    label: DISPOSITIONS_STRING_MAP[DISPOSITIONS.NOT_QUALIFIED],
    value: DISPOSITIONS.NOT_QUALIFIED,
    bgClassname: "bg-[#FFBFB0]",
    hoverBgClassname: "hover:bg-black hover:text-white",
  },
  {
    label: DISPOSITIONS_STRING_MAP[DISPOSITIONS.BAD_DATA],
    value: DISPOSITIONS.BAD_DATA,
    bgClassname: "bg-[#FF9E89]",
    hoverBgClassname: "hover:bg-black hover:text-white",
  },
  // {
  //   label: DISPOSITIONS_STRING_MAP[DISPOSITIONS.OTHER],
  //   value: DISPOSITIONS.OTHER,
  //   hoverBgClassname: "hover:bg-black hover:text-white",
  // },
];

const DETAILS_OPTIONS_MAP: Record<string, CustomSelectFieldOptionI[]> = {
  [DISPOSITIONS.TENTATIVE_INTEREST]: [
    { label: DISPOSITION_DETAILS.REQUESTED_MORE_MATERIALS },
  ],
  [DISPOSITIONS.NO_PICK_UP]: [
    { label: DISPOSITION_DETAILS.VOICEMAIL_LEFT },
    { label: DISPOSITION_DETAILS.VOICEMAIL_NOT_LEFT },
  ],
  [DISPOSITIONS.NOT_INTERESTED]: [
    { label: DISPOSITION_DETAILS.PITCH_REJECTED_DM },
    { label: DISPOSITION_DETAILS.INTRO_REJECTED_DM },
    { label: DISPOSITION_DETAILS.GATEKEEPER_REJECTED },
  ],
  [DISPOSITIONS.NOT_QUALIFIED]: [
    { label: DISPOSITION_DETAILS.NOT_QUALIFIED_AUTHORITY },
    { label: DISPOSITION_DETAILS.NOT_QUALIFIED_NEED },
    { label: DISPOSITION_DETAILS.NOT_QUALIFIED_TIMING },
    { label: DISPOSITION_DETAILS.NOT_QUALIFIED_BUDGET },
  ],
  [DISPOSITIONS.BAD_DATA]: [
    { label: DISPOSITION_DETAILS.BAD_PHONE_NUMBER },
    { label: DISPOSITION_DETAILS.DO_NOT_CALL },
    { label: DISPOSITION_DETAILS.DATA_WRONG },
  ],
};

const DispositionDetails = ({
  data,
  updateData,
  handleSave,
  loading,
}: {
  data: DataI;
  updateData: (data: DataI, callback?: () => void) => void;
  handleSave: (
    dispositionDetails?: keyof typeof DISPOSITION_DETAILS,
    optional?: {
      is_not_a_direct_line?: boolean;
      has_technical_issues?: boolean;
    }
  ) => void;
  loading: boolean;
}) => {
  const { startAt, endAt } = useInCallContext();

  const callDuration =
    startAt && endAt
      ? Math.abs(dayjs(endAt).diff(startAt, "second"))
      : undefined;

  const [disabled, setDisabled] = useState(false);
  const [isError, setIsError] = useState(false);
  const [notesValue, setNotesValue] = useState(data.notes || "");
  const [dispositionValue, setDispositionValue] = useState<
    (typeof DISPOSITIONS)[keyof typeof DISPOSITIONS] | undefined
  >(data?.disposition);
  const [detailsValue, setDetails] = useState<
    keyof typeof DISPOSITION_DETAILS | undefined
  >(data?.dispositionDetails);
  const [isNotDirectLine, setIsNotDirectLine] = useState(false);
  const [isTechIssues, setIsTechIssues] = useState(false);

  const onSave = () => {
    handleSave(undefined, {
      is_not_a_direct_line: isNotDirectLine,
      has_technical_issues: isTechIssues,
    });
  };

  const onSkipDisposition = () => {
    handleSave(
      DISPOSITION_DETAILS.VOICEMAIL_NOT_LEFT as keyof typeof DISPOSITION_DETAILS,
      {
        is_not_a_direct_line: isNotDirectLine,
        has_technical_issues: isTechIssues,
      }
    );
  };

  const handleDispositionChange = (
    val: (typeof DISPOSITIONS)[keyof typeof DISPOSITIONS]
  ) => {
    setDispositionValue(val);

    setDetails(
      val === DISPOSITIONS.OTHER
        ? (DISPOSITION_DETAILS.OTHER as keyof typeof DISPOSITION_DETAILS)
        : undefined
    );
  };

  useEffect(() => {
    updateData({ notes: notesValue });
  }, [notesValue]);

  useEffect(() => {
    updateData({
      disposition: dispositionValue,
      dispositionDetails: detailsValue,
    });
  }, [dispositionValue, detailsValue]);

  useEffect(() => {
    setIsError(false);

    // NOTE - Disposition is not selected
    if (!dispositionValue) {
      setDisabled(true);
      return;
    }

    // NOTE - Disposition is selected but disposition details is not
    if (dispositionValue !== DISPOSITIONS.OTHER && !detailsValue) {
      setDisabled(true);
      return;
    }

    // NOTE - Disposition selected as "OTHER" or "REQUESTED_MORE_MATERIALS" but left no notes
    if (
      [
        DISPOSITION_DETAILS.OTHER,
        DISPOSITION_DETAILS.REQUESTED_MORE_MATERIALS,
      ].includes(detailsValue as keyof typeof DISPOSITION_DETAILS) &&
      notesValue?.length < 1
    ) {
      setDisabled(true);
      setIsError(true);

      return;
    }

    setDisabled(false);
  }, [detailsValue, dispositionValue, notesValue]);

  return (
    <div>
      <div className="form-control mb-6">
        <section>
          <label className="label">
            <span className="ae-typography-detail1">Disposition</span>
          </label>

          <div className="grid grid-cols-2 gap-4">
            {DISPOSITION_OPTIONS.map((opt, i) => (
              <EnhancedRadioButton
                key={i}
                className={opt.bgClassname}
                name="disposition_value"
                value={opt.label}
                label={opt.label}
                inputProps={{
                  onChange: () => handleDispositionChange(opt.value),
                }}
              />
            ))}
          </div>
        </section>

        {dispositionValue && dispositionValue !== DISPOSITIONS.OTHER && (
          <section className="mt-6">
            <label className="label">
              <span className="ae-typography-detail1">Disposition Details</span>
            </label>
            <div className="grid grid-cols-2 gap-4">
              {DETAILS_OPTIONS_MAP[dispositionValue].map((opt, i) => (
                <EnhancedRadioButton
                  key={`${i}-${opt.label?.replaceAll(" ", "")}`}
                  name="details_value"
                  value={opt.label}
                  label={opt.label}
                  inputProps={{
                    onChange: () =>
                      setDetails(opt.label as keyof typeof DISPOSITION_DETAILS),
                  }}
                />
              ))}
            </div>
          </section>
        )}
      </div>

      <section className="mb-6">
        <label className="label">
          <span className="ae-typography-detail1">Notes</span>
        </label>
        <textarea
          className={clsx(
            "textarea textarea-bordered w-full resize-none bg-black/5 focus:outline-none",
            {
              "border-error-content": isError,
            }
          )}
          rows={4}
          placeholder="Type your notes here"
          value={notesValue}
          onChange={(ev) => setNotesValue(ev.target.value)}
        ></textarea>
      </section>

      <p className="ae-typography-detail1">
        Make our AI dialer smarter by training it with feedback
      </p>

      <section className="mb-6 flex items-center justify-between gap-4">
        <div
          className={clsx(
            "flex flex-1 items-center",
            "ae-tooltip tooltip tooltip-top",
            "before:w-full"
          )}
          data-tip="This lead was a phone tree or a gatekeeper."
        >
          <EnhancedCheckbox
            name="is_not_direct_line"
            value={isNotDirectLine}
            label="Was this not a direct line?"
            inputProps={{
              onChange: () => setIsNotDirectLine(!isNotDirectLine),
            }}
          />
          <InformationCircleIcon className="w-4 min-w-[16px]" />
        </div>
        <div
          className={clsx(
            "flex flex-1 items-center",
            "ae-tooltip tooltip tooltip-top",
            "before:w-full"
          )}
          data-tip="Technical issues include, but are not limited to, issues with delay, issues with audio, issues with connection. Please detail in the notes the technical issues you faced"
        >
          <EnhancedCheckbox
            name="has_technical_issue"
            value={isTechIssues}
            label="Technical issues in this call?"
            inputProps={{
              onChange: () => setIsTechIssues(!isTechIssues),
            }}
          />

          <InformationCircleIcon className="relative -left-2 w-4 min-w-[16px]" />
        </div>
      </section>

      <section className="flex w-full justify-end">
        {typeof callDuration === "number" &&
          callDuration <= MAX_CALL_DURATION_TO_SKIP_DISPOSITION_IN_SECONDS && (
            <button
              className="btn-ae-text mr-2"
              onClick={onSkipDisposition}
              disabled={loading}
            >
              Skip disposition
            </button>
          )}

        <button
          className={clsx("btn-ae-default w-[140px]", {
            disabled: disabled,
            loading: loading,
          })}
          onClick={onSave}
        >
          Submit
        </button>
      </section>
    </div>
  );
};

export default DispositionDetails;
