import { DISQUALIFY_MODAL_ID } from "@/components/modals/disqualify-modal";
import { BaseControlButton } from "@/modules/pipeline/account-details/control-menu/buttons/base-control-button";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { modalHelpers } from "shared/lib/helpers/modalHelpers";

export const DisqualifyButton = () => {
  const openDisqualifyModal = () => {
    modalHelpers.trigger(DISQUALIFY_MODAL_ID);
  };

  return (
    <BaseControlButton className="text-[#D71D24]" onClick={openDisqualifyModal}>
      <XMarkIcon className="w-6" />
      <span>Disqualify</span>
    </BaseControlButton>
  );
};
