import { useEffect } from "react";
import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";

import { useEffectOnce } from "shared/lib/hooks";
import { InfiniteScrollWithObserver } from "shared/ui";

import { HistoryList } from "./history-list";
import { CUSTOM_EVENTS } from "@/constants/custom-events";

export const AccountHistorySection = () => {
  const {
    accountHistoryData: {
      data: accountHistory,
      loadMore: loadMoreAccountHistory,
      reloadData: reloadAccountHistory,
      isReachedEnd: isReachAccountHistoryEnd,
      isLoading: isLoadingAccountHistory,
    },
  } = useAccountDetailsContext();

  useEffectOnce(() => {
    reloadAccountHistory();
  });

  // Need to reload account history if user adds a note from the widget dialer
  useEffect(() => {
    window.document.addEventListener(
      CUSTOM_EVENTS.ACCOUNT.HISTORY.RELOAD,
      reloadAccountHistory
    );

    return () => {
      window.document.removeEventListener(
        CUSTOM_EVENTS.ACCOUNT.HISTORY.RELOAD,
        reloadAccountHistory
      );
    };
  }, []);

  return (
    <section>
      <InfiniteScrollWithObserver
        isLoading={isLoadingAccountHistory}
        isMore={!isReachAccountHistoryEnd}
        onMore={loadMoreAccountHistory}
        isDefaultMessageHidden={accountHistory.length === 0}
      >
        <HistoryList
          isLoading={isLoadingAccountHistory}
          historyData={accountHistory}
        />
      </InfiniteScrollWithObserver>
    </section>
  );
};
