import { useCallback } from "react";
import { Portal } from "react-portal";
import { toast } from "react-hot-toast";

import { useAccountDetailsContext } from "@/modules/pipeline/account-details/context";
import { DisqualifyModal } from "@/modals/disqualify-modal";
import { EditContactModal } from "@/modals/edit-contact-modal";
import { AccountSendEmailModal } from "@/modals/account-send-email-modal";
import CreateNewLeadModal from "@/modals/create-new-lead-modal";
import AccountChangeStatusModal from "@/modals/account-change-status-modal";
import { ACCOUNT_STATUSES } from "shared/lib/constants/account";
import AccountCancelMeetingModal from "@/modals/account-cancel-meeting-modal";
import { AccountEditCallbackModal } from "@/modals/account-edit-callback-modal";
import { AccountBookMeetingModal } from "@/modals/account-book-meeting-modal";
import EditAccountModal from "@/modals/edit-account-modal";
import { useAccountsContext } from "@/hooks/use-accounts-context";
import { CreateNewLeadFormI } from "@/modals/create-new-lead-modal/new-lead-form";
import { glencocoClientAPI } from "@/api/glencoco";
import { handleErrorMessage } from "@/helpers/error";
import { AccountUserDispositionI } from "@/interfaces/accounts";
import { MEETING_OUTCOMES } from "@/constants/accounts";
import { DeleteAccountLeadConfirmationModal } from "@/components/modals/delete-account-lead-confirmation-modal";
import { AddUserToListSidebar } from "@/modules/pipeline/shared/add-user-to-list-sidebar";
import { useAddUserToListSidebarContext } from "@/modules/pipeline/shared/add-user-to-list-sidebar/context";
import { SetUnsetPrimaryLeadConfirmationModal } from "@/modals/set-primary-lead-confirmation-modal";
import { SetContactTypeModal } from "@/modals/set-contact-type-modal";

const getBlockedStatuses = (account?: AccountUserDispositionI) => {
  const { status, meeting_outcome } = account || {};

  if (
    ACCOUNT_STATUSES.FEEDBACK_RECEIVED === status &&
    ![
      MEETING_OUTCOMES.NO_SHOW as string,
      MEETING_OUTCOMES.CANCELED,
      MEETING_OUTCOMES.PENDING,
    ].includes(meeting_outcome as string)
  ) {
    return [
      ACCOUNT_STATUSES.BACKLOG,
      ACCOUNT_STATUSES.CALLBACK,
      ACCOUNT_STATUSES.MEETING_SCHEDULED,
    ];
  }

  return [];
};

export const AccountDetailsModals = () => {
  const { clearModalAccount, modalAccount } = useAccountsContext();

  const {
    focusedContact,
    setFocusedContact,
    campaign,
    account,
    contacts,
    onAccountUpdate: handleAccountUpdate,
    accountUserDisposition,
    accountHistoryData: { reloadData: reloadAccountHistory },
  } = useAccountDetailsContext();

  const {
    isOpen: isAddUserToListSidebarOpen,
    onClose: closeAddUserToListSidebar,
  } = useAddUserToListSidebarContext();

  const submitNewLeadToAccountAsBacklog = async (data: CreateNewLeadFormI) => {
    if (campaign?.id && account?.id) {
      const API = glencocoClientAPI();

      const resp = await API.addLeadToAccount(campaign?.id, account?.id, {
        first_name: data.first_name,
        last_name: data.last_name,
        title: data.title,
        phone: data.phone,
        mobile: data.mobile,
        email: data.email,
        linkedin_url: data.linkedin_url,
      }).catch((e) => e);

      if (resp.status === 200) {
        toast.success("Success");
        return true;
      } else {
        handleErrorMessage(
          resp?.response?.data?.error_code as number,
          "Failed to add new lead. Please contact Glencoco."
        );
      }
    }
    return false;
  };

  // Call this function when we need to both update account data and reload account history
  const onModalActionSuccess = useCallback(() => {
    handleAccountUpdate();
    reloadAccountHistory();
  }, [handleAccountUpdate, reloadAccountHistory]);

  return (
    <Portal>
      {campaign && account && (
        <DisqualifyModal
          campaignId={campaign?.id}
          account={account}
          contacts={contacts}
          focusedContactId={focusedContact?.id}
          onSuccess={onModalActionSuccess}
        />
      )}

      {focusedContact && (
        <>
          <EditContactModal
            campaign={campaign}
            contact={focusedContact}
            onClose={() => setFocusedContact(undefined)}
            onSubmitSuccess={onModalActionSuccess}
          />

          <SetUnsetPrimaryLeadConfirmationModal
            campaign={campaign}
            account={account}
            contact={focusedContact}
            onSuccess={onModalActionSuccess}
          />

          <AccountSendEmailModal
            campaignId={campaign?.id as string}
            contact={focusedContact}
            onSuccess={onModalActionSuccess}
          />

          <DeleteAccountLeadConfirmationModal
            campaignId={campaign?.id}
            contact={focusedContact}
            onSuccess={() => {
              setFocusedContact(undefined);
              onModalActionSuccess();
            }}
          />

          <SetContactTypeModal
            campaignId={campaign?.id}
            accountId={account?.id}
            contactId={focusedContact?.id}
            contactType={focusedContact.contact_disposition_type}
            onSuccess={onModalActionSuccess}
          />
        </>
      )}

      {/* THESE MODALS HANDLE NEW ACCOUNT LEAD CREATION */}
      <CreateNewLeadModal
        campaign={campaign}
        account={account}
        handleNewLeadSubmitAsBacklog={submitNewLeadToAccountAsBacklog}
        onSuccess={onModalActionSuccess}
      />

      {/* THESE MODALS HANDLE UPDATING AN EXISTING ACCOUNT  */}
      {!!modalAccount && (
        <>
          <AccountChangeStatusModal
            blockedStatuses={getBlockedStatuses(accountUserDisposition)}
            onClose={clearModalAccount}
            onSuccess={onModalActionSuccess}
          />

          {modalAccount.accountStatus ===
            ACCOUNT_STATUSES.MEETING_SCHEDULED && (
            <AccountCancelMeetingModal
              onClose={clearModalAccount}
              onSuccess={onModalActionSuccess}
            />
          )}

          {modalAccount.targetAccountStatus === ACCOUNT_STATUSES.CALLBACK && (
            <AccountEditCallbackModal
              followUpTime={modalAccount.followUpTime}
              onClose={clearModalAccount}
              onSuccess={onModalActionSuccess}
            />
          )}

          {modalAccount.targetAccountStatus ===
            ACCOUNT_STATUSES.MEETING_SCHEDULED && (
            <AccountBookMeetingModal
              onClose={clearModalAccount}
              onSuccess={onModalActionSuccess}
            />
          )}
        </>
      )}

      <EditAccountModal
        campaign={campaign}
        account={account}
        onSubmitSuccess={onModalActionSuccess}
      />

      <AddUserToListSidebar
        isOpen={isAddUserToListSidebarOpen}
        onClose={closeAddUserToListSidebar}
      />
    </Portal>
  );
};
