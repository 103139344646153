import { FC, MutableRefObject, useRef } from "react";

import CalendarForm from "@/components/modals/account-edit-callback-modal/calendar-form";
import { useAccountsContext } from "@/hooks/use-accounts-context";

interface AccountEditCallbackModalPropsI {
  modalId?: string;
  onSuccess?: () => void;
  onClose?: () => void;
  followUpTime?: string;
}

export const ACCOUNT_EDIT_CALLBACK_MODAL_ID = "account-edit-callback-modal";

export const AccountEditCallbackModal: FC<AccountEditCallbackModalPropsI> = ({
  modalId = ACCOUNT_EDIT_CALLBACK_MODAL_ID,
  followUpTime = "",
  onSuccess = () => {},
  onClose = () => {},
}) => {
  const { onChangeStatusCancelRef } = useAccountsContext();
  const checkboxRef = useRef() as MutableRefObject<HTMLInputElement>;

  const handleClose = () => {
    if (onChangeStatusCancelRef.current) {
      onChangeStatusCancelRef.current();
      onChangeStatusCancelRef.current = null;
    }

    checkboxRef.current.click();
    onClose();
  };

  const onBookingSuccess = () => {
    onSuccess();
    handleClose();
  };

  return (
    <>
      <input
        type="checkbox"
        id={modalId}
        className="modal-toggle"
        ref={checkboxRef}
      />

      <div className="modal cursor-pointer">
        <div className="set-callback-modal modal-box relative max-h-[calc(100vh-50px)] w-[520px] rounded-md p-6">
          <label
            htmlFor={modalId}
            className="b-typography-detail1 btn btn-circle btn-ghost btn-sm absolute right-6 top-4 text-black/40"
            onClick={handleClose}
          >
            ✕
          </label>

          <CalendarForm
            isNotes
            isNotesRequired
            initialDate={followUpTime}
            initialTime={followUpTime}
            title="Set Callback"
            description="Specify date and time when to remind you to call back"
            cancelButton={{
              type: "label",
              htmlFor: modalId,
              text: "Cancel",
              onClick: handleClose,
            }}
            successButton={{
              type: "button",
              text: "Save",
            }}
            onBookingSuccess={onBookingSuccess}
          />
        </div>
      </div>
    </>
  );
};
