import { WidgetI } from "@/components/shared/widgets/interface";
import { WIDGETS } from "@/constants/widgets";
import { GLOBAL_STATE_VAR_ACTIVE_WIDGETS } from "@/constants/window-globals";

/**
 * Used in various async events and Interval functions
 * which are outside of the WidgetProvider context (DialerGlobal)
 *
 * @returns {boolean}
 */
export const isDialerWidgetOpen = () => {
  const activeWidgets = (window as any)[
    GLOBAL_STATE_VAR_ACTIVE_WIDGETS
  ] as WidgetI[];

  return activeWidgets?.some((w) => w.id === WIDGETS.MAXED_DIALER);
};
