import { useRouter } from "next/router";

import { websocketListener } from "lib/websockets";
import { useEffectOnce } from "shared/lib/hooks";
import Router from "@/helpers/router";

const SOCKETLESS_PAGES = [Router.profileInactive()];

export const useWebsocketConnection = () => {
  const { route } = useRouter();

  useEffectOnce(() => {
    // Need to manually add check for "/" route because home page is "/", which is included in all routes - even the ones inside SOCKELESS_PAGES
    // so it will match that even though we don't want the home page to be treated as a sockless page.
    if (
      route === "/" ||
      !SOCKETLESS_PAGES.some((socketless_route) =>
        socketless_route.includes(route)
      )
    ) {
      websocketListener();
    }
  });
};
