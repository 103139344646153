import CircularLoading from "@/components/base/circular-loading";
import Router from "@/helpers/router";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { clsxMerge } from "shared/lib/helpers";

const LOGOUT_VISIBLE_TIMEOUT = 10 * 1000;

const LogoutButton = () => {
  return (
    <a
      href={Router.logout()}
      className={clsxMerge(
        "animate-fadein",
        "btn-ae-default",
        "absolute right-[calc(50vw-40px)] top-[calc(50vh+30px)]"
      )}
    >
      Logout
    </a>
  );
};

export const LoadingComponent = ({
  className,
  spinnerClassName,
  delay,
  isTimedoutLogout,
}: {
  className?: string;
  spinnerClassName?: string;
  delay?: number;
  isTimedoutLogout?: boolean;
}) => {
  const [isVisible, setIsVisible] = useState(typeof delay !== "number");
  const [isLogoutVisible, setIsLogoutVisible] = useState(false);

  let interval: any = null;
  useEffect(() => {
    setIsVisible(false);
    clearInterval(interval);

    interval = setInterval(() => setIsVisible(true), delay);

    return () => {
      clearInterval(interval);
    };
  }, [delay]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLogoutVisible(true);
    }, LOGOUT_VISIBLE_TIMEOUT);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return isVisible ? (
    <div
      className={clsx(
        "hero-content flex animate-fadein items-center justify-center",
        className
      )}
    >
      {isTimedoutLogout && isLogoutVisible && <LogoutButton />}
      <CircularLoading className={spinnerClassName} />
    </div>
  ) : null;
};

export const LoadingModule = ({ className }: { className?: string }) => (
  <div className={clsx("hero min-h-screen", className)}>
    <LoadingComponent isTimedoutLogout />
  </div>
);
