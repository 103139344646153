import {
  WEBSOCKET_CONNECTION_TYPES,
  WEBSOCKET_EVENT_TYPES,
} from "lib/websockets/constants";
import { getWebsocket } from "@/helpers/websockets";
import { dd } from "@/helpers/datadog";
import { datadogRum } from "@datadog/browser-rum";
import { LOG_CATEGORIES } from "@/constants/logs";
import { ERROR_CATEGORIES } from "@/constants/errors";

const ddMessage = (ddMessage: string, context: any) => {
  dd.log(ddMessage, { data: context });
  datadogRum.addAction(ddMessage, {
    data: context,
  });
};

/**
 * SOCKET MESSAGES SEND TYPE ONLY
 */
export const dialerListSocketActions = {
  /**
   * This function initiates a new dialing session for a specific list.
   * It creates a WebSocket connection using a general connection type
   * and sends a message to start the dialing session for the provided listId
   *
   * @param listId - string
   */
  startSession: (listId: string) => {
    const ws: WebSocket = getWebsocket(WEBSOCKET_CONNECTION_TYPES.GENERAL);

    const msg_start_list_dialing_session = {
      list_id: listId,
    };

    ddMessage(`${LOG_CATEGORIES.DIALER_LIST}[WS][SEND] - START SESSION`, {
      msg_start_list_dialing_session,
    });

    ws?.send(
      JSON.stringify({
        msg_type: WEBSOCKET_EVENT_TYPES.DIALER_LIST_SEND_START_DIALING_SESSION,
        msg_start_list_dialing_session,
      })
    );
  },

  /**
   *
   * This function initiates a new call for a specified list, twilio idenity and its associated membership IDs.
   * It establishes a WebSocket connection using a general connection type and sends a message
   * to start the calls for the provided listId and membershipIds
   *
   * @param config
   * @param config.listId
   * @param config.membershipIds
   * @param config.identity_id
   */
  startCall: ({
    listId,
    membershipIds,
    identity_id,
  }: {
    listId: string;
    membershipIds: string[] | null | undefined;
    identity_id: string;
  }) => {
    const ws: WebSocket = getWebsocket(WEBSOCKET_CONNECTION_TYPES.GENERAL);

    const msg_start_list_calls = {
      list_id: listId,
      membership_ids: membershipIds,
      identity_id,
    };

    ddMessage(`${LOG_CATEGORIES.DIALER_LIST}}[WS][SEND] - START CALL`, {
      msg_start_list_calls,
    });

    if (!identity_id)
      dd.rum.error(
        `${ERROR_CATEGORIES.WS}[SEND] - START CALL - no identity to start a call`
      );

    ws?.send(
      JSON.stringify({
        msg_type: WEBSOCKET_EVENT_TYPES.DIALER_LIST_SEND_START_LIST_CALLS,
        msg_start_list_calls,
      })
    );
  },

  /**
   * Initiates an "ignore" status update for a specified list and its associated membership IDs.
   *
   * This function establishes a swift process for syncing the status of intentionally ignored
   * calls that are not going to be processed in the global dialer.
   *
   * @param {object} config
   * @param {string} config.listId
   * @param {string} config.membershipId
   */
  ignoreCall: ({
    listId,
    membershipId,
  }: {
    listId: string;
    membershipId: string;
  }) => {
    const ws: WebSocket = getWebsocket(WEBSOCKET_CONNECTION_TYPES.GENERAL);

    const msg_ignored_list_call = {
      list_id: listId,
      membership_id: membershipId,
    };

    ddMessage(`${LOG_CATEGORIES.DIALER_LIST}[WS][SEND] - IGNORE CALL`, {
      msg_ignored_list_call,
    });

    ws?.send(
      JSON.stringify({
        msg_type: WEBSOCKET_EVENT_TYPES.DIALER_LIST_SEND_IGNORE_LIST_CALL,
        msg_ignored_list_call,
      })
    );
  },

  /**
   * This function terminates the call.
   * It creates a WebSocket connection using a general connection type
   * and sends a message to stop the call identified by membership_id
   *
   * @param {string} membershipId
   */
  killCall: ({
    listId,
    membershipId,
  }: {
    listId: string;
    membershipId: string;
  }) => {
    const ws: WebSocket = getWebsocket(WEBSOCKET_CONNECTION_TYPES.GENERAL);

    const msg_kill_list_call = {
      membership_id: membershipId,
      list_id: listId,
    };

    ddMessage(`${LOG_CATEGORIES.DIALER_LIST}[WS][SEND] - KILL CALL`, {
      msg_kill_list_call,
    });

    ws?.send(
      JSON.stringify({
        msg_type: WEBSOCKET_EVENT_TYPES.DIALER_LIST_SEND_KILL_LIST_CALL,
        msg_kill_list_call,
      })
    );
  },

  /**
   * This function terminates all ongoing calls for a specified list.
   * It creates a WebSocket connection using a general connection type
   * and sends a message to stop all calls associated with the provided listId
   *
   * @param listId
   */
  killAllCallsByListId: (listId: string) => {
    const ws: WebSocket = getWebsocket(WEBSOCKET_CONNECTION_TYPES.GENERAL);

    const msg_kill_list_calls = {
      list_id: listId,
    };

    ddMessage(`${LOG_CATEGORIES.DIALER_LIST}[WS][SEND] - KILL ALL CALLS`, {
      msg_kill_list_calls,
    });

    ws?.send(
      JSON.stringify({
        msg_type: WEBSOCKET_EVENT_TYPES.DIALER_LIST_SEND_KILL_LIST_CALLS,
        msg_kill_list_calls,
      })
    );
  },
};
