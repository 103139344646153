import { WIDGETS } from "./widgets";

const NETWORK_CATEGORY = "NETWORK";

const WEBSOCKETS_CATEGORY = "WEBSOCKETS";
const LIST_DIALING_SUBCATEGORY = "LIST_DIALING";
const NOTIFICATIONS_SUBCATEGORY = "NOTIFICATIONS";

const CALLING_MODULE_CATEGORY = "CALLING_MODULE";
const FREQUENTLY_CHANGING_CONTEXT_SUBCATEGORY = "FREQUENTLY_CHANGING_CONTEXT";

const DIALER_TOUR = "DIALER_TOUR";

const MESSAGING_MODULE_CATEGORY = "MESSAGING";

const WIDGETS_CATEGORY = "WIDGETS";

const ACCOUNT_CATEGORY = "ACCOUNT";
const ACCOUNT_HISTORY_SUBCATEGORY = "HISTORY";

export const CUSTOM_EVENTS = {
  // TODO - better struction for events
  MEETING: "meeting",
  // ---------------------------------

  NETWORK: {
    ONLINE: `${NETWORK_CATEGORY}:ONLINE`,
    OFFLINE: `${NETWORK_CATEGORY}:OFFLINE`,
  },

  WEBSOCKETS: {
    AUTH_SUCCESS: `${WEBSOCKETS_CATEGORY}:AUTH_SUCCESS`,
    CLOSED: `${WEBSOCKETS_CATEGORY}:CLOSED`,
    ERROR: `${WEBSOCKETS_CATEGORY}:ERROR`,

    NOTIFICATIONS: {
      NEW: `${WEBSOCKETS_CATEGORY}:${NOTIFICATIONS_SUBCATEGORY}:NEW`,
    },

    MESSAGING: {
      ENTER_CHAT: `${MESSAGING_MODULE_CATEGORY}:ENTER_CHAT`,
      MESSAGE_SEND: `${MESSAGING_MODULE_CATEGORY}:MESSAGE_SEND`,
      MESSAGE_RECEIVE: `${MESSAGING_MODULE_CATEGORY}:MESSAGE_RECEIVE`,
    },

    LIST_DIALING: {
      CALL_STATUS_UPDATE: `${WEBSOCKETS_CATEGORY}:${LIST_DIALING_SUBCATEGORY}:CALL_STATUS_UPDATE`,
      ERROR: `${WEBSOCKETS_CATEGORY}:${LIST_DIALING_SUBCATEGORY}:ERROR`,
    },
  },

  CALLING_MODULE: {
    FREQUENTLY_CHANGING_CONTEXT: {
      INPUT_VOLUME_UPDATED: `${CALLING_MODULE_CATEGORY}:${FREQUENTLY_CHANGING_CONTEXT_SUBCATEGORY}:INPUT_VOLUME_UPDATED`,
      OUTPUT_VOLUME_UPDATED: `${CALLING_MODULE_CATEGORY}:${FREQUENTLY_CHANGING_CONTEXT_SUBCATEGORY}:OUTPUT_VOLUME_UPDATED`,
      CALL_QUALITY_UPDATED: `${CALLING_MODULE_CATEGORY}:${FREQUENTLY_CHANGING_CONTEXT_SUBCATEGORY}:CALL_QUALITY_UPDATED`,
    },
    TOUR: {
      START_TOUR: `${CALLING_MODULE_CATEGORY}:${DIALER_TOUR}:START_TOUR`,
    },
  },

  ACCOUNT: {
    RELOAD: `${ACCOUNT_CATEGORY}:RELOAD`,

    HISTORY: {
      RELOAD: `${ACCOUNT_CATEGORY}:${ACCOUNT_HISTORY_SUBCATEGORY}:RELOAD`,
    },
  },

  WIDGETS: {
    [WIDGETS.DIALER]: {
      OPEN: `${WIDGETS_CATEGORY}:${WIDGETS.DIALER}:OPEN`,
      CLOSE: `${WIDGETS_CATEGORY}:${WIDGETS.DIALER}:CLOSE`,
      TOGGLE: `${WIDGETS_CATEGORY}:${WIDGETS.DIALER}:TOGGLE`,
      TRIGGER: `${WIDGETS_CATEGORY}:${WIDGETS.DIALER}:TRIGGER`,
    },
    [WIDGETS.MAXED_DIALER]: {
      OPEN: `${WIDGETS_CATEGORY}:${WIDGETS.MAXED_DIALER}:OPEN`,
      CLOSE: `${WIDGETS_CATEGORY}:${WIDGETS.MAXED_DIALER}:CLOSE`,
      TOGGLE: `${WIDGETS_CATEGORY}:${WIDGETS.MAXED_DIALER}:TOGGLE`,
      TRIGGER: `${WIDGETS_CATEGORY}:${WIDGETS.MAXED_DIALER}:TRIGGER`,
    },
  },
} as const;
