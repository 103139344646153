import { ChangeEvent, FC, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import clsx from "clsx";

import { useCallingContext } from "@/hooks/dialer/use-dialer-context";
import { SelectField, ToggleField } from "shared/ui/ae-user-input";

const EnhancedToggleField = ToggleField();

const Size18Array = Array(18).fill(0);

const InputAudioVisualization: FC<{
  microphoneDeviceId: string;
}> = ({ microphoneDeviceId }) => {
  const [activeCellCount, setActiveCellCount] = useState(0);

  useEffect(() => {
    let audioContext: AudioContext;
    let volumeProcessor: AudioWorkletNode;

    const getMicrophoneVolume = async () => {
      try {
        audioContext = new AudioContext();
        await audioContext.audioWorklet.addModule("/volume-processor.js");

        const stream = await navigator.mediaDevices.getUserMedia({
          audio: {
            deviceId: microphoneDeviceId
              ? { exact: microphoneDeviceId }
              : undefined,
          },
        });

        const microphone = audioContext.createMediaStreamSource(stream);
        volumeProcessor = new AudioWorkletNode(
          audioContext,
          "volume-processor"
        );

        microphone.connect(volumeProcessor);
        volumeProcessor.connect(audioContext.destination);

        volumeProcessor.port.onmessage = (event) => {
          setActiveCellCount(event.data.volume);
        };
      } catch (err) {
        console.error("Error getting microphone", err);
      }
    };

    if (microphoneDeviceId) {
      void getMicrophoneVolume();
    }

    return () => {
      volumeProcessor?.port.postMessage({ stop: true });
      volumeProcessor?.disconnect();
      audioContext?.close();
    };
  }, [microphoneDeviceId]);

  return (
    <div className="mt-3 flex justify-between">
      {Size18Array.map((n, i) => (
        <div
          key={i}
          className={clsx(
            "h-3 w-3 rounded-[4px]",
            "transition-colors duration-200",
            {
              "bg-black/5": i >= activeCellCount,
              "bg-[#4474E3]": i < activeCellCount,
            }
          )}
        />
      ))}
    </div>
  );
};

export interface DialerSettingsConfigI {
  isAutoDialSetting?: boolean;
  isMusicSetting?: boolean;
}

interface SettingsI extends DialerSettingsConfigI {
  isVisible: boolean;
}

const Settings: FC<SettingsI> = ({
  isVisible,
  isAutoDialSetting = true,
  isMusicSetting = true,
}) => {
  const callContext = useCallingContext();

  const [microphoneLabels, setMicrophoneLabels] = useState<any[]>([]);
  const [selectedMicrophone, setSelectedMicrophone] = useState("");
  const [selectedMicrophoneDeviceId, setSelectedMicrophoneDeviceId] = useState<
    string | null
  >();
  const [speakerLabels, setSpeakerLabels] = useState<any[]>([]);
  const [selectedSpeaker, setSelectedSpeaker] = useState("");

  const { campaign } = callContext;

  const handleMicrophoneChange = (ev: ChangeEvent<HTMLSelectElement>) => {
    setSelectedMicrophone(ev.target.value);
    const selectedMicrophoneObj = callContext.inputDevices?.find(
      (d) => d[1].label === ev.target.value
    );

    if (selectedMicrophoneObj) {
      setSelectedMicrophoneDeviceId(selectedMicrophoneObj[1].deviceId);

      callContext.device?.audio
        ?.setInputDevice(selectedMicrophoneObj[0])
        .then(() => toast("Microphone input device updated"))
        .catch((err) => console.error("err: ", err));
    }
  };

  const handleSpeakerChange = (ev: ChangeEvent<HTMLSelectElement>) => {
    setSelectedSpeaker(ev.target.value);
    const selectedSpeakerObj = callContext.outputDevices?.find(
      (s) => s[1].label === ev.target.value
    );

    if (selectedSpeakerObj) {
      callContext.device?.audio?.speakerDevices
        .set(selectedSpeakerObj[0])
        .then(() => {
          toast("Speaker output device updated");
          callContext.device?.audio?.speakerDevices.test();
        })
        .catch((err) => console.error("err: ", err));
    }
  };

  const handleAutodialChange = () => {
    callContext.callerProfile.setAutoDial(!callContext.callerProfile.autoDial);
  };

  const handleMusicChange = () => {
    callContext.setIsMusic(!callContext.isMusic);
  };

  useEffect(() => {
    const micLabels = callContext.inputDevices?.map(
      (device) => device[1].label
    );

    setSelectedMicrophoneDeviceId(callContext.inputDevices?.[0]?.[1].deviceId);

    if (micLabels) {
      setMicrophoneLabels(micLabels);
    }

    const speakerLabels = callContext.outputDevices?.map(
      (device) => device[1].label
    );

    if (speakerLabels) {
      setSpeakerLabels(speakerLabels);
    }
  }, [callContext.inputDevices, callContext.outputDevices]);

  return (
    <div className="relative w-[348px] overflow-hidden rounded-[10px] bg-white/80 px-6 py-6 backdrop-blur-xl">
      <section className="mb-9">
        <p className="ae-typography-body1 mb-1">Microphone</p>
        <SelectField
          selectedValue={selectedMicrophone}
          options={microphoneLabels}
          onChange={handleMicrophoneChange}
          variation="small"
        />

        {selectedMicrophoneDeviceId && isVisible && (
          <InputAudioVisualization
            microphoneDeviceId={selectedMicrophoneDeviceId}
          />
        )}
      </section>

      <section className="mb-9">
        <p className="ae-typography-body1 mb-1">Speakers</p>
        <SelectField
          selectedValue={selectedSpeaker}
          options={speakerLabels}
          onChange={handleSpeakerChange}
          variation="small"
        />
      </section>

      {isAutoDialSetting && (
        <section className="mb-9">
          <EnhancedToggleField
            label="Autodial"
            checked={callContext.callerProfile.autoDial}
            onChange={handleAutodialChange}
            inputProps={{ disabled: campaign?.has_live_transfer }}
          />
          <p className="ae-typography-body2 text-black/60">
            Automatically dial leads after a call ends
          </p>
        </section>
      )}

      {isMusicSetting && (
        <section>
          <EnhancedToggleField
            label="Dialing music"
            checked={callContext.isMusic}
            onChange={handleMusicChange}
          />
          <p className="ae-typography-body2 text-black/60">
            Play music during dialing state
          </p>
        </section>
      )}
    </div>
  );
};

export default Settings;
