import { DATE_FROMAT_ONLY_TIME_WITH_TZ } from "shared/lib/constants/time";
import { TIMEZONES } from "shared/lib/constants/timezones";
import { clsxMerge } from "shared/lib/helpers";
import { useLiveTime } from "shared/lib/hooks";
import { CurrentCallerBadge } from "./current-caller-badge";

export const LeadAdditionalInfo = () => {
  //TODO get timezone
  const currentTime = useLiveTime({
    dateFormat: "",
    hourFormat: DATE_FROMAT_ONLY_TIME_WITH_TZ,
    timezone: TIMEZONES[0].tzCode,
  });
  return (
    <div
      className={clsxMerge(
        "flex flex-col items-end justify-center",
        "text-white",
        "p-3"
      )}
    >
      <span className="typography-header-8-bold">Connected to lead</span>

      <span className={clsxMerge("typography-body-5-medium", "mb-2")}>
        Local time for contact {currentTime}
      </span>

      <CurrentCallerBadge />
    </div>
  );
};
