import { CallContactI } from "@/interfaces/dialer";
import { ContactDetailI } from "shared/lib/interfaces/account";

//TODO FIX this bs with the BE and use single interface for contacts
export const convertContactToContactDetail = (
  contact: CallContactI
): ContactDetailI => ({
  contact_id: contact?.id,
  first_name: contact?.first_name,
  last_name: contact?.last_name,
  title: contact?.title,
  phone: contact?.phone,
  mobile: contact?.mobile,
  email: contact?.email,
  linkedin_url: contact?.linkedin,
});

export const convertContactDetailToContact = (
  contact: ContactDetailI
): CallContactI => ({
  id: contact?.contact_id,
  first_name: contact?.first_name,
  last_name: contact?.last_name,
  phone: contact?.phone,
  mobile: contact?.mobile,
  email: contact?.email,
  linkedin: contact?.linkedin_url,
  title: contact?.title,
});
